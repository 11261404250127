<template>
    <section class="message-container">
        <img :src="imageSrc()" />
        <p class="message-title mt-5">{{ title }}</p>
        <p class="subtitle mt-1">{{ subtitle }}</p>
        <slot name="link" />
    </section>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: undefined
        },
        subtitle: {
            type: String,
            default: undefined
        },
        type: {
            type: String,
            default: undefined
        }
    },
    methods: {
         imageSrc(){
            if (this.type === 'no-messages') return require('../../assets/no-messages.png')
            return require('../../assets/no-results-illustration.png')
         }
    }
   
}
</script>

<style lang="scss" scoped>
img {
    height: 204px;
}
.message-title {
    font-size: 1rem;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
}

.subtitle {
    font-size: .9rem;
    font-weight: 100;
    font-family: 'Inter', sans-serif;
    color: #9E9E9E;
    margin-bottom: .5rem;
}

.message-container{
   margin-top: 6rem;
   display: flex;
   flex-direction: column;
   align-items: center;
}
</style>