<template>
    <section>
        <div class="is-flex is-justify-content-center">

           
            <ShareNetwork
    network="facebook"
    :url="url"
    :title="title"  >
    <div class="social-share-btn mr-1">
        <img class="social-share-icon"  src="../assets/facebook-icon.svg"/>
    </div>
</ShareNetwork>

<ShareNetwork
network="Email"
:url="url"
:title="title">
<div class="social-share-btn mr-1">
    <img class="social-share-icon"  src="../assets/mail-icon.svg"/>
</div>
</ShareNetwork>

<ShareNetwork
network="Pinterest"
:url="url"
:title="title"
:media="image">
<div class="social-share-btn mr-1">
    <img class="social-share-icon"  src="../assets/pinterest-icon.svg"/>
</div>
</ShareNetwork>

<ShareNetwork
network="Twitter"
:url="url"
:title="title">
<div class="social-share-btn mr-1">
    <img class="social-share-icon"  src="../assets/x-icon.svg"/>
</div>
</ShareNetwork>

<ShareNetwork
network="WhatsApp"
:url="url"
:title="title">
<div class="social-share-btn mr-1">
    <img class="social-share-icon"  src="../assets/wp-icon.svg"/>
</div>

</ShareNetwork>

<div class="social-share-btn">
    <img class="social-share-icon" @click="copyToClipboard('https://guiders/blog/10-best-things-to-do-in-lisbon-in-2024')" src="../assets/link.svg"/>
</div>
</div>

    </section>
</template>

<script>
import { HelpersMixin } from '../mixins'
export default {
    props:['title', 'url', 'image'],
    mixins: [HelpersMixin],
}
</script>

<style scoped lang="scss">
.social-share-btn{
    padding: 0.4rem 0.5rem;
    border: 1px solid #EDEBF2;
    width: max-content;
    display: flex;
    border-radius: 4px;
    margin-bottom: 1.5rem;
   
    .social-share-icon{
        height: 1.3rem;
        width: auto;
    }
}
</style>