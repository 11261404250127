<template>
    <section class="is-flex is-align-items-center is-flex-direction-column">
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
        <p class="page-title mb-4">Create an account with Stripe</p>
        <p class="page-message ">We use Stripe to process all the payments in the platform. To start getting paid </p>
        <div class="is-blue mt-4" @click="$emit('create-stripe-account')">
            <b-icon class="mr-2" icon="external-link-alt"></b-icon> Create a Stripe account
        </div>

        <b-button @click="$emit('go-back')" class="mt-1" type="is-ghost">
            Go back
        </b-button>

    </section>
</template>

<script>
export default{
    props: {
        isLoading: {
            type: Boolean,
            default: false
        },
    }
}
</script>

<style lang="scss" scoped>
section {
    margin-top: 25vh;
    padding: 20px;
}

.page-title {
    font-weight: 600;
    font-size: 1.1rem;
}

.page-message {
    font-weight: 400;
    font-size: 1rem;
    text-align-last: center;
}

.is-blue {
    color: #446DF6;
    font-weight: 600;
    display: flex;

}
</style>