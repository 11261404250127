<template>
    <section>
        <GoBack class="mb-6" :route-name-humanized="employee.displayName" />
        <b-notification class="mb-6" v-if="employee.isAccountInactive" type="is-danger"
            aria-close-label="Close notification" role="alert">
            This account has been deactivated. <a @click="reactivateAccount">Click here</a> to reactivate it.
        </b-notification>

        <div class="user-details columns is-mobile mb-5">
            <div class="column is-2">
                <img class="profile-photo" :src="employee.photoURL">
            </div>

            <div class="column">
                <p class="has-text-weight-medium">{{ employee.displayName }}</p>
                <b-button v-if="!employee.isAccountInactive" @click="toggleWarning(true, employee.email)"
                    icon-left="user-times" size="is-small" class="has-text-danger" type="is-ghost"><b>Deactivate
                        employee</b></b-button>
            </div>
        </div>

        <b>Employee info</b>
        <div class="container--info mt-5 mb-5">
            <div class="columns">
                <div class="column is-6">
                    <!-- <div class=" display-flex-space-btw">
                        <p class="has-text-weight-medium">ID</p>
                        <div class="display-flex-space-btw">
                            <p class="employee-details">{{ employee.uid || 'Unknown' }}</p>
                            <div @click="copyTextToClipboard(employee.uid)">
                                <b-icon :class="{ 'is-invisible': !employee.uid }" pack="far" class="ml-2 icon--light-grey"
                                    icon="copy"></b-icon>
                            </div>
                        </div>
                    </div> -->

                    <div class="display-flex-space-btw">
                        <p class="has-text-weight-medium">Phone</p>
                        <div class="display-flex-space-btw">
                            <p class="employee-details">{{ employee.phoneNumber || 'Unknown' }}</p>
                            <div @click="copyTextToClipboard(employee.phoneNumber)">
                                <b-icon :class="{ 'is-invisible': !employee.phoneNumber }" pack="far"
                                    class="ml-2  icon--light-grey" icon="copy"></b-icon>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="column is-6">
                    <div class="display-flex-space-btw">
                        <p class="has-text-weight-medium">Email</p>
                        <div class="display-flex-space-btw">
                            <p class="employee-details">{{ employee.email || 'Unknown' }}</p>
                            <div @click="copyTextToClipboard(employee.email)">
                                <b-icon :class="{ 'is-invisible': !employee.email }" pack="far"
                                    class="ml-2  icon--light-grey" icon="copy"></b-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b>Payment history</b>

        <b-table class="mt-4" :data="finishedActivities" ref="table" per-page="5" detailed detail-key="id"
            detail-transition="fade" show-detail-icon aria-next-label="Next page" aria-previous-label="Previous page"
            aria-page-label="Page" aria-current-label="Current page" searchable pagination-rounded
            :row-class="(row) => row.employeeId !== employeeId && 'is-hidden'">

            <b-table-column field="tourName" label="Tour name" sortable v-slot="props">
                {{ getActivityInfo(props.row.activityId).title }}
            </b-table-column>

            <b-table-column label="Duration" centered v-slot="props">
                <span>
                    {{ getDuration(props.row.startTime, props.row.endTime) }}
                </span>
            </b-table-column>

            <b-table-column field="guiderHasBeenPayed" label="Payment status" sortable v-slot="props">
                <b-select :class="getStatusClass(props.row.guiderHasBeenPayed)" placeholder="Select a payment status"
                    rounded v-model="props.row.guiderHasBeenPayed" @change="update(props.row)">
                    <option :value="true">Paid</option>
                    <option :value="false">Unpaid</option>
                </b-select>
            </b-table-column>

            <template #detail="props">
                <p><b>Date</b></p>
                {{ getDate(props.row.startTime) }}
                <p class="mt-2"><b>Start time</b></p>
                {{ getHourHumanized(props.row.startTime) }}
            </template>

            <template #empty>
                <div class="has-text-centered">This guider hasn't done any activities yet</div>
            </template>
        </b-table>

        <b-modal v-model="isWarningActive">
            <WarningModal @toggleDeleteWarning="toggleWarning" @onActionConfirmed="removeEmployee"
                confirm-button-text="Deactivate employee" title="Are you sure you want to deactivate this employee?" subtitle="They will no longer have access to their account
                            but their activities history will still be visible to you." icon="user-times">
            </WarningModal>
        </b-modal>
    </section>
</template>

<script>

import { db } from "../firebase";
import GoBack from "../components/elements/GoBack.vue";
import { formattedDate, getTimeDifferenceInMinutes, getHoursAndMinutes } from '../helpers';
import { mapState, mapActions } from "vuex";
import { GuiderActions, GuidersStates, UserActions, UsersStates } from '../store/Storetypes'
import { ActivityMixin, HelpersMixin } from "../mixins";
import WarningModal from '../components/elements/WarningModal.vue';
export default {
    components: {
        GoBack,
        WarningModal
    },
    mixins: [ActivityMixin, HelpersMixin],
    data() {
        return {
            formattedDate,
            isLoading: false,
            isWarningActive: false,
            employeeToRemove: undefined,
        }
    },
    mounted() {
        this.getEmployees(this.user?.uid)
        const employeeId = this.$route.params.employeeId
        this.getEmployeeData(employeeId)
        if (!this.finishedActivities || !this.finishedActivities.length) this.getFinishedActivities(this.user?.uid)
        this.getEmployeeFinishedActivities(employeeId)
    },
    watch: {
        activities: {
            handler: function () {
                this.updateFinishedActivities({
                    guiderId: this.user?.uid,
                    updatedActivitiesInfo: this.finishedActivities
                })
            },
            deep: true,
        },
    },
    methods: {
        ...mapActions({
            updateFinishedActivities: GuiderActions.SET_FINISHED_ACTIVITIES,
            getEmployees: UserActions.GET_COMPANY_EMPLOYEES,
            getEmployeeData: UserActions.GET_EMPLOYEE_DATA,
            getFinishedActivities: GuiderActions.GET_FINISHED_ACTIVITIES,
            getEmployeeFinishedActivities: GuiderActions.GET_EMPLOYEE_FINISHED_ACTIVITIES
        }),
        copyTextToClipboard(text) {
            if (!text) return
            this.copyToClipboard(text)
        },
        toggleWarning(isActive, employeeEmail) {
            this.isWarningActive = isActive
            if (!employeeEmail) return
            this.employeeToRemove = employeeEmail
        },
        removeEmployee() {
            this.updateEmployeeDoc(true)
            this.isWarningActive = false
            /*  const updatedEmployeesList = [...this.user.employees]
             updatedEmployeesList[employeeIndex] = { ...updatedEmployeesList, isAccountActive: false }
             db.firestore()
                 .collection("guiders")
                 .doc(this.user.uid)
                 .update({ employees: updatedEmployeesList })
                 .then(() => {
                     console.log({uid: updatedEmployeesList[employeeIndex].uid});
                 })
                 .catch(() => {
                     this.$buefy.toast.open({
                         duration: 10000,
                         message:
                             "There has been an error removing this employee. Try again later.",
                         position: "is-bottom",
                         type: "is-danger",
                     });
                 });
  */
        },
        reactivateAccount() {
            this.updateEmployeeDoc(false)
        },
        updateEmployeeDoc(isAccountInactive) {
            db.firestore()
                .collection("guides")
                .doc(this.employee.uid)
                .update({ isAccountInactive: isAccountInactive })
                .then(() => {
                    const message = isAccountInactive ? "The employee account has been deactivated" : "The employee account has been reactivated"
                    const employeeId = this.$route.params.employeeId
                    this.getEmployeeData(employeeId)
                    this.$buefy.toast.open({
                        duration: 10000,
                        message,
                        position: "is-bottom",
                        type: "is-success",
                    });
                })
                .catch(() => {
                    const message = isAccountInactive ? "There has been an error deactivating this employee. Try again later." : "There has been an error reactivating this employee. Try again later."

                    this.$buefy.toast.open({
                        duration: 10000,
                        message,
                        position: "is-bottom",
                        type: "is-danger",
                    });
                });
        },
        getHourHumanized(dateString) {
            const date = new Date(dateString);
            let h = date.getHours();
            let m = date.getMinutes();
            return `${h}:${m}`
        },
        getStatusClass(allPaid) {
            return allPaid ? 'paid' : 'unpaid'
        },
        getDuration(startTime, endTime) {
            let durationInMinutes = getTimeDifferenceInMinutes(startTime, endTime)
            durationInMinutes = Math.round(durationInMinutes)
            return getHoursAndMinutes(durationInMinutes)
        },
        getDate(date) {
            const dateParsed = Date.parse(date)
            return formattedDate(dateParsed)
        },
    },
    computed: {
        ...mapState({
            finishedActivities: state => state.guiders[GuidersStates.FINISHED_ACTIVITIES],
            user: state => state.users[UsersStates.USER],
            employee: state => state.users[UsersStates.SELECTED_EMPLOYEE_DATA],
            employees: state => state.users[UsersStates.EMPLOYEES],
            activities: state => state.guiders[GuidersStates.EMPLOYEE_FINISHED_ACTIVITIES],
        }),
        employeeId() {
            return this.$route.params.employeeId
        }
    }
}
</script>

<style lang="scss" scoped>
.container--info {
    display: block;
    padding: 1rem;

    .columns {
        margin: 0;
    }

    .column {
        padding: 0
    }

}

.profile-photo {
    width: auto;
    object-fit: contain;
    max-height: 3rem;
    border-radius: 50%;
}

.employee-details {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: start;
    max-width: 10rem;
    color: #373F4F;
    font-weight: 300;
}

.user-details.columns {
    .column:not(:first-child) {
        padding: 0;
    }
}

.column {
    padding: 0 0.75rem;
}

.b-table .table-wrapper.has-mobile-cards tr:not(.detail):not(.is-empty):not(.table-footer) td:before {
    content: attr(data-label);
    color: #A29EBB;
}

::v-deep {
    .paid .select.is-rounded {
        select {
            background-color: #EBFBEF;
            color: #37D25F;
        }

        &::after {
            border-color: #37D25F;
        }

    }

    .modal-close {
        right: 36px;
        top: 36px;
    }

    .unpaid .select.is-rounded {
        select {
            background-color: #F9EDF7;
            color: #FF4868;
        }

        &::after {
            border-color: #FF4868;
        }
    }


    .select select option {
        background-color: white;
    }
}
</style>