<template>
    <section>
        <GoBack class="mb-6" route-name-humanized="Add employee" />
        <b-field label="Name">
            <b-input icon="user" v-model="name"> </b-input>
        </b-field>

        <b-field label="Email">
            <b-input icon="at" v-model="email" type="email"> </b-input>
        </b-field>

        <b-field :label="isOptionalField('Drivers license')">
            <b-upload multiple v-model="driversLicense" drag-drop required expanded accept="image/*" v-if="!isLoading">
                <section class="section">
                    <div class="content has-text-centered">
                        <p>
                            <b-icon icon-pack="fas" icon="image" size="is-large"> </b-icon>
                        </p>
                        <p>Upload your employee's drivers license.</p>
                    </div>
                </section>

            </b-upload>
            <span class="file-name mt-2" v-if="driversLicense">
                {{ driversLicense.name }}
            </span>
        </b-field>

        <b-field :label="isOptionalField('Citizen Card')">
            <b-upload multiple v-model="citizenCard" drag-drop required expanded accept="image/*" v-if="!isLoading">
                <section class="section">
                    <div class="content has-text-centered">
                        <p>
                            <b-icon icon-pack="fas" icon="image" size="is-large"> </b-icon>
                        </p>
                        <p>Upload your employee's citizen's card.</p>
                    </div>
                </section>

            </b-upload>
            <span class="file-name mt-2" v-if="citizenCard">
                {{ citizenCard.name }}
            </span>
        </b-field>

        <b-button :disabled="isSubmitBtnDisabled" :loading="isLoading" @click.prevent="addEmployee()" class="mt-5"
            type="is-primary" expanded>
            Add employee
        </b-button>
    </section>
</template>

<script>
import { ActivityFormMixin } from '@/CreationFormComponents/mixins/ActivityFormMixin'
import { db } from "../firebase";
import GoBack from "../components/elements/GoBack.vue";
import { mapState, mapMutations, mapActions } from "vuex";
import { UsersMutations, UsersStates, UserActions } from "../store/Storetypes";
export default {
    mixins: [ActivityFormMixin],
    components: {
        GoBack
    },
    data() {
        return {
            email: null,
            citizenCard: null,
            driversLicense: null,
            name: '',
            isLoading: false,
            uploadedCitizenCard: null,
            uploadedDriversLicense: null,
        }
    },
    mounted() {
        this.getEmployees(this.user?.uid)
    },
    computed: {
        ...mapState({
            user: state => state.users[UsersStates.USER],
            employees: state => state.users[UsersStates.EMPLOYEES],
        }),
        isSubmitBtnDisabled() {
            if (!this.email ||
                !this.citizenCard ||
                !this.driversLicense ||
                !this.name ||
                !this.email?.length ||
                !this.name?.length) return true
            return false
        }
    },
    methods: {
        ...mapMutations({
            updateEmployeeData: UsersMutations.UPDATE_EMPLOYEE_DATA,
        }),
        ...mapActions({
            getEmployees: UserActions.GET_COMPANY_EMPLOYEES,
        }),
        uploadCitizenCard() {
            const storageRef = db
                .storage()
                .ref(`${this.user.uid}/${this.email}/${this.citizenCard.name}`)
                .put(this.citizenCard);
            storageRef.on(
                `state_changed`,
                () => {
                    this.uploadDriversLicense()
                },
                (error) => {
                    console.log(error.message);
                },
                () => {
                    storageRef.snapshot.ref.getDownloadURL().then((url) => {
                        this.uploadedCitizenCard = url;
                    });
                }
            );
        },
        uploadDriversLicense() {
            const storageRef = db
                .storage()
                .ref(`${this.user.uid}/${this.email}/${this.driversLicense.name}`)
                .put(this.driversLicense);
            storageRef.on(
                `state_changed`,
                () => {
                    console.log('loading photo');
                },
                (error) => {
                    console.log(error.message);
                },
                () => {
                    storageRef.snapshot.ref.getDownloadURL().then((url) => {
                        this.driversLicense = url;
                    });
                }
            );
        },
        addEmployee() {
            const employeeHasBeenAdded = this.checkIfEmployeeExists()
            if (employeeHasBeenAdded) return

            const updatedEmployeesList = this.user.employees ? [...this.user.employees,
            {
                email: this.email,
                citizenCard: this.uploadedCitizenCard,
                driversLicense: this.uploadedDriversLicense,
                name: this.name,
                isAccountActive: true,
                registrationFinished: false
            }] :
                [{
                    email: this.email,
                    citizenCard: this.uploadedCitizenCard,
                    driversLicense: this.uploadedDriversLicense,
                    name: this.name,
                    isAccountActive: true,
                    registrationFinished: false
                }]
            db.firestore()
                .collection("guides")
                .doc(this.user.uid)
                .update({ employees: updatedEmployeesList })
                .then(() => {
                    this.updateEmployeeData(updatedEmployeesList)
                    this.uploadCitizenCard()
                    this.sendEmailInvitation()
                })
                .catch(() => {
                    this.$buefy.toast.open({
                        duration: 10000,
                        message:
                            "There has been an error adding this employee. Try again later.",
                        position: "is-bottom",
                        type: "is-danger",
                    });
                });
        },
        checkIfEmployeeExists() {
            if (this.email === this.user?.email){
                this.$buefy.toast.open({
                    duration: 10000,
                    message: "You can't add this email as an employee. If you want to add yourself as an employee use a different email",
                    position: "is-bottom",
                    type: "is-danger",
                });
                return true
            } 
            if (!this.employees || !this.employees.length) return false
            const hasBeenAdded = this.employees.some((employee) => employee.email === this.email)
            if (hasBeenAdded){
                this.$buefy.toast.open({
                    duration: 10000,
                    message:
                        "This employee has already been added to the company",
                    position: "is-bottom",
                    type: "is-danger",
                });
            }
            return hasBeenAdded
        },
        sendEmailInvitation() {
            const emailInfo = {
                companyName: this.user.displayName || this.user.name,
                email: this.email,
                // TODO: UPDATE LINK TO LIVE
                // registrationLink: `http://localhost:8080/${this.user.uid}/guider/register`
                registrationLink: `https://guiders.pt/${this.user.uid}/guider/register`
            }
            fetch(`${process.env.VUE_APP_API_URL}/send/mail/employee-invitation`, {
                method: "POST",
                body: JSON.stringify(emailInfo),
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "same-origin",
            }).then((response) => {
                if (response.status !== 200) return false
                this.$buefy.toast.open({
                    duration: 10000,
                    message: "We sent an invitation to your employee's email to create an account",
                    position: "is-bottom",
                    type: "is-success",
                });
                if (window.history.length) {
                    this.$router.go(-1);
                    return
                }
            }).catch(() => {
                this.$buefy.toast.open({
                    duration: 10000,
                    message:
                        "There has been an error sending the email invitation to the employee. If this error persists contact support.",
                    position: "is-bottom",
                    type: "is-danger",
                });
            });
        }
    }
}
</script>