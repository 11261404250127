<template>
    <section>
        <div class="widgets">
            <div @click="$emit('toggleVisibility', false)">
                <router-link :to="{ name: 'upcoming-activities' }">
                    <RouteButtonWithIcon title="Upcoming activities"
                        description="See all the scheduled activities information.">
                        <template v-slot:icon>
                            <b-icon pack="fas" icon="tasks"></b-icon>
                        </template>
                    </RouteButtonWithIcon>
                </router-link>
            </div>

            <div @click="$emit('toggleVisibility', false)">
                <router-link :to="{ name: 'manage-payments' }">
                    <RouteButtonWithIcon title="Manage Payments" description="See all the tours made and their payment status.">
                        <template v-slot:icon>
                            <b-icon pack="fas" icon="comment-dollar"></b-icon>
                        </template>
                    </RouteButtonWithIcon>
                </router-link>
            </div>

            <div @click="$emit('toggleVisibility', false)">
                <router-link :to="{ name: 'manage-guiders' }">
                    <RouteButtonWithIcon title="Manage guiders" description="See their information and payment history.">
                        <template v-slot:icon>
                            <b-icon pack="fas" icon="users-cog"></b-icon>
                        </template>
                    </RouteButtonWithIcon>
                </router-link>
            </div>
            <div @click="$emit('toggleVisibility', false)">
                <router-link :to="{ name: 'manage-activities' }">
                    <RouteButtonWithIcon title="Manage content" description="Manage activities, tickets, events and blog post.">
                        <template v-slot:icon>
                            <b-icon pack="fas" icon="th-list"></b-icon>
                        </template>
                    </RouteButtonWithIcon>
                </router-link>
            </div>
            <div @click="$emit('toggleVisibility', false)">
                <router-link :to="{ name: 'affiliates-program' }">
                    <RouteButtonWithIcon title="Affiliates program" description="Manage affiliates.">
                        <template v-slot:icon class="mr-2">
                            <b-icon pack="fas" icon="user" ></b-icon>
                        </template>
                    </RouteButtonWithIcon>
                </router-link>
            </div>

            <div @click="toggleModal(true)">
                <RouteButtonWithIcon title="Send registration code"
                    description="Send codes to allow companies to register to our platform.">
                    <template v-slot:icon>
                        <b-icon pack="fas" icon="unlock"></b-icon>
                    </template>
                </RouteButtonWithIcon>
            </div>

            <div @click="signOutUser" class="mb-6">
                <RouteButtonWithIcon title="Sign out" is-danger>
                    <template v-slot:icon>
                        <b-icon pack="fas" icon="sign-out-alt"></b-icon>
                    </template>
                </RouteButtonWithIcon>
            </div>
        </div>
        <b-modal v-model="isCodeGenerationModalActive">
            <GenerateGuiderRegistrationCode @toggleModal="toggleModal" />
        </b-modal>
    </section>
</template>

<script>
import RouteButtonWithIcon from '../components/elements/RouteButtonWithIcon.vue';
import GenerateGuiderRegistrationCode from './GenerateGuiderRegistrationCode.vue';
export default {
    components: {
        RouteButtonWithIcon,
        GenerateGuiderRegistrationCode
    },
    data() {
        return {
            isCodeGenerationModalActive: false
        }
    },
    methods: {
        signOutUser(){
            this.$emit('toggleVisibility', false)
            this.$emit('signOut')
        },
        toggleModal(isActive) {
            this.isCodeGenerationModalActive = isActive
        }
    },
}
</script>

<style scoped lang="scss">
.column {
    padding: 0 0.75rem;
}

::v-deep {
    .columns.is-mobile {
        display: -webkit-inline-box;
    }

    .modal-close {
        right: 36px;
        top: 36px;
    }
}
</style>