<template>
    <section>
        <b-loading v-if="isLoading" is-full-page v-model="isLoading" :can-cancel="false"></b-loading>
        <div v-else>
            <GoBack route-name-humanized="Plan your day" />
            <section v-if="availableTours.length">
                <p class="is-blue title mb-3 pt-4">What activities you’d like to work on?</p>

                <div class="mt-4" v-for="(activity, index) in availableTours" :key="index">
                      <ActivityInfoCardListItem :activity-info="getActivityInfo(activity.activityId)"
                        :date="Number(activity.date)" :time="activity.time" :activity-group-info="activity"
                        :end-time="activity.endTime" @on-btn-click="onActivityClicked" /> 
                </div>

                <p class="is-size-8 mb-6">We only accept vehicles with the minimum of 4 seats. Make sure you select the
                    right amount of seats. If you are not sure how many
                    seats you’ll have always play on the safe side! Failure to do so will result in a penalization.</p>

                <div class="page-footer">
                    <b-button @click="submitChoices" type="is-primary" expanded :disabled="!canSubmitForm">
                        Submit
                    </b-button>
                </div>
            </section>
            <EmptyStateSearch v-if="!availableTours || !availableTours.length" title="No activities for tomorrow"
                subtitle="Check again later" type="no-messages" />

        </div>
    </section>
</template>

<script>
import ActivityInfoCardListItem from '../components/elements/ActivityInfoCardListItem.vue'
import { mapState, mapMutations, mapActions } from "vuex";
import { db } from "../firebase";
import { ActivityMixin, UserMixin } from "../mixins";
import { GuidersStates, GuidersMutations, ActivitiesStates, ActivitiesActions, UsersStates } from '../store/Storetypes'
import { SeatNumbers } from '../enums/SeatNumbers'
import GoBack from '../components/elements/GoBack.vue'
import { updateActivityGroup, dateWithMonthAndDay, hasDateTimePassed } from '../helpers';

import EmptyStateSearch from '../components/elements/EmptyStateSearch.vue';

export default {
    components: {
        ActivityInfoCardListItem,
        GoBack,
        EmptyStateSearch
    },
    mixins: [ActivityMixin, UserMixin],
    data() {
        return {
            availableTours: [],
            selectedActivities: [],
            isLoading: true,
            hasGroupIdInRoute: true,
            SeatNumbers,
            dateWithMonthAndDay,
            hasDateTimePassed,
            numberOfSeats: null
        }
    },
    beforeDestroy() {
        this.setGuiderWaitlistedActivities([])
    },
    async mounted() {
        await this.getAvailableTours()
        //if (this.guiderActivities.length < 1)
        this.getGuiderTours()
        if (this.activities.length < 1) await this.getActivities()
        this.numberOfSeats = this.user?.numberOfSeats
    },
    computed: {
        ...mapState({
            user: state => state.users[UsersStates.USER],
            isEmployee: state => state.users[UsersStates.IS_EMPLOYEE],
            guiderActivities: state => state.guiders[GuidersStates.GUIDER_WAITLISTED_ACTIVITIES],
            activities: state => state.activities[ActivitiesStates.ACTIVITIES]
        }),
        guiderHasThisActivityScheduled() {
            if (!this.guiderActivities || this.guiderActivities.length < 1 || !this.notificationActivity) return false
            const guiderAccepted = this.guiderActivities.find((activity) => activity.id === this.notificationActivity.id)
            return guiderAccepted ? true : false
        },
        canSubmitForm() {
            return this.selectedActivities.length
        },
        dateTomorrow() {
            let dateTomorrow = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1)
            dateTomorrow = Date.parse(dateTomorrow)
            return dateTomorrow
        },
        hasTimeLimitPassed() {
            // Get the current time
            const currentTime = new Date();
            // Create a Date object for 5:00 PM (17:00)
            const specificTime = new Date();
            specificTime.setHours(17);
            specificTime.setMinutes(0);

            const hasTimeLimitPassed = currentTime.getHours() > specificTime.getHours() ||
                (currentTime.getHours() === specificTime.getHours() && currentTime.getMinutes() > specificTime.getMinutes())
            return hasTimeLimitPassed
        }
    },
    methods: {
        ...mapMutations({
            setGuiderWaitlistedActivities: GuidersMutations.SET_GUIDER_WAITLISTED_ACTIVITIES,
        }),
        ...mapActions({
            getActivities: ActivitiesActions.GET_ACTIVITIES
        }),
        async getAvailableTours() {
            let dateTomorrow = new Date()
            dateTomorrow.setDate(dateTomorrow.getDate() + 1)
            dateTomorrow.setHours(0, 0, 0, 0);


            let dateToday = new Date()
            dateToday.setHours(0, 0, 0, 0);

            const availableTours = await db.firestore().collection("groups").where('date', 'in', [Number(Date.parse(dateToday)), Number(Date.parse(dateTomorrow))]).get();
            if (availableTours.empty) {
                this.availableTours = []
                this.isLoading = false
                return
            }
            const activities = []
            availableTours.forEach(doc => {
                const activity = doc.data()
                //if (!activity?.waitListedGuiders || !activity?.waitListedGuiders.length) {
                /* if (!activity?.waitListedGuiders || !activity?.waitListedGuiders.length) {
                    activities.push(activity)
                    return
                } */

                if ( activity.date === Number(Date.parse(dateToday)) && !hasDateTimePassed(activity.endTime, activity.date)){
                    return
                }

                if (!activity?.guiders || !activity?.guiders.length) {
                    activities.push(activity)
                    return
                }
                const idToSearch = this.isEmployee ? this?.user?.companyId : this?.user?.uid
                /* const isGuiderWaitlisted = activity?.waitListedGuiders.filter((activity) => activity.guiderId === idToSearch)
                if (!isGuiderWaitlisted || !isGuiderWaitlisted.length) {
                    activities.push(activity)
                } */
                const wasSelected = activity?.guiders.filter((activity) => activity.guiderId === idToSearch)
                if (!wasSelected || !wasSelected.length) {
                    activities.push(activity)
                }

            });


            this.availableTours = this.orderActivitiesByDate(activities)
            this.isLoading = false
        },
         orderActivitiesByDate(activities) {
            const sortedActivities = activities.sort((a, b) => {
                return a.date - b.date;
            });
           return sortedActivities
        },
        onActivityClicked({ isSelected, activityGroup, nOfSeats, numberOfVehicles }) {
            if (isSelected) {
                const group = { ...activityGroup, nOfSeats, numberOfVehicles }
                this.selectedActivities.push(group)
                return
            }
            this.selectedActivities = this.selectedActivities.filter((group) => group.id !== activityGroup.id)
        },
        submitChoices() {
            this.selectedActivities.forEach((group) => {
                const nOfSeats = group.nOfSeats
                const numberOfVehicles = group.numberOfVehicles
                delete group.nOfSeats;
                delete group.numberOfVehicles
                this.addGuiderToWaitList(group, nOfSeats, numberOfVehicles)
            })
        },
        addGuiderToWaitList(activityGroup, nOfSeats, numberOfVehicles) {
            // const propertyName = this.hasTimeLimitPassed ? 'guiders' : 'waitListedGuiders'
            const updatedActivityGroup = {
                ...activityGroup,
                guiders: !activityGroup.guiders ? [{
                    guiderId: this.guiderUid,
                    nOfSeats: nOfSeats,
                    numberOfVehicles: numberOfVehicles,
                    name: this.user.name,
                    status: 0,
                    notes: null
                }] : [...activityGroup.guiders, {
                    guiderId: this.guiderUid,
                    nOfSeats: nOfSeats,
                    numberOfVehicles: numberOfVehicles,
                    name: this.user.name,
                    status: 0,
                    notes: null
                }]
            }

            // console.log({ updatedActivityGroup });
            // this.setGuiderWaitlistedActivities(updatedActivityGroup) DELETE THIS FOR GOOD ???
            updateActivityGroup({
                activityGroup,
                updatedActivityGroup
            })
            this.$router.push({ name: "company-upcoming-activities" })
        },

    }
}
</script>

<style lang="scss" scoped>
.container--info {
    align-items: flex-start;
}

.helper-text {
    color: #9286B4;
    font-size: 14px;
}

.no-activities {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 5rem
}

.description {
    font-size: 0.95rem;
    font-weight: 300;
    color: #0e3d4d;
    white-space: pre-line;
}

.not-found {
    height: 12rem
}

.page-footer {
    bottom: 0;
    background-color: #fff;
    width: 100%;
    position: fixed;
    left: 0;
    padding: 20px;
}

::v-deep {
    .activity-title {
        max-width: 75vw;

    }
}
</style>