<template>
  <section>
    <p class="is-blue page-title mb-5">Account settings</p>
    <div v-for="(button, index) in buttons" :key="index">
      <LargeButtonWithArrow v-bind="button" />
    </div>
    <div @click="toggleDeleteWarning(true)">
      <LargeButtonWithArrow title="Delete account" is-danger :with-arrow="false" />
    </div>

    <b-modal v-model="isDeleteWarningActive">
      <WarningModal @toggleDeleteWarning="toggleDeleteWarning" @onActionConfirmed="deleteAccount"
        confirm-button-text="Delete account" title="Delete your account?" subtitle="Are you sure you want to delete your account? 

  You can’t undo this action." icon="trash">

      </WarningModal>
    </b-modal>

  </section>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import LargeButtonWithArrow from '../components/elements/LargeButtonWithArrow.vue';
import { GuiderSettings, UserSettings, CompanySettings } from '../enums/Settings';
import { db } from "../firebase";
import { UsersMutations, UsersStates } from '../store/Storetypes';
import WarningModal from '../components/elements/WarningModal.vue';
export default {
  components: {
    LargeButtonWithArrow,
    WarningModal
  },
  data() {
    return {
      isDeleteWarningActive: false
    }
  },
  methods: {
    ...mapMutations({
      setUser: UsersMutations.SET_USER
    }),
    deleteAccount() {


      // Guider-> delete account, remove from db, remove stripe account, sign out
      // User -> delete account, remove from db, sign out
    },
    toggleDeleteWarning(isActive) {
      this.isDeleteWarningActive = isActive
    },
    f() {
      db.auth()
        .currentUser.delete()
        .then(() => {
          this.setUser(null)
          this.$router.push({ name: "home" });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  computed: {
    ...mapState({
      isCompany: state => state.users[UsersStates.IS_COMPANY],
      isEmployee: state => state.users[UsersStates.IS_EMPLOYEE]
    }),
    buttons() {
      if (this.isCompany) return CompanySettings
      if (this.isEmployee) return GuiderSettings
      return UserSettings
    }
  }
}

</script>

<style lang="scss" scoped>
::v-deep {
  .modal-close {
    right: 38px;
    top: 38px;
  }
}
</style>