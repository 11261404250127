<template>
    <section>
        <b-loading is-full-page v-model="isLoading">
            <b-icon pack="fas" icon="sync-alt" size="is-small" class="mr-2" custom-class="fa-spin">
            </b-icon>
            <b>Redirecting</b>
        </b-loading>
    </section>
</template>

<script>
export default {
    data() {
        return {
            isLoading: true
        }
    },
    created() {
        this.goToStripeSetUp()
    },
    methods: {
        async goToStripeSetUp() {
            await fetch(`${process.env.VUE_APP_API_URL}/stripe/affiliates/retrieve`, {
                method: "POST",
                body: JSON.stringify({ accountId: this.$route.params.accountId }),
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "same-origin",
            }).then((response) => response.json()).then((data) => {
                if (data.success) {
                    window.location.href = data.accountLink;
                }
                // show error messsage
            })
                .catch(() => {
                    // show error messsage
                });
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep{
    .svg-inline--fa.fa-w-16 {
        width: 0.75em;
    }
}
</style>