<template>
    <section>
        <b-loading v-if="isLoading" is-full-page v-model="isLoading" :can-cancel="false"></b-loading>
        <div v-else class="mt-3">
            <div class="is-flex is-justify-content-space-between">
                <p class="is-blue page-title mb-5">Your Employees </p>

                <b-button icon-left="plus" tag="router-link" :to="{ name: 'add-employee' }" size="is-small"
                    type="is-primary" class="add-btn mb-5" rounded></b-button>
            </div>
            <EmptyStateSearch class="mb-5" v-if="!employees || !employees.length" type="no-messages"
                title="You don't have any employees yet" />


            <div v-for="(employee) in employees" :key="employee.uid">
                <router-link class="display-flex--flex-start mb-5 container--outlined"
                    :is="employee?.registrationFinished ? 'router-link' : 'span'"
                    :to="{ name: 'manage-employee', params: { employeeId: employee.uid } }">
                    <img class="profile-photo mr-4" :src="employee.photoURL">
                    <div class="employee-details">
                        <p class="has-text-weight-medium">{{ employee.displayName }}</p>
                        <p class="mb-2">{{ employee.email }}</p>
                        <div class="unpaid is-size-8" v-if="!employee?.registrationFinished">Registration pending</div>
                        <!--   <div class="paid is-size-8" v-else-if="getPaymentStatus(employee.uid)">Paid</div>
                        <div class="unpaid is-size-8" v-else>Needs payment</div> -->

                        <b-button class="mt-1" icon-right="chevron-right" type="is-ghost"
                            v-if="employee?.registrationFinished"></b-button>
                    </div>

                </router-link>
            </div>

        </div>
    </section>
</template>


<script>
import { mapState, mapActions } from "vuex";
import { UsersStates, UserActions, GuiderActions, GuidersStates } from '../store/Storetypes'
import EmptyStateSearch from "../components/elements/EmptyStateSearch.vue";
export default {
    components: {
        EmptyStateSearch
    },
    data() {
        return {
            isLoading: false,
        }
    },
    computed: {
        ...mapState({
            user: state => state.users[UsersStates.USER],
            employees: state => state.users[UsersStates.EMPLOYEES],
            finishedActivities: state => state.guiders[GuidersStates.FINISHED_ACTIVITIES],
        }),
    },
    mounted() {
        this.getEmployees(this.user?.uid)
        this.getFinishedActivities(this.user?.uid)
    },
    methods: {
        ...mapActions({
            getEmployees: UserActions.GET_COMPANY_EMPLOYEES,
            getFinishedActivities: GuiderActions.GET_FINISHED_ACTIVITIES
        }),
        getPaymentStatus(employeeId) {
            if (!this.finishedActivities || !this.finishedActivities.length) return true
            const employeeFinishedActivities = this.finishedActivities.filter((activity) => activity.employeeId === employeeId)
            const needsPayment = employeeFinishedActivities.every((data) => (data.employeeId === employeeId) && data.guiderHasBeenPayed)
            return needsPayment
        }
    }
}

</script>

<style lang="scss" scoped>
.add-btn.button {
    border-radius: 4px;
}

.employee-details {
    width: -webkit-fill-available;

    .button {
        float: right;
    }
}

.profile-photo {
    width: 3.5rem;
    object-fit: contain;
    height: 3.5rem;
    border-radius: 50%;
}


.paid,
.unpaid {
    border-radius: 3px;
    padding: 3px 12px;
    width: fit-content;
}

.paid {
    background-color: #EBFBEF;
    color: #37D25F;
}

.unpaid {
    background-color: #F9EDF7;
    color: #FF495C;
}

::v-deep {
    .modal-close {
        right: 38px;
        top: 38px;
    }
}
</style>


