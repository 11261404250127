<template>
    <section>
        <div v-if="computedActivities.length" class="mt-4">
            <div v-for="(group, index) in computedActivities.slice(0, limit)" :key="index">
                <div v-if="!showPreviousOnly && isCompany" @click="toggleUnscheduleWarning(true, group)" class="edit--btn">
                    <b-icon pack="fas" icon="pen-alt"></b-icon>
                    <span>Update</span>
                </div>
                <div class="column is-6" @click="goToActivityGroupDetails(group.id, group.activityId)">
                    <ActivityInfoCard :number-of-vehicles="isCompany ? group.numberOfVehicles: undefined" :n-of-seats="isAdmin? group.groupSize : group.nOfSeats"
                        :is-title-clickable="isTitleClickable" :activity-info="getActivityInfo(group.activityId)"
                        :end-time="group.endTime" :time="group.time" :date="group.date" :activity-group-info="group" />
                </div>
            </div>
        </div>

        <EmptyStateSearch :title="emptyStateTitle" v-else>
            <template v-if="isCompany" v-slot:link>
                <b-button tag="router-link" type="is-primary" rounded :to="{ name: 'schedule' }">See available
                    activities</b-button>
            </template>
        </EmptyStateSearch>
        <b-modal v-model="isUnscheduleWarningActive">
            <WarningModal @toggleDeleteWarning="toggleUnscheduleWarning" @onActionConfirmed="unscheduleActivity"
                confirm-button-text="Update" title="Update available seats and vehicles"
                subtitle="" icon="calendar-day">
                <template slot="custom">
                    <span class="mt-4 mb-4 is-size-6"><b>Reminder:</b> You have been assigned <b>{{ assignedSeats }}</b>
                        seats.</span>
                        <div class="mb-3 mt-4 ml-5 pl-2">
                            <b-field label="How many seats do you have?">
                                <b-numberinput class="participants-field" type="is-primary" v-model="numberOfSeats" min="0"
                                    :max="assignedSeats - 1" controls-alignment="right"></b-numberinput>
                            </b-field>
                        </div>

                        <div class="mb-3 ml-5 pl-2">
                            <b-field label="How many vehicles do you have?">
                                <b-numberinput class="participants-field" type="is-primary" v-model="numberOfVehicles"
                                    min="0" controls-alignment="right"></b-numberinput>
                            </b-field>
                        </div>
                </template>
            </WarningModal>
        </b-modal>
    </section>
</template>

<script>

import ActivityInfoCard from '../components/elements/ActivityInfoCard.vue'
import WarningModal from '../components/elements/WarningModal.vue';
import { mapState, mapActions } from "vuex";
import { ActivitiesActions, ActivitiesStates, UsersStates, GuidersStates, GuiderActions } from '../store/Storetypes'
import { ActivityMixin } from "../mixins";
import { dateWithMonthAndDay, getHourHumanized, hasDatePassed, updateActivityGroup } from '../helpers';
import EmptyStateSearch from '../components/elements/EmptyStateSearch.vue';
export default {
    components: {
        ActivityInfoCard,
        EmptyStateSearch,
        WarningModal
    },
    mixins: [ActivityMixin],
    data() {
        return {
            dateWithMonthAndDay,
            hasDatePassed,
            getHourHumanized,
            isUnscheduleWarningActive: false,
            activityToUnschedule: [],
            numberOfSeats: 0,
            assignedSeats: 0,
            numberOfVehicles: 0
        }
    },
    props: {
        maxNumberToShow: {
            type: Number,
            default: undefined
        },
        needsAttention: {
            type: Boolean,
            default: false
        },
        showTodayOnly: {
            type: Boolean,
            default: false
        },
        showPreviousOnly: {
            type: Boolean,
            default: false
        },
        showUpcomingOnly: {
            type: Boolean,
            default: false
        },
        isClickable: {
            type: Boolean,
            default: true
        },
        isTitleClickable: {
            type: Boolean,
            default: true
        },
        emptyStateTitle: {
            type: String,
            default: undefined
        },
        employeeOnly: {
            type: Boolean,
            default: false
        },
    },
    created() {
        this.getActivityGroups()
        if (!this.showPreviousOnly) return
        const idToSearch = this.isEmployee ? this.user.companyId : this.user.uid
        this.getFinishedActivities(idToSearch)
    },
    methods: {
        ...mapActions({
            getActivityGroups: ActivitiesActions.GET_ACTIVITY_GROUPS,
            getSelectedActivityGroupData: ActivitiesActions.GET_ACTIVITY_GROUP_DATA,
            getFinishedActivities: GuiderActions.GET_FINISHED_ACTIVITIES
        }),
        toggleUnscheduleWarning(isActive, groupData) {
            this.isUnscheduleWarningActive = isActive
            if (isActive) {
                this.activityToUnschedule = groupData
                const guiderIndex = groupData.guiders.findIndex((guider) => guider.guiderId === this.user?.uid)
                if (!guiderIndex < 0) return
                this.assignedSeats = Number(groupData.guiders[guiderIndex]?.nOfSeats)
            }
        },
        async unscheduleActivity() {
            let activityGroup = this.activityToUnschedule
            // TODO: add confirmation modal and success notification
            const guiderIndex = activityGroup.guiders.findIndex((guider) => guider.guiderId === this.user?.uid)
            if (!guiderIndex < 0) return
            const previouslyFilledSeats = Number(activityGroup.guiders[guiderIndex]?.nOfSeats)
            if (this.numberOfSeats >= previouslyFilledSeats) {
                this.$buefy.toast.open({
                    duration: 10000,
                    message: "The updated number of available seats can't be equal or bigger then your assigned number of seats.",
                    position: "is-bottom",
                    type: "is-danger",
                });
                return
            }
            const missingSeats = previouslyFilledSeats - this.numberOfSeats
            let updatedActivityGroup = {}
            if (missingSeats === previouslyFilledSeats || this.numberOfVehicles === 0) {
                // removes activity
                const updatedGuiders = activityGroup.guiders.filter((guider) => guider.guiderId !== this.user?.uid)
                updatedActivityGroup = { ...activityGroup, guiders: updatedGuiders }
            }
            else {
                activityGroup.guiders[guiderIndex].nOfSeats = this.numberOfSeats
                activityGroup.guiders[guiderIndex].numberOfVehicles = this.numberOfVehicles
                updatedActivityGroup = activityGroup
            }
            const message = this.numberOfSeats === 0 || this.numberOfVehicles === 0 ? "This activity has been canceled." : "Your available seats for this activity have been updated."

            updateActivityGroup({ activityGroup, updatedActivityGroup, message })
            const slackNotification = {
                tourName: this.getActivityInfo(activityGroup.activityId).title,
                date: activityGroup.date,
                time: activityGroup.time,
                endTime: activityGroup.endTime,
                activityGroupId: activityGroup.id,
                missingSeats
            }
            this.sendSlackNotification(slackNotification)
            await this.getActivityGroups()
            // when canceled
            this.isUnscheduleWarningActive = false
        },
        async sendSlackNotification(data) {
            await fetch(`${process.env.VUE_APP_API_URL}/activities/slack/new-activity`, {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "same-origin",
            })
                .then((response) => response.json()).catch(err => console.log(err));
        },
        goToActivityGroupDetails(activityGroupId, activityId) {
            if (!this.isClickable) return
            if (this.isCompany || this.isEmployee) {
                this.$router.push({
                    name: "start-activity",
                    params: {
                        activityId,
                        activityGroupId: activityGroupId
                    }
                });
                return
            }
            if (this.isAdmin) {
                this.getSelectedActivityGroupData(activityGroupId)
                this.$router.push({ name: 'activity-group-details', params: { activityGroup: activityGroupId } })
            }

        },
        formatFinishedActivities(finishedActivities) {
            const activities = finishedActivities.filter(activity => !activity.activityInProgress);
            return activities.map(activity => {
                return {
                    ...activity,
                    date: Date.parse(activity.startTime).toString(),
                    time: getHourHumanized(activity.startTime),
                    endTime: getHourHumanized(activity.endTime)
                }
            });
        },
        hasDateTimePassed(targetTime, targetDate) {
            const splitTargetTime = targetTime.split(':')
            const targetHour = splitTargetTime[0];
            const targetMinute = splitTargetTime[1];
            let date = new Date(Number(targetDate))
            date = date.setHours(targetHour, targetMinute, '00')
            return new Date(date) > new Date();
        },
        getNumberOfFilledSeats(guidersArray) {
            if (!guidersArray || !guidersArray.length) return 0
            let nOfSeats = 0
            guidersArray.map((guider) => nOfSeats += Number(guider.nOfSeats))
            return nOfSeats
        },
        orderActivitiesByDate(activities) {
            const sortedActivities = activities.sort((a, b) => {
                return a.date - b.date;
            });
            return sortedActivities
        },
        getGuiderNumberOfSeats(activityGroup) {
            if (!activityGroup?.guiders || !activityGroup?.guiders.length) return
            const idToFilter = this.isCompany ? this.user.uid : this.user.companyId;
            const guiderInfo = activityGroup?.guiders.find((guider) => guider.guiderId === idToFilter)
            if (!guiderInfo) return
            return {
                nOfSeats: guiderInfo.nOfSeats,
                numberOfVehicles: guiderInfo.numberOfVehicles
            }
        }
    },
    computed: {
        ...mapState({
            user: state => state.users[UsersStates.USER],
            isEmployee: state => state.users[UsersStates.IS_EMPLOYEE],
            isCompany: state => state.users[UsersStates.IS_COMPANY],
            isAdmin: state => state.users[UsersStates.IS_ADMIN],
            activityGroups: state => state.activities[ActivitiesStates.ACTIVITY_GROUPS],
            finishedActivities: state => state.guiders[GuidersStates.FINISHED_ACTIVITIES],
        }),
        limit() {
            return this.maxNumberToShow || this.activityGroups.length
        },
        computedActivities() {
            let activityGroups = this.activityGroups.map((activityGroup) => {
                return {
                    ...activityGroup,
                    ...this.getGuiderNumberOfSeats(activityGroup)
                }
            })
            if (this.isCompany || this.isEmployee) {
                const idToFilter = this.isCompany ? this.user.uid : this.user.companyId;
                activityGroups = activityGroups.filter(activity => activity?.guiders && activity?.guiders.length && activity?.guiders.some(guider => guider.guiderId === idToFilter))
            }

            if (this.employeeOnly && this.showPreviousOnly) {
                const employeeFinishedActivities = this.finishedActivities.filter((activity) => activity.employeeId === this.user.uid && !activity?.activityInProgress)
                return this.formatFinishedActivities(employeeFinishedActivities)
            }
            let dateToday = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
            if (this.needsAttention) {
                const activitiesThatNeedAttention = activityGroups.filter((activity) => Number(activity.groupSize) > this.getNumberOfFilledSeats(activity.guiders) && !hasDatePassed(activity.date))
                return this.orderActivitiesByDate(activitiesThatNeedAttention)
            }
            if (this.showTodayOnly) {
                return activityGroups.filter((activity) => (dateWithMonthAndDay(activity.date) === dateWithMonthAndDay(Date.parse(dateToday)) && this.hasDateTimePassed(activity.endTime, activity.date)))
            }
            if (this.showUpcomingOnly) {
                return activityGroups.filter((activity) => dateWithMonthAndDay(activity.date) !== dateWithMonthAndDay(Date.parse(dateToday)) && !hasDatePassed(activity.date))
            }
            if (this.showPreviousOnly) {
                if (this.isAdmin) return this.activityGroups.filter((activity) => hasDatePassed(activity.date))
                return this.formatFinishedActivities(this.finishedActivities)
            }
            return activityGroups
        }
    }

}
</script>

<style scoped lang="scss">
.column {
    padding: 0;
}

.edit--btn {
    font-size: 12px;
    align-items: center;
    font-weight: bold;
    display: flex;
    justify-content: flex-end;
}

::v-deep {
    .modal-close {
        right: 36px;
        top: 36px;
    }

    .b-numberinput input[type=number] {
        width: 40px
    }

    .b-numberinput.field.is-grouped div.control {
        -webkit-box-flex: 0;
        -ms-flex-positive: 1;
        flex-grow: 0;
        flex-shrink: 0;
    }

    .participants-field .control {

        .button.is-primary[disabled],
        .button.is-primary {
            background-color: transparent;
            border-color: transparent;
            color: #0e3d4d
        }

        .button.is-primary[disabled] {
            opacity: .1
        }
    }

}</style>