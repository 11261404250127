<template>
  <section class="full-screen">
    <QrcodeStream @detect="onDetect">
      <img @click="closeScanner" class="icon-close" src="../assets/circle-xmark.svg" />
      <div v-if="!ticketDetected" class="helper-container">
        <img class="icon-small" src="../assets/qrcode.svg" />
        <span>Scan the ticket</span>
      </div>

      <div v-else class="scanned-ticket--info">
        <ActivityInfoCard 
        :is-title-clickable="false"
        :activity-info="selectedActivityData"
                                :end-time="scannedTicketDetails.endTime"
                                 :time="scannedTicketDetails.time"
                                :date="scannedTicketDetails.date"/>

        <b-button class="g--primary-btn mt-3" @click="addTicketToGroup" type="is-primary" expanded :disabled="hasReachedParticipantsLimit">
          Add ticket to group
        </b-button>
      </div>
    </QrcodeStream>
  </section>
</template>

<script>
// TODO: Change page and route name
import { db } from "../firebase";
import { QrcodeStream } from 'vue-qrcode-reader'
import ActivityInfoCard from "./elements/ActivityInfoCard.vue";
import { mapMutations, mapState } from "vuex";
import { ActivitiesStates, GuidersMutations, GuidersStates, TicketsMutations, TicketsStates } from '../store/Storetypes'
import { UserMixin, TicketMixin } from "../mixins";
export default {
  components: {
    QrcodeStream,
    ActivityInfoCard
  },
  mixins: [UserMixin, TicketMixin],
  data() {
    return {
      savedGroupsIdsList: [],
      ticketDetected: false,
      scannedTicketDetails: {},
      scannedTicket: null,
    }
  },
  computed: {
    ...mapState({
      groupParticipantsInfo: state => state.guiders[GuidersStates.ACTIVITY_PARTICIPANTS],
      scannedTickets: state => state.guiders[GuidersStates.SCANNED_TICKETS],
      selectedActivityData: state => state.activities[ActivitiesStates.SELECTED_ACTIVITY_DATA],
      savedGroupsList: state => state.tickets[TicketsStates.SAVED_GROUPS_LIST],
    }),
    hasReachedParticipantsLimit(){
     return this.groupParticipantsInfo.length >= this.selectedActivityData?.maxNumOfParticipants 
    }
  },
  methods: {
    ...mapMutations({
      updateActivityParticipants: GuidersMutations.UPDATE_ACTIVITY_PARTICIPANTS,
      setSavedGroupsList: TicketsMutations.SET_SAVED_GROUPS_LIST,
      setScannedTickets: GuidersMutations.UPDATE_SCANNED_TICKETS
    //  getSelectedActivityData: GuidersMutations.GET_ACTIVITY_DATA
    }),
    closeScanner() {
      if (window.history.length) {
        this.$router.go(-1);
        return
      } 
      this.$router.push({ name: "start-activity" });
    },
    checkIfParticipantHasBeenAdded() {
      const hasParticipantBeenAdded = this.groupParticipantsInfo.find((participantInfo) => participantInfo.ticketId === this.scannedTicket)
      return hasParticipantBeenAdded ? true : false
    },
    async onDetect(promise) {
      try {
        const { content } = await promise

        if (!content) {
          this.notifyError("Couldn't read QR Code")
          return;
        }
        
        if (this.hasReachedParticipantsLimit) {
          this.notifyError("This group has reached the maximum number of participants.")
          return;
        }

        this.scannedTicket = content
        const hasParticipantBeenAdded = await this.checkIfParticipantHasBeenAdded()
        if (hasParticipantBeenAdded) {
          this.notifyError("This participant has already been added to the group.")
          return;
        }
        if (!this.scannedTickets.includes(content)) {
          this.setScannedTickets(content)
        //  this.scannedTickets.push(content)
        }

        const ticketInfo = this.getTicketData(content)
        this.getTicketInfo(ticketInfo)
        // change in Db to isTicketValid = false so we know the tour has started
        // add uid of the guider that read the qr code 

      } catch (error) {
        this.notifyError(error)
      }
    },
    async getTicketInfo({ groupId, participantId, ticketNumber }) {
      if (!this.savedGroupsIdsList.includes(groupId)) {
        const groupRef = db.firestore().collection('groups').doc(groupId);
        const doc = await groupRef.get();
        if (!doc.exists) {
          // TODO: Notify invalid qr code- can't find group that the ticket belongs to
          this.notifyError()
          return
        } else {
          this.savedGroupsIdsList.push(groupId)
          const groupInfo = doc.data()
          //this.savedGroupsList[groupId] = groupInfo
          this.setSavedGroupsList({groupInfo, groupId})
        }
      }
      // TODO: Show this info in the ticket and show the 'add to group' button
      // date:  this.savedGroupsList[groupId].date &&  time: this.savedGroupsList[groupId].time name: get from metadata
      const participantInfo = this.getParticipantInfo({ groupId, participantId})
      const groupInfo = this.savedGroupsList[groupId]

      this.scannedTicketDetails = {
        date: groupInfo.date,
        time: groupInfo.time,
        endTime: groupInfo.endTime,
        participantInfo,
        ticketId: this.scannedTicket
      }

      // check if ticket is valid
      if (this.savedGroupsList[groupId].activityId !== this.selectedActivityData.id) {
        this.notifyError('This ticket belongs to a different tour.')
        return
      }
      if (!participantInfo) {
        this.notifyError()
        return
      }
      const isTicketValid = participantInfo.tickets[ticketNumber].isTicketValid

      if (!isTicketValid) {
        this.notifyError('This ticket has already been validated.')
        return
      }

      this.ticketDetected = true
      //this.startTour()
    },
    addTicketToGroup() {
      // TODO: Shouldn't exceed the max. num of participants
      this.ticketDetected = false
      this.updateActivityParticipants(this.scannedTicketDetails)
    },
    // TODO: move to create-group
   /*  startTour() {
      let numOfScannedTickets = 0
      this.scannedTickets.map((ticket) => {
        const ticketInfo = this.getTicketData(ticket)
        const { groupId, participantId, ticketNumber } = ticketInfo
        if (!groupId || !participantId || !ticketNumber) {
          this.notifyError("Couldn't start tour")
          return
        }

        const participantInfo = this.getParticipantInfo({ groupId, participantId})

        if (!participantInfo) {
          this.notifyError()
          return
        }

        const updatedParticipants = [...this.savedGroupsList[groupId].participants]
        const participantToUpdateIndex = this.getParticipantIndex({ groupId, participantId })

        updatedParticipants[participantToUpdateIndex].tickets[ticketNumber] = {
          ...updatedParticipants[participantToUpdateIndex].tickets[ticketNumber],
          isTicketValid: false,
          guiderUid: this.guiderUid
        }
        
// TODO: add affiliate commission to earned amount and pay them

        db.firestore()
          .collection("groups")
          .doc(groupId)
          .update(updatedParticipants)
          .then(() => {
            numOfScannedTickets++
          })
          .catch((err) => {
            this.$buefy.toast.open({
              duration: 10000,
              message: err.message,
              position: "is-bottom",
              type: "is-danger",
            });
          });
      })
      if (numOfScannedTickets !== this.scannedTickets.length) {
        this.notifyError('One or more tickets have not been validated correctly')
        return
      }

      // TODO: START ACTIVITY
    }, */
  /*   getTicketData(ticket) {
      const qrCodeContent = ticket.split('@');
      const groupId = qrCodeContent[0]
      const participantId = qrCodeContent[1]
      const ticketNumber = qrCodeContent[2]
      return { groupId, participantId, ticketNumber }
    },
    getParticipantInfo({ groupId, participantId }) {
      return this.savedGroupsList[groupId].participants.find((participant) => participant.participantId === participantId)
    },
    getParticipantIndex({ groupId, participantId }) {
      return this.savedGroupsList[groupId].participants.findIndex((participant) => participant.participantId === participantId)
    },
    notifyError(message = this.defaultInvalidQRCodeTitle) {
      this.$buefy.notification.open({
        duration: 10000,
        message,
        position: "is-bottom",
        type: "is-danger",
        hasIcon: true,
        closable: false,
        iconSize: 'is-small'
      });
    } */
  }
}
</script>

<style lang="scss" scoped>
.full-screen {
  height: 100vh;
}

.icon-close {
  height: 1.75rem;
  float: right;
  margin: 1rem;
  width: auto;
}

.helper-container {
  width: -webkit-fill-available;
  padding: 1rem;
  border-radius: 10px;
  background-color: #00000040;
  color: white;
  margin: 1rem;
  margin-top: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.icon-small {
  height: 1rem;
  width: auto;
  margin-right: .5rem;
}

.scanned-ticket--info {
  padding: 1rem;
  bottom: 0;
  position: fixed;
  width: 100%;
}
</style>