import {
    ActivitiesMutations as Mutations,
    ActivitiesStates as States,
    ActivitiesActions as Actions
} from '../Storetypes'
import { db } from "../../firebase";

export default {
    state: {
        [States.SELECTED_ACTIVITY_ID]: undefined,
        [States.ACTIVITIES]: undefined,
        [States.ACTIVITY_GROUPS]: undefined,
        [States.SELECTED_ACTIVITY_DATA]: undefined,
        [States.SELECTED_ACTIVITY_GROUP_DATA]: undefined,
    },
    mutations: {
        [Mutations.SET_ACTIVITIES](state, activities) {
            state[States.ACTIVITIES] = activities
        },
        [Mutations.SET_ACTIVITY_GROUPS](state, activityGroups) {
            state[States.ACTIVITY_GROUPS] = activityGroups
        },
        [Mutations.SET_SELECTED_ACTIVITY_ID](state, id) {
            state[States.SELECTED_ACTIVITY_ID] = id
        },
        [Mutations.SET_SELECTED_ACTIVITY_DATA](state, activityData) {
            state[States.SELECTED_ACTIVITY_DATA] = activityData
        },
        [Mutations.SET_SELECTED_ACTIVITY_GROUP_DATA](state, groupData) {
            state[States.SELECTED_ACTIVITY_GROUP_DATA] = groupData
        },
        [Mutations.UPDATE_ACTIVITY_DATA](state, { activityIndex, activityData }) {
            state[States.ACTIVITIES][activityIndex] = activityData
        },
        [Mutations.DELETE_ACTIVITY](state, activityId) {
            const filteredActivities = state[States.ACTIVITIES].filter((activity) => activity.id !== activityId)
            state[States.ACTIVITIES] = filteredActivities
        },
        [Mutations.GET_ACTIVITY_DATA](state, activityId) {
            const activities = state[States.ACTIVITIES]
            const selectedActivityIndex = activities.findIndex((activity) => activity.id === activityId)
            if (selectedActivityIndex < 0) return {}
            state[States.SELECTED_ACTIVITY_DATA] = activities[selectedActivityIndex]
           /*  this.$nextTick(() => {
                document.dispatchEvent(new Event('rendered-page'));
            }) */
            return activities[selectedActivityIndex]
        }
    },
    actions: {
        [Actions.GET_ACTIVITIES]({ commit }) {
            let Tours = [];
            db.firestore()
                .collection("tours")
                .where("isVisible", "==", true)
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        Tours.push({
                            ...doc.data(),
                            id: doc.id,
                        });
                    });
                    const toursSortedByCheapest = Tours.sort((a, b) => {
                        return b.duration - a.duration;
                    });
                    commit(Mutations.SET_ACTIVITIES, toursSortedByCheapest)
                })
            return Tours
        },
        async [Actions.GET_ACTIVITY_BY_IDS](activityIds) {
            let Tours = [];
            for (const docId of activityIds) {
                try {
                    const docRef = await db.firestore()
                        .collection("tours").doc(docId).get();
                    if (docRef.exists) {
                        Tours.push({
                            ...docRef.data(),
                            id: docRef.id,
                        });
                    }
                } catch (error) {
                    console.error(`Error getting document with ID ${docId}:`, error);
                }
            }
            return Tours
        },
        [Actions.GET_ACTIVITY_GROUPS]({ commit }) {
            let ActivityGroups = [];
            db.firestore()
                .collection("groups")
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        ActivityGroups.push({
                            ...doc.data(),
                            id: doc.id,
                        });
                    });
                    const activityGroupsSortedByDate = ActivityGroups.sort((a, b) => {
                        return b.date - a.date;
                    });
                    commit(Mutations.SET_ACTIVITY_GROUPS, activityGroupsSortedByDate)
                })
        },
        [Actions.GET_ACTIVITY_DATA]({ state }, activityId) {
            const activities = state[States.ACTIVITIES]
            const selectedActivityIndex = activities.findIndex((activity) => activity.id === activityId)
            if (selectedActivityIndex < 0) return {}
            state[States.SELECTED_ACTIVITY_DATA] = activities[selectedActivityIndex]
            return activities[selectedActivityIndex]
        },
        [Actions.GET_ACTIVITY_GROUP_DATA]({ commit }, groupId) {
            db.firestore()
                .collection("groups")
                .doc(groupId)
                .get()
                .then((doc) => {
                    if (doc.data()) {
                        commit(Mutations.SET_SELECTED_ACTIVITY_GROUP_DATA, doc.data())
                    }
                })
                .catch((err) => {
                    this.$buefy.toast.open({
                        duration: 10000,
                        message: err.message,
                        position: "is-bottom",
                        type: "is-danger",
                    });
                });
        }
    }
}