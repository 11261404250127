import { OptionalActivityFields } from '../../enums/OptionalActivityFields.js'

export const ActivityFormMixin = {
    methods: {
        isOptionalField(fieldToCheck){
            const isOptional = Object.values(OptionalActivityFields).includes(fieldToCheck)
            return isOptional ? `${fieldToCheck} (optional)` : fieldToCheck
        }
    }
  }
