<template>
    <section>
        <div v-if="isLoading">
            <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
        </div>

        <section v-else>
            <p class="is-size-5 is-blue">Welcome back <strong class="is-blue">{{ user.displayName }}! 👋</strong></p>

            <div class="mb-2" v-if="isGuider && (!user?.slackID || !user?.numberOfSeats)">
                <b-button type="is-ghost" tag="router-link" :to="{ name: 'guider-basic-information' }"
                    icon-right="arrow-right" size="is-small" class="has-text-weight-semibold">Complete your
                    profile</b-button>
            </div>

            <b-loading v-if="isLoading" is-full-page v-model="isLoading" :can-cancel="false"></b-loading>
            <div v-else>
                <div v-if="hasActivityInProgress" class="container--info activity-in-progress--container mb-5">
                    <p class="title is-blue mb-4">Activity in progress</p>
                    <p><span class="is-blue">Activity name:</span> {{ activityInProgress.activityData.title }} </p>
                    <p><span class="is-blue">Started at:</span> {{ activityInProgress.startedAtHumanized }}</p>
                    <b-button class="mt-4 g--primary-btn" type="is-primary" @click="finishActivity">
                        🎉 Finish activity
                    </b-button>
                </div>
 <!--
                <div class="display-flex-space-btw mb-5">
                    <p class="is-blue page-title">Upcoming tours</p>
                    <span class="is-light-purple link">See all</span> 
                </div>

                <div v-if="activities">
                    <div v-for="(activitiesByDate, index) in activities" :key="index">
                        <div class="subtitle mt-5">
                            {{ getActivityDate(activitiesByDate[0].date) }}
                        </div>

                        <div v-for="(activity, index) in activitiesByDate" :key="index" class="columns">
                            <div class="column is-6">
                                <div @click="toggleUnscheduleWarning(true)" class="cancel--btn">
                                    <b-icon pack="fas" icon="calendar-times"></b-icon>
                                    <span>Cancel</span>
                                </div>
                                <ActivityInfoCard :activity-info="getActivityInfo(activity.activityId)"
                                    :end-time="activity.endTime"
                                    :pay-amount="getActivityInfo(activity.activityId).guiderPayment" :time="activity.time"
                                    :activity-group-info="activity" :is-clickable="isActivityForToday(activity.date)" />
                            </div>
                        </div>
                    </div>
                </div> -->

                <div class="display-flex-space-btw mb-5">
                    <p class="is-blue page-title">Upcoming activities</p>
                    <router-link :to="{ name: 'company-upcoming-activities' }">
                        <span class="is-light-purple link">See all</span>
                    </router-link>
        
                </div>
        
                <UpcomingActivities empty-state-title="No activities scheduled for today" showTodayOnly :max-number-to-show="4" />

            </div>
        </section>
        <b-modal v-model="isUnscheduleWarningActive">
            <WarningModal @toggleDeleteWarning="toggleUnscheduleWarning" @onActionConfirmed="unscheduleActivity"
                confirm-button-text="Unschedule" title="Unschedule activity"
                subtitle="Are you sure you want to unschedule this activity?" icon="calendar-times">

            </WarningModal>
        </b-modal>
        <ActivityInProgress/>
    </section>
</template>

<script>

//import ActivityInfoCard from '../components/elements/ActivityInfoCard.vue'
import UpcomingActivities from '../Admin/UpcomingActivities.vue';
//import RouteButtonWithIcon from '../components/elements/RouteButtonWithIcon.vue';
//import InlineNotification from '../components/elements/InlineNotification.vue'
import WarningModal from '../components/elements/WarningModal.vue';
import { mapState, mapMutations, mapActions } from "vuex";
import { GuidersStates, GuidersMutations, GuiderActions, UsersStates, ActivitiesActions, ActivitiesStates } from '../store/Storetypes'
import { groupBy, formattedDate, dateWithName, updateActivityGroup, hasDatePassed } from '../helpers';
import { ActivityMixin, UserMixin, GuidersMixin } from "../mixins";
import { db, FieldValue } from '../firebase';
import ActivityInProgress from '../components/elements/ActivityInProgress.vue'
export default {
    components: {
        //ActivityInfoCard,
        UpcomingActivities,
        //RouteButtonWithIcon,
        //InlineNotification,
        WarningModal,
        ActivityInProgress
    },
    mixins: [ActivityMixin, UserMixin, GuidersMixin],
    created() {
        this.getAllGuiderTours()
        this.getGuiderFinishedActivities(this.user.uid)
        this.listenForGuiderDataUpdates()
        if (!this.user.hasFinishedSetUp)
            this.getStripeAccountInfo()
    },
    data() {
        return {
            activities: [],
            isLoading: true,
            formattedDate,
            dateWithName,
            hasDatePassed,
            isUnscheduleWarningActive: false
        }
    },
    watch: {
        guiderActivities() {
            const activities = this.guiderActivities.filter((activity) => !hasDatePassed(activity.date))
            if (!activities.length) return
            this.activities = groupBy(activities, 'date')
        },
    },
    methods: {
        ...mapMutations({
            setActivityInProgress: GuidersMutations.SET_ACTIVITY_IN_PROGRESS_DATA,
            setGuiderActivities: GuidersMutations.SET_GUIDER_ACTIVITIES,
            setFinishedActivities: GuidersMutations.SET_FINISHED_ACTIVITIES
        }),
        ...mapActions({
            getGuiderFinishedActivities: GuiderActions.GET_FINISHED_ACTIVITIES,
            getActivityData: ActivitiesActions.GET_ACTIVITY_DATA
        }),
        toggleUnscheduleWarning(isActive) {
            this.isUnscheduleWarningActive = isActive
        },
        onStripeDashboardWidgetClicked() {
            if (!this.user.hasFinishedSetUp) return
            this.goToStripeDashboard()
        },
        onStripeAccountSetUpRequest() {
            this.isLoading = true
           // this.setUpStripeAccount()
        },
        finishActivity() {
            // add tour to db
            this.addFinishedActivitiesDataToDB()
        },
        /* getActivityDate(date) {
            return this.isActivityForToday(date) ? "Today" : dateWithName(date)
        },
        isActivityForToday(date) {
            let dateToday = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
            const isDateToday = formattedDate(dateToday) === formattedDate(date)
            return isDateToday
        }, */
        async getAllGuiderTours() {
            const activities = await this.getGuiderTours()
            this.activities = activities
            if (!activities.length) {
                this.isLoading = false
                return
            }
            this.sortActivities(activities)

        },
        sortActivities(activities) {
            const sortedActivities = activities.sort((a, b) => {
                return a.date - b.date;
            });
            const activitiesToGroup = sortedActivities.filter((activity) => !hasDatePassed(activity.date))
            this.groupActivitiesByDate(activitiesToGroup)
        },
        groupActivitiesByDate(sortedActivities) {
            this.activities = groupBy(sortedActivities, 'date')
            this.isLoading = false
        },
        unscheduleActivity(activityGroup) {
            // TODO: add confirmation modal and success notification
            const updatedGuidersList = activityGroup.guiders.filter((guider) => guider.guiderId !== this.guiderUid)
            let updatedActivityGroup = {
                ...activityGroup,
                guiders: updatedGuidersList
            }

            // TODO: make request to notify guiders on slack- WILL GO THIS ROUTE REMOVE BELLOW SOLUTION
            /* if (!activityGroup.waitListedGuiders) {
                updateActivityGroup({ activityGroup, updatedActivityGroup })
                this.removeActivityFromGuiderList(activityGroup.id)
                return
            } */
           /*  if (activityGroup.waitListedGuiders && activityGroup.waitListedGuiders.length) {
                const guiderIdToAddToList = activityGroup.waitListedGuiders[0]
                updatedActivityGroup.guiders.push(guiderIdToAddToList)
                updatedActivityGroup.waitListedGuiders = updatedActivityGroup.waitListedGuiders.filter((guiderId) => guiderId !== guiderIdToAddToList)
            } */
            updateActivityGroup({ activityGroup, updatedActivityGroup })
            this.removeActivityFromGuiderList(activityGroup.id)
            const slackNotification = {
                tourName: this.getActivityInfo(activityGroup.activityId).title,
                date: activityGroup.date,
                time: activityGroup.time,
                endTime: activityGroup.endTime,
                activityGroupId: activityGroup.id
            }
            this.sendSlackNotification(slackNotification)
        },
        removeActivityFromGuiderList(activityGroupId) {
            const updatedGuiderActivities = this.guiderActivities.filter((activity) => activity.id !== activityGroupId)
            this.setGuiderActivities(updatedGuiderActivities)
        },
        async sendSlackNotification(data) {
            await fetch(`${process.env.VUE_APP_API_URL}/activities/slack/new-activity`, {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "same-origin",
            })
                .then((response) => response.json())
        },
        /*   async addFinishedActivitiesDataToDB() {
              this.getGuiderFinishedActivities({guiderId: this.user.uid})
              const tours = this.guiderFinishedActivities || []
              const activityData = await this.getActivityData(this.activityInProgress.activityId)
              const data = {
                  name: this.user.displayName,
                  uid: this.user.uid,
                  stripeAccountId: this.user.stripeAccountId || '',
                  tours: [
                      ...tours,
                      {
                          activityId: this.activityInProgress.activityId,
                          startTime: this.activityInProgress.startedAt,
                          endTime: new Date().toISOString(),
                          transferred: false,
                          payAmount: activityData.guiderPayment
                      }
                  ]
  
              }
              console.log(data);
              db.firestore()
                  .collection("finishedActivities")
                  .doc(this.user?.uid)
                  .set(data)
                  .catch(() => {
                      this.$buefy.toast.open({
                          duration: 10000,
                          message:
                              "There has been an error finishing your tour. Try again later, if this error persists please contact support.",
                          position: "is-bottom",
                          type: "is-danger",
                      });
                  });
          } */
        async addFinishedActivitiesDataToDB() {
            const activityData = await this.getActivityData(this.activityInProgress.activityId)
            const finishedActivityData = {
                activityId: this.activityInProgress.activityId,
                activityGroupId: this.activityInProgress.groupId,
                startTime: this.activityInProgress.startedAt,
                endTime: new Date().toISOString(),
                guiderHasBeenPayed: false,
                transferred: false,
                payAmount: activityData.guiderPayment,
                employeeId: this.user?.uid
            }
            
            db.firestore()
                .collection("finishedActivities")
                .doc(this.user?.companyId)
                .update({ tours: FieldValue.arrayUnion(finishedActivityData) })
                .then(() => {
                    const updatedFinishedActivities = [
                        ...this.guiderFinishedActivities,
                        finishedActivityData
                    ]
                    this.setFinishedActivities(updatedFinishedActivities)
                    this.setActivityInProgress({})
                })
                .catch(() => {
                    this.$buefy.toast.open({
                        duration: 10000,
                        message:
                            "There has been an error finishing your tour. Try again later, if this error persists please contact support.",
                        position: "is-bottom",
                        type: "is-danger",
                    });
                });
        }
    },
    computed: {
        ...mapState({
            user: state => state.users[UsersStates.USER],
            isGuider: state => state.users[UsersStates.IS_USER_A_GUIDER],
            isEmployee: state => state.users[UsersStates.IS_EMPLOYEE],
            guiderActivities: state => state.guiders[GuidersStates.GUIDER_ACTIVITIES],
            activityInProgress: state => state.guiders[GuidersStates.ACTIVITY_IN_PROGRESS_DATA],
            isCreatingStripeLink: state => state.guiders[GuidersStates.IS_CREATING_STRIPE_ACCOUNT_LINK],
            guiderFinishedActivities: state => state.guiders[GuidersStates.FINISHED_ACTIVITIES],
            selectedActivity: state => state.activities[ActivitiesStates.SELECTED_ACTIVITY_DATA],
        }),
        hasActivityInProgress() {
            if (!this.activityInProgress) return false
            return Object.values(this.activityInProgress).length
        },
        dashboardWidgetClass() {
            const hasStripeAccount = this.user.hasFinishedSetUp
            return hasStripeAccount ? '' : 'widget-not-allowed'
        },
        // TODO: IS THIS ABOUT THE COMPANY OR EMPLOYEE
        guiderEarnings() {
            let earnings = 0
            if (!this.guiderFinishedActivities.length) return earnings

            this.guiderFinishedActivities.forEach((tour) => {
                if (!tour.transferred) earnings += tour.payAmount
            })
            return earnings
        }
    }
}

</script>

<style lang="scss" scoped>
.widgets {
    display: grid;
    grid-template-columns: repeat(2, minmax(min-content, 1fr));
    gap: 1.5rem;
}

.cancel--btn {
    color: #FE6D73;
    font-size: 12px;
    align-items: center;
    margin-right: 10px;
    text-align: end;
    margin-bottom: .5rem;
}

.container--info {
    gap: 0
}

.columns {
    margin-bottom: 0;
}

.column {
    padding: 0.75rem .5rem
}

.widget-not-allowed {
    opacity: .4;
    cursor: not-allowed;
}

.subtitle {
    display: flex;
    font-size: 16px;
    align-items: center;
    margin-bottom: .5rem
}

.activity-in-progress--container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 2rem;
}

.link {
    font-size: .85rem;
}

::v-deep {
    .modal-close {
        right: 38px;
        top: 38px;
    }
}
</style>