export const CompanySettings = [
    {
      title: 'Company information',
      description: 'Change the company photo, company name, email and number of available seats.',
      routerLink: 'company-profile'
    },
    {
      title: 'Change password',
      routerLink: 'company-change-password'
    },
  ]

  export const GuiderSettings = [
    {
      title: 'Personal information',
      description: 'Change your profile photo, name, email, Slack ID',
      routerLink: 'guider-basic-information'
    },
    {
      title: 'Change password',
      routerLink: 'guider-change-password'
    }
  ]

  export const UserSettings = [
    {
      title: 'Basic information',
      description: 'Change your name, email and profile photo.',
      routerLink: 'guider-basic-information'
    },
    {
      title: 'Change password',
      routerLink: ''
    },
    /* {
      title: 'Delete account',
      routerLink: '',
      action: 'deleteAccount',
      isDanger: true,
      withArrow: false
    }, */
  ]