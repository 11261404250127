<template>
    <section>
        <div v-if="isLoading">
            <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
          </div>
          <section v-else>
        <GoBack class="mb-5" :route-name-humanized="guider.displayName || guider.name" />

        <!-- <div class="user-details columns is-mobile">
            <div class="column is-2">
                <img class="profile-photo" :src="guider.photoURL">
            </div>

            <div class="column">
                <p class="has-text-weight-medium">{{ guider.displayName }}</p>
            </div>
        </div> -->
        <div class="container--info mb-5">
            <div class="columns">
                <div class="column is-6">
                    <div class=" display-flex-space-btw">
                        <p class="has-text-weight-medium">ID</p>
                        <div class="display-flex-space-btw">
                            <p class="guider-details">{{ guider.uid || 'Unknown' }}</p>
                            <div @click="copyTextToClipboard(guider.uid)">
                                <b-icon :class="{ 'is-invisible': !guider.uid }" pack="far" class="ml-2 icon--light-grey"
                                    icon="copy"></b-icon>
                            </div>
                        </div>
                    </div>

                    <div class="display-flex-space-btw">
                        <p class="has-text-weight-medium">Stripe account</p>
                        <div class="display-flex-space-btw">
                            <p class="guider-details">{{ guider.stripeAccountId || 'Unknown' }}</p>
                            <div @click="copyTextToClipboard(guider.stripeAccountId)">
                                <b-icon :class="{ 'is-invisible': !guider.stripeAccountId }" pack="far"
                                    class="ml-2  icon--light-grey" icon="copy"></b-icon>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="column is-6">
                    <div class="display-flex-space-btw">
                        <p class="has-text-weight-medium">Phone</p>
                        <div class="display-flex-space-btw">
                            <p class="guider-details">{{ guider.phoneNumber || 'Unknown' }}</p>
                            <div @click="copyTextToClipboard(guider.phoneNumber)">
                                <b-icon :class="{ 'is-invisible': !guider.phoneNumber }" pack="far"
                                    class="ml-2  icon--light-grey" icon="copy"></b-icon>
                            </div>
                        </div>
                    </div>

                    <div class="display-flex-space-btw">
                        <p class="has-text-weight-medium">Email</p>
                        <div class="display-flex-space-btw">
                            <p class="guider-details">{{ guider.email || 'Unknown' }}</p>
                            <div @click="copyTextToClipboard(guider.email)">
                                <b-icon :class="{ 'is-invisible': !guider.email }" pack="far" class="ml-2  icon--light-grey"
                                    icon="copy"></b-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <p class="has-text-weight-bold mb-2">Payment history</p>

        <b-field grouped group-multiline class="field" :class="{ 'is-invisible': !checkedRows.length }">
            <b-button label="Clear selection" type="is-danger" icon-left="times" @click="checkedRows = []" class="mr-2" />
            <b-button label="Transfer" :loading="transferring" type="is-primary" :disabled="!checkedRows.length"
                @click="transferToGuider" />
        </b-field>

        <b-table class="pt-4" :data="computedFinishedActivities" ref="table" paginated per-page="5" detailed detail-key="id"
            detail-transition="fade" show-detail-icon aria-next-label="Next page" aria-previous-label="Previous page"
            aria-page-label="Page" aria-current-label="Current page" pagination-rounded :checked-rows.sync="checkedRows"
            checkbox-position="left" checkable :is-row-checkable="(row) => !row.transferred">
            <b-table-column field="tourName" label="Tour name" sortable v-slot="props">
                {{ getActivityInfo(props.row.activityId).title }}
            </b-table-column>

            <b-table-column field="date" label="Date" v-slot="props">

                {{ getDate(props.row.startTime) }}
            </b-table-column>

            <b-table-column field="payAmount" label="Payment amount" sortable v-slot="props">
                {{ props.row.payAmount }} €
            </b-table-column>

            <b-table-column field="transferred" label="Payment status" sortable v-slot="props">
                <b-tag :type="getTransferStatus(props.row.transferred)"> <span class="is-capitalized">{{
                    getTransferStatus(props.row.transferred) }} </span></b-tag>
            </b-table-column>

            <template #detail="props">
                <div v-if="props.row.transferId" class="mb-2">
                    <p>Transfer ID</p>
                    <div class="is-flex">
                        {{ props.row.transferId }}
                        <div @click="copyTextToClipboard(props.row.transferId)">
                            <b-icon :class="{ 'is-invisible': !props.row.transferId }" pack="far"
                                class="ml-2  icon--light-grey" icon="copy"></b-icon>
                        </div>
                    </div>
                </div>

                <p><b>Start time</b></p>
                {{ getHourHumanized(props.row.startTime) }}

                <p class="mt-2"><b>End time</b></p>
                {{ getHourHumanized(props.row.endTime) }}

                <p class="mt-2"><b>Number of participants</b></p>
                {{ props.row.numOfScannedTickets || 'Unknown' }}
            </template>

            <template #empty>
                <div class="has-text-centered">This company hasn't done any activities yet</div>
            </template>
        </b-table>
        </section>
    </section>
</template>

<script>
import GoBack from "../components/elements/GoBack.vue";
import { mapState, mapActions, mapMutations } from "vuex";
import { formattedDate } from '../helpers';
import { GuiderActions, GuidersStates, GuidersMutations, UserActions, UsersStates } from '../store/Storetypes'
import { ActivityMixin, HelpersMixin } from "../mixins";
export default {
    components: {
        GoBack
    },
    mixins: [ActivityMixin, HelpersMixin],
    data() {
        return {
            formattedDate,
            checkedRows: [],
            transferring: false,
            isLoading: true
        }
    },
    created() {
        if(this.guider.uid !== this.$route.params.guiderUid){
            this.retrieveEmployeeData(this.$route.params.guiderUid)
            this.setSelectedGuider(this.guiderData)
            this.isLoading = false
        }
        this.getFinishedActivities(this.guider.uid)
        this.isLoading = false
    },
    methods: {
        ...mapMutations({
            setGuiderFinishedActivities: GuidersMutations.SET_FINISHED_ACTIVITIES,
            setSelectedGuider: GuidersMutations.SET_SELECTED_GUIDER,
        }),
        ...mapActions({
            sendPayment: GuiderActions.PROCESS_GUIDER_PAYMENT,
            getFinishedActivities: GuiderActions.GET_FINISHED_ACTIVITIES,
            retrieveEmployeeData: UserActions.GET_EMPLOYEE_DATA
        }),
        copyTextToClipboard(text) {
            if (!text) return
            this.copyToClipboard(text)
        },
        getHourHumanized(dateString) {
            const date = new Date(dateString);
            let h = date.getHours();
            let m = date.getMinutes();
            return `${h}:${m}`
        },
        getTransferStatus(status) {
            return status ? 'paid' : 'unpaid'
        },
        getDate(date) {
            const dateParsed = Date.parse(date)
            return formattedDate(dateParsed)
        },
        async getUpdatedToursArray() {
            await this.getFinishedActivities(this.guider.uid)
            const updatedToursArray = this.finishedActivities.map((tour) => {
                const isSelected = this.checkedRows.some((activity) => activity.activityGroupId === tour.activityGroupId)
                if (isSelected) return { ...tour, transferred: true }
                return tour
            })
            return updatedToursArray;
        },
        async transferToGuider() {
            this.transferring = true
          /*   let amount = 0
            this.checkedRows.forEach((row) => {
                amount += row.payAmount * 100
            }) */
            const updatedToursArray = await this.getUpdatedToursArray()
         console.log({updatedToursArray});
          /*   const data = {
                amount,
                destination: this.guider.stripeAccountId,
                guiderUid: this.guider.uid,
                updatedToursArray
            }
            await fetch(`${process.env.VUE_APP_API_URL}/stripe/guider/pay`, {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "same-origin",
            })
                .then((response) => response.json()).then((data) => {
                    this.transferring = false
                    if (data.success) {
                        this.setGuiderFinishedActivities(updatedToursArray)
                        this.$buefy.toast.open({
                            duration: 10000,
                            message: 'The money has been transferred to the company',
                            position: "is-bottom",
                            type: "is-success",
                        });
                        return
                    }
                    this.$buefy.toast.open({
                        duration: 10000,
                        message: data.message,
                        position: "is-bottom",
                        type: "is-danger",
                    });
                })
                .catch((error) => {
                    this.transferring = false
                    this.$buefy.toast.open({
                        duration: 10000,
                        message: error.message,
                        position: "is-bottom",
                        type: "is-danger",
                    });
                }); */

        }
    },
    computed: {
        ...mapState({
            guider: state => state.guiders[GuidersStates.SELECTED_GUIDER],
            guiderData: state => state.users[UsersStates.SELECTED_EMPLOYEE_DATA],
            finishedActivities: state => state.guiders[GuidersStates.FINISHED_ACTIVITIES],
        }),
        computedFinishedActivities() {
            return this.finishedActivities.map((activity, index) => { return { ...activity, id: index } })
        }
    },
    // TODO: IF GUIDER DATA IS NOT IN VUEX -> RETRIEVE IT
}
</script>

<style lang="scss" scoped>
.container--info {
    display: block;
    padding: 1rem;

    .columns {
        margin: 0;
    }

    .column {
        padding: 0
    }
}

.profile-photo {
    width: auto;
    object-fit: contain;
    max-height: 3rem;
    border-radius: 50%;
}

.guider-details {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: start;
    max-width: 10rem;
    color: #373F4F;
    font-weight: 300;
}

.user-details.columns {
    .column:not(:first-child) {
        padding: 0;
    }
}

.paid,
.unpaid {
    font-weight: 600;
}

.paid {
    background-color: #EBFBEF;
    color: #37D25F;
}

.unpaid {
    background-color: #F9EDF7;
    color: #FF4868;

}

.column {
    padding: 0 0.75rem;
}
</style>