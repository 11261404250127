import { db } from "../firebase";
import { ToastProgrammatic as toast } from "buefy";

export const updateActivityGroup = ({ activityGroup, updatedActivityGroup, message = 'Your schedule has been updated.' }) => {
    db.firestore()
        .collection("groups")
        .doc(activityGroup.id)
        .update(updatedActivityGroup)
        .then(() => {
            // TODO: Show success toast notification
            toast.open({
                duration: 10000,
                message,
                position: "is-bottom",
                type: "is-success",
            });
        })
        .catch((err) => {
            toast.open({
                duration: 10000,
                message: err.message,
                position: "is-bottom",
                type: "is-danger",
            });
        });
}