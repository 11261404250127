import {
  GuidersMutations as Mutations,
  GuidersStates as States,
  TicketsStates,
  GuiderActions as Actions
} from '../Storetypes'
import { db } from "../../firebase";

/* const getCompanyId = (employeeId) => {
  return employeeId.split('@')[0]
} */

export default {
  state: {
    [States.GUIDER_UID]: '',
    [States.IS_GUEST_GUIDER]: false,
    [States.IS_USER_A_GUIDER]: false,
    [States.IS_CREATING_STRIPE_ACCOUNT_LINK]: false,
    [States.GUIDER_DATA]: [],
    [States.GUIDERS]: [],
    [States.SELECTED_GUIDER]: [],
    [States.ACTIVITY_PARTICIPANTS]: [],
    [States.SCANNED_TICKETS]: [],
    [States.ACTIVITY_IN_PROGRESS_DATA]: null,
    [States.GUIDER_ACTIVITIES]: [],
    [States.GUIDER_WAITLISTED_ACTIVITIES]: [],
    [States.FINISHED_ACTIVITIES]: [],
    [States.EMPLOYEE_FINISHED_ACTIVITIES]: [],
  },

  mutations: {
    // TODO: Get guider uid on login
    [Mutations.SET_GUIDER_UID](state, guiderUID) {
      state[States.GUIDER_UID] = guiderUID
    },
    [Mutations.SET_GUIDERS](state, guiders) {
      state[States.GUIDERS] = guiders
    },
    [Mutations.SET_SELECTED_GUIDER](state, guider) {
      state[States.SELECTED_GUIDER] = guider
    },
    [Mutations.SET_EMPLOYEE_FINISHED_ACTIVITIES](state, finishedActivities) {
      state[States.EMPLOYEE_FINISHED_ACTIVITIES] = finishedActivities
    },
    [Mutations.SET_GUIDER_ACTIVITIES](state, activities) {
      state[States.GUIDER_ACTIVITIES] = activities
    },
    [Mutations.SET_IS_CREATING_STRIPE_ACCOUNT_LINK](state, value) {
      state[States.IS_CREATING_STRIPE_ACCOUNT_LINK] = value
    },
    [Mutations.SET_GUIDER_WAITLISTED_ACTIVITIES](state, activities) {
      state[States.GUIDER_WAITLISTED_ACTIVITIES] = activities
    },
    [Mutations.UPDATE_ACTIVITY_PARTICIPANTS](state, newParticipant) {
      state[States.ACTIVITY_PARTICIPANTS].push(newParticipant)
      // state[States.ACTIVITY_PARTICIPANTS] = [...state[States.ACTIVITY_PARTICIPANTS], newParticipant]
    },
    [Mutations.UPDATE_SCANNED_TICKETS](state, scannedTicket) {
      state[States.SCANNED_TICKETS] = [...state[States.SCANNED_TICKETS], scannedTicket]
    },
    [Mutations.RESET_ACTIVITY_GROUP_CREATION](state) {
      state[States.ACTIVITY_PARTICIPANTS] = []
      state[States.SCANNED_TICKETS] = []
      state[TicketsStates.SAVED_GROUPS_LIST] = []
    },
    [Mutations.SET_IS_GUEST_GUIDER](state, isGuestGuider) {
      state[States.IS_GUEST_GUIDER] = isGuestGuider
    },
    [Mutations.SET_FINISHED_ACTIVITIES](state, finishedActivities) {
      state[States.FINISHED_ACTIVITIES] = finishedActivities
    },
    [Mutations.SET_ACTIVITY_IN_PROGRESS_DATA](state, activityData) {
      state[States.ACTIVITY_IN_PROGRESS_DATA] = activityData
    }
  },
  actions: {
    // update to work with company structure
    /* [Actions.SET_FINISHED_ACTIVITIES]({ commit, state }, { guiderId, updatedActivitiesInfo, employeeId }) {
      console.log({ guiderId, updatedActivitiesInfo });
      let dataToUpdate = {}
      let companyId = undefined

      if (employeeId) {
        const employees = state[UsersStates.USER].employees
        const employeeIndex = employees.findIndex((employee) => employee.uid === employeeId)
        if (employeeIndex >= 0) {
          employees[employeeIndex].finishedActivities = updatedActivitiesInfo
          dataToUpdate = employees
        }
        companyId = getCompanyId(employeeId)
      } else {
        dataToUpdate = { finishedActivities: updatedActivitiesInfo }
      }

      const docToUpdate = companyId || guiderId
      console.log({ dataToUpdate, docToUpdate });
      db.firestore()
        .collection("guides")
        .doc(docToUpdate)
        .update(dataToUpdate)
        .then(() => {
          commit(Mutations.SET_FINISHED_ACTIVITIES, updatedActivitiesInfo)

        })
        .catch((error) => {
          console.log(error);
        });
    }, */

    /*  async [Actions.GET_FINISHED_ACTIVITIES]({ commit, state }, { guiderId }) {
       if (!guiderId) return
       let Activities = [];
       const companyId = getCompanyId(state[States.USER].uid)
       const docToUpdate = companyId || guiderId
       const ref = db.firestore()
         .collection("guides")
         .doc(docToUpdate)
       const doc = await ref.get();
       if (!doc.exists) {
         commit(Mutations.SET_FINISHED_ACTIVITIES, Activities)
         return
       }
       const data = doc.data()
       console.log({ data });
       if (data.employees.length) {
         const employeeIndex = data.employees.findIndex((employee) => employee.email === state[States.USER].email)
         if (employeeIndex >= 0) {
           Activities = data.employees[employeeIndex].finishedActivities
         }
       } else {
         Activities = data.finishedActivities
       }
       commit(Mutations.SET_FINISHED_ACTIVITIES, Activities)
     } */
    [Actions.SET_FINISHED_ACTIVITIES]({ commit }, { guiderId, updatedActivitiesInfo }) {
      db.firestore()
        .collection("finishedActivities")
        .doc(guiderId)
        .update({ tours: updatedActivitiesInfo })
        .then(() => {
          commit(Mutations.SET_FINISHED_ACTIVITIES, updatedActivitiesInfo)
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [Actions.GET_GUIDERS]({ commit }) {
      let Guiders = []
      db.firestore()
        .collection("guides")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            Guiders.push({
              ...doc.data(),
              id: doc.id,
            });
          });
          commit(Mutations.SET_GUIDERS, Guiders)
        })
    },
    // TODO?????
    [Actions.PROCESS_GUIDER_PAYMENT]() {

    },
    async [Actions.GET_FINISHED_ACTIVITIES]({ commit }, guiderId) {
      let Activities = [];
      if (!guiderId) {
        db.firestore()
          .collection("finishedActivities")
          .get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              if (doc.data().tours.length) {
                doc.data().tours.map((tour) => {
                  Activities.push({
                    ...tour,

                    companyId: doc.id,
                    companyName: doc.data().name
                  });
                })

                return
              }
            });
            commit(Mutations.SET_FINISHED_ACTIVITIES, Activities.flat())
          })
        return
      }

      const ref = db.firestore()
        .collection("finishedActivities")
        .doc(guiderId)
      const doc = await ref.get();

      if (!doc.exists) {
        commit(Mutations.SET_FINISHED_ACTIVITIES, Activities)
        return
      }

      Activities = doc.data().tours

      commit(Mutations.SET_FINISHED_ACTIVITIES, Activities)
    },
    [Actions.GET_EMPLOYEE_FINISHED_ACTIVITIES]({ commit, state }, employeeId) {
      if (!employeeId) return
      const employeeFinishedActivities = state[States.FINISHED_ACTIVITIES].filter((activity) => activity.employeeId === employeeId)
      commit(Mutations.SET_EMPLOYEE_FINISHED_ACTIVITIES, employeeFinishedActivities)
    },
    async [Actions.GET_WAITLISTED_ACTIVITIES]({ commit, }, companyId) {
      if (!companyId) return
      const waitListedActivities = []
      const ref = db.firestore().collection("groups")
      const doc = await ref.get();

      if (!doc.exists) {
        commit(Mutations.SET_GUIDER_WAITLISTED_ACTIVITIES, waitListedActivities)
        return
      }

      doc.data().forEach((activityGroup) => activityGroup?.waitListedGuiders.map(guider => {
        if (guider.guiderId === companyId)
          waitListedActivities.push(activityGroup)
      }))
      commit(Mutations.SET_GUIDER_WAITLISTED_ACTIVITIES, waitListedActivities)
    }
  },
}