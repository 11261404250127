<template>
    <section>
        <div v-if="pageIndex === 0">
            <GoBack route-name-humanized="Company Information" />
            <div class=" is-flex is-justify-content-center block-section mt-6 mb-6">
                <img class="image is-rounded" :src="form?.photoURL || defaultPhotoUrl" />

                <b-upload v-model="profilePhoto" class=" wrapper" accept="image/*">
                    <span class="badge">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M13.6997 19.8981H20.0767" stroke="#0e3d4d" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M12.855 4.95573C13.592 4.01673 14.783 4.06573 15.723 4.80273L17.113 5.89273C18.053 6.62973 18.386 7.77273 17.649 8.71373L9.35996 19.2887C9.08296 19.6427 8.65996 19.8517 8.20996 19.8567L5.01296 19.8977L4.28896 16.7827C4.18696 16.3457 4.28896 15.8857 4.56596 15.5307L12.855 4.95573Z"
                                stroke="#0e3d4d" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M11.3027 6.93616L16.0967 10.6942" stroke="#0e3d4d" stroke-width="1.5"
                                stroke-linecap="round" stroke-linejoin="round" />
                        </svg></span>
                </b-upload>
            </div>

            <div class="block">
                <p class="is-blue section-title mb-3 mt-5">Business legal name</p>
                <b-field message="The business name has to match the name associated with your tax ID number" horizontal>
                    <b-input class="mb-3" v-model="form.name"> </b-input>
                </b-field>

            </div>

            <div class=" block">
                <p class="is-blue section-title mb-3 mt-5">Company’s tax ID (NIF)</p>
                <b-input v-model="form.tax_id" placeholder="123456789"> </b-input>
            </div>

            <div class=" block">
                <p class="is-blue section-title mb-3 mt-5">Phone number</p>
                <b-input v-model="form.phoneNumber"> </b-input>
            </div>

            <div class=" block">
                <p class="is-blue section-title mb-3 mt-5">Company’s owner name</p>
                <b-input v-model="form.companyOwner"> </b-input>
            </div>

            <div class=" block">
                <p class="is-blue section-title mb-3 mt-5">Your business address</p>
                <b-input class="mb-3" placeholder="City" v-model="form.city"> </b-input>
                <b-input class="mb-3" v-model="form.line1"
                    placeholder="Address line 1 (e.g., street, PO Box, or company name)">
                </b-input>
                <b-input class="mb-3" v-model="form.line2"
                    placeholder="Address line 2 (e.g., apartment, suite, unit, or building)">
                </b-input>

                <b-field message="You can use your home address if you don't have a physical address for your business."
                    horizontal>
                    <b-input class="mb-3" v-model="form.postal_code" placeholder="Postal Code"> </b-input>
                </b-field>
            </div>

            <b-button v-if="user?.hasFinishedSetUp" @click="saveChanges" class="mt-6" type="is-primary" expanded :disabled="!hasChanges">
                Save
            </b-button>

            <b-button v-else @click="goToNextPage" class="mt-3" type="is-primary" expanded :disabled="!canGoToNextPage"
                :loading="isCreatingStripeAccountLink">
                Next
            </b-button>
        </div>

        <CreateStripeAccountMessage v-else @go-back="goBack" @create-stripe-account="createStripeAccount" :is-loading="isCreatingStripeAccountLink"/>
    </section>
</template>

<script>
import { SeatNumbers } from '../enums/SeatNumbers';
import { GuidersMixin } from '../mixins';
import { isEqual } from 'lodash'
import { db } from "../firebase";
import { mapState } from "vuex";
import { UsersStates } from '../store/Storetypes'
import GoBack from '../components/elements/GoBack.vue';
import CreateStripeAccountMessage from '@/Company/elements/CreateStripeAccountMessage'

export default {
    components: {
        GoBack,
        CreateStripeAccountMessage
    },
    mixins: [GuidersMixin],
    data() {
        return {
            SeatNumbers,
            profilePhoto: null,
            form: null,
            isLoading: true,
            isCreatingStripeAccountLink: false,
            pageIndex: 0,
        }
    },
    computed: {
        ...mapState({
            user: state => state.users[UsersStates.USER],
            isGuider: state => state.users[UsersStates.IS_USER_A_GUIDER],
        }),
        defaultPhotoUrl() {
           return require("../assets/user.svg")
        },
        canGoToNextPage() {
            if (!this.form?.name ||
                !this.form?.phoneNumber ||
                !this.form?.city ||
                !this.form?.line1 ||
                !this.form?.line2 ||
                !this.form?.postal_code ||
                !this.form?.tax_id
            ) return false

            return this.form?.name.length > 0 &&
                this.form?.phoneNumber.length > 0 &&
                this.form?.city.length > 0 &&
                this.form?.line1.length > 0 &&
                this.form?.line2.length > 0 &&
                this.form?.postal_code.length > 0 &&
                this.form?.tax_id.length > 0
        },
        hasChanges() {
            if (!this.form) return false
            return this.hasChanged(this.user?.displayName, this.form?.name) ||
                this.hasChanged(this.user?.email, this.form?.email) ||
                this.hasChanged(this.user?.phoneNumber, this.form?.phoneNumber) ||
                this.hasChanged(this.user?.photoURL, this.form?.photoURL) ||
                this.hasChanged(this.user?.companyOwner, this.form?.companyOwner) ||
                this.hasChanged(this.user?.city, this.form?.city) ||
                this.hasChanged(this.user?.line1, this.form?.line1) ||
                this.hasChanged(this.user?.line2, this.form?.line2) ||
                this.hasChanged(this.user?.postal_code, this.form?.postal_code) ||
                this.hasChanged(this.user?.tax_id, this.form?.tax_id)
        },
    },
    watch: {
        profilePhoto() {
            // TODO: ONLY UPLOAD WHEN THE FORM IS SENT
            const storageRef = db
                .storage()
                .ref(`${this.user.uid}/${this.profilePhoto?.name}`)
                .put(this.profilePhoto);
            storageRef.on(
                `state_changed`,
                () => { },
                (error) => {
                    console.log(error.message);
                },
                () => {
                    storageRef.snapshot.ref.getDownloadURL().then((url) => {
                        this.form.photoURL = url;
                    });
                }
            );
        },
        user() {
            if (!this.user?.uid) {
                this.form = {}
                return
            }
            /* this.form = {
              email: this.user?.email || '',
              displayName: this.user?.displayName || '',
              slackID: this.user?.slackID || '',
              photoURL: this.user?.photoURL || '',
              numberOfSeats: this.user?.numberOfSeats || null
            } */
            this.form = {
                uid:  this.user?.uid || '',
                email: this.user?.email || '',
                name: this.user?.displayName || this.user?.name || '',
                slackID: this.user?.slackID || '',
                photoURL: this.user?.photoURL || '',
                companyOwner: this.user?.companyOwner || '',
                numberOfSeats: this.user?.numberOfSeats || null,
                city: this.user?.city || null,
                line1: this.user?.line1 || null,
                line2: this.user?.line2 || null,
                postal_code: this.user?.postal_code || null,
                vat_id: this.user?.tax_id || null,
                tax_id: this.user?.tax_id || null,
                phoneNumber: this.user?.phoneNumber || null
            }
        },
        form: {
            handler: function () {
                this.isLoading = false
            },
            deep: true
        }
    },
    methods: {
        hasChanged(firstValue, secondValue) {
            return !isEqual(firstValue, secondValue)
        },
        async createStripeAccount() {
            this.isCreatingStripeAccountLink = true
            const { email, uid, /* city, line1, line2, postal_code, */ name, tax_id, phoneNumber } = this.form
            const data = { email, uid, /* city, line1, line2, postal_code, */ name, vat_id:tax_id, tax_id, accountId: this.user?.stripeAccountId, phoneNumber }

            await fetch(`${process.env.VUE_APP_API_URL}/stripe/account`, {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "same-origin",
            })
                .then((response) => response.json()).then((data) => {
                    this.isCreatingStripeAccountLink = false
                    if (data.success) window.location.href = data.url;
                })
                .catch((error) => {
                    this.isCreatingStripeAccountLink = false
                    this.$buefy.toast.open({
                        duration: 10000,
                        message: error.message,
                        position: "is-bottom",
                        type: "is-danger",
                    });
                    return true;
                });
        },
        saveChanges() {
            if (!this.hasChanges) return
            this.updateGuiderData({
                userData: {
                    ...this.user,
                    ...this.form
                }
            })
            if (this.hasChanged(this.user.email, this.form.email)) this.updateEmail()
            if (
                this.hasChanged(this.user?.displayName, this.form?.name) ||
                this.hasChanged(this.user.photoURL, this.form.photoURL)
            ) this.updateUserData()


        },
        goToNextPage() {
            this.saveChanges()
            this.pageIndex = 1
            //   if (!this.user?.hasFinishedSetUp) this.createStripeAccount()
        },
        goBack() {
            this.pageIndex = 0
        },
        updateEmail() {
            // TODO: UNCOMMENT
            /*  db.auth()
                 .currentUser.updateEmail(this.form.email)
                 .then(() => {
                     db.auth()
                         .currentUser.sendEmailVerification()
                     if (this.isGuider) {
                         this.updateGuiderData({
                             userData: {
                                 email: this.form.email
                             }
                         })
                         return
                     }
 
                 }).catch((err) => {
                     this.isLoading = false;
                     this.$buefy.toast.open({
                         duration: 10000,
                         message: err.message,
                         position: "is-bottom",
                         type: "is-danger",
                     });
                 }); */
        },
        updateUserData() {
            db.auth()
                .currentUser.updateProfile({
                    displayName: this.form?.name,
                    photoURL: this.form?.photoURL,
                })
                .catch((err) => {
                    this.isLoading = false;
                    this.$buefy.toast.open({
                        duration: 10000,
                        message: err.message,
                        position: "is-bottom",
                        type: "is-danger",
                    });
                });
        }
    },
}
</script>

<style lang="scss" scoped>
.image {
    height: 128px;
    width: 128px;
    object-fit: contain;
    border-radius: 300px;
}

.badge {
    display: flex;
    min-width: 0.5rem;
    padding: 0.3em;
    border-radius: 50%;
    font-size: 24px;
    text-align: center;
    background: #fff;
    color: #fefefe;
}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    right: 36px;
}
</style>