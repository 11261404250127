<template>
    <section>
        <AffiliatesFormConfirmation v-if="isFormSent" />
        <div v-else>
            <b-field>
                <b-input expanded placeholder="Your name" v-model="name"> </b-input>
            </b-field>
                <b-field>
                    <b-input expanded placeholder="Your email" v-model="email" type="email"> </b-input>
                </b-field>

                <b-button @click.prevent="addAffiliate()" :disabled="!email || !name" :loading="isLoading" type="is-primary" rounded expanded>
                    Join
                </b-button>

            <p class="is-size-8 mt-2 ">By joining our affiliate program you accept our
                <br>
                <a class="link"
                    href="https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/documents%2FContractPartners.pdf?alt=media&token=285f172b-95d5-4ee5-b04b-e8264c32cf5a"
                    target="_blank">Terms of Service</a> and
                <a class="link"
                    href="https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/documents%2FPrivacy%20policy.pdf?alt=media&token=e6925517-73ae-4405-bc01-feaeb8e1526d"
                    target="_blank">Privacy Policy</a>.
            </p>
        </div>

    </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { AffiliatesActions, AffiliatesStates } from "../store/Storetypes";
import { ToastProgrammatic as toast } from "buefy";
import AffiliatesFormConfirmation from "../Company/elements/AffiliatesFormConfirmation.vue";
export default {
    components: {
        AffiliatesFormConfirmation
    },
    data() {
        return {
            name: null,
            email: null,
            isLoading: false,
            isFormSent: false
        }
    },
    created() {
        this.getAffiliates()
    },
    computed: {
        ...mapState({
            affiliates: state => state.affiliates[AffiliatesStates.AFFILIATES]
        }),
    },
    methods: {
        ...mapActions({
            getAffiliates: AffiliatesActions.GET_AFFILIATES
        }),
        async addAffiliate() {
            this.isLoading = true
            const affiliateHasBeenAdded = await this.checkIfAffiliateExists()
            if (affiliateHasBeenAdded) {
                this.isLoading = false
                toast.open({
                    duration: 10000,
                    message: "This email is already in the affiliates program",
                    position: "is-bottom",
                    type: "is-danger",
                });
                return
            }
            this.addAffiliateToDb()
        },
        async checkIfAffiliateExists() {
            await this.getAffiliates()
            if (!this.affiliates) return false
            return this.affiliates.some((employee) => employee.email === this.email)
        },
        async addAffiliateToDb() {
            await fetch(`${process.env.VUE_APP_API_URL}/stripe/affiliates/add`, {
                method: "POST",
                body: JSON.stringify({ email: this.email, name: this.name }),
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "same-origin",
            })
                .then((response) => response.json()).then((data) => {
                    if (data.success) {
                        this.isFormSent = true
                        return
                    }
                    this.$buefy.toast.open({
                        duration: 10000,
                        message: data.message,
                        position: "is-bottom",
                        type: "is-danger",
                    });
                })
                .catch(() => {
                    toast.open({
                        duration: 10000,
                        message: "There has been an error adding this affiliate",
                        position: "is-bottom",
                        type: "is-danger",
                    });
                });
            this.isLoading = false
        }
    }
}
</script>

<style lang="scss" scoped>
.link {
    color: #fff
}

.modal-body {
    border-radius: 8px;
    padding: 20px;
    margin: 20px;
    background-color: white;
    width: -webkit-fill-available;
    min-height: auto;
    border: 1px solid #fff;
}

::v-deep {
    .button.is-primary {
        height: 2.5rem;
        color: #446DF6;
        background-color: #fff;
        font-weight: 600;
    }

    .input {
        color: #fff;
        border-color: #fff !important;
    }

    .input::placeholder {
        color: #fff;
    }

    .control.has-icons-right .icon.is-right {
        color: #fff !important;
    }
}
</style>