<template>
    <section>
        <b-table class="mt-4" :data="guidersComputed" ref="table" paginated searchable per-page="5"
            aria-next-label="Next page" aria-previous-label="Previous page" aria-page-label="Page"
            aria-current-label="Current page" pagination-rounded>

            <b-table-column field="name" label="Name" sortable v-slot="props">
                {{ props.row.displayName }}
            </b-table-column>

            <b-table-column label="Email" sortable v-slot="props">
                {{ props.row.email }}
            </b-table-column>

            <b-table-column field="hasFinishedSetUp" label="Setup Status" sortable v-slot="props">
                <b-tag :type="props.row.hasFinishedSetUp ? 'is-success is-light' : 'is-danger is-light'"> {{
                    props.row.hasFinishedSetUp ? 'Completed' : 'Pending' }}</b-tag>
            </b-table-column>

            <b-table-column label="" v-slot="props">
                <div @click="onClick(props.row)">
                    <b-icon pack="fas" icon="chevron-right" size="is-small"  />
                </div>
            </b-table-column>

            <template #empty>
                <div class="has-text-centered">No guiders have been registered</div>
            </template>
        </b-table>
    </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { GuiderActions, GuidersStates, GuidersMutations } from "../store/Storetypes";
export default {
    computed: {
        ...mapState({
            guiders: state => state.guiders[GuidersStates.GUIDERS]
        }),
        guidersComputed() {
            return this.guiders.filter((guider) => !guider.companyId && !guider.isAdmin)
        }
    },
    created() {
        this.getGuiders()
    },
    methods: {
        ...mapActions({
            getGuiders: GuiderActions.GET_GUIDERS
        }),
        ...mapMutations({
            setSelectedGuider: GuidersMutations.SET_SELECTED_GUIDER,
        }),
        onClick(guiderData) {
            this.setSelectedGuider(guiderData)
            this.$router.push({ name: 'guider-details', params: { guiderUid: guiderData.uid } })
        }
    }
}
</script>

<style scoped lang="scss">

.b-table .table-wrapper.has-mobile-cards tr:not(.detail):not(.is-empty):not(.table-footer) td:before {
    content: attr(data-label);
    color: #A29EBB;
}
</style>