<template>
    <div class="btn-container mb-3" @click="onClick">
        <div class="columns is-mobile pt-2" :class="{'danger': isDanger}">
            <div class="column is-1 mr-2 icon">
                <slot name="icon" />
            </div>
            <div class="column">
                <span class="title is-blue">{{ title }} </span>
                <span v-if="!!description" class="description mt-1 mb-3">{{ description }} </span>
                <slot name="custom-slot"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: ""
        },
        description: {
            type: String,
            default: ""
        },
        isDanger: {
            type: Boolean,
            default: false
        },
    },
    methods: {
        onClick() {
            this.$emit('onClick')
        }
    }
}
</script>

<style lang="scss" scoped>
.danger .title,
.danger .icon {
    color: #FF4E78
}

.title,
.description {
    font-family: 'Inter', sans-serif;
}

.title {
    font-weight: 600;
    font-size: .9rem;
}

.description {
    font-size: 13px;
    display: block;
    line-height: 16px;
}

.btn-container {
    height: 100%;
    padding: 0.4rem .75rem;
    background-color: #FFFFFF;
    color: var(--primary);
    display: block;
    border-radius: 10px;
    border: 1px solid #EDEBF2;
    box-shadow: 5px 5px 16px rgba(27, 3, 91, 0.04);
}

.columns.is-mobile {
    align-items: flex-start;
}
</style>