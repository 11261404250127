<template>
    <div class="notification-container">
        <div class="columns is-mobile">
            <div v-if="icon" class="column is-1">
                {{ icon }}
            </div>
            <div class="column">
                <p class="notification--title"> {{ title }}</p>
                <p class="subtitle"> {{ text }}</p>
                <slot name="custom-element"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "InlineNotification",
    props: {
        type: {
            type: String,
            default: ""
        },
        title: {
            type: String,
            default: ""
        },
        text: {
            type: String,
            default: ""
        },
        icon: {
            type: String,
            default: ''
        }
    },
    computed: {
        containerClass() {
            return `${this.type}--notification`
        }
    }
}
</script>

<style lang="scss" scoped>
.notification-container {
    margin: .5rem 0;
    border-radius: 10px;
    padding: 1rem;
    height: fit-content;
    display: inline-block;
    width: 100%;
    background-color: #FFEFCE;
    border: 2px solid #FFC801;
    box-shadow: 5px 5px 16px rgba(27, 3, 91, 0.04);

    .notification--title {
        font-size: 14px;
        font-weight: 700;
    }

    .subtitle {
        font-size: 13px;
        font-weight: 300;
        font-family: 'Inter', sans-serif;
        margin-top: .5rem;
        margin-bottom:.5rem
    }
}

.column:first-child {
    padding: 0.75rem 0 0 0.75rem;
}


.column:has(.warning--notification){
    display: flex;
    justify-content: center;
}

.columns.is-mobile {
    align-items: flex-start;
}

::v-deep {
    .field.has-addons {
        gap: 0.5rem;
    }

    .field.has-addons .control:first-child:not(:only-child) .button {
        border-radius: 10px;
    }
}
</style>