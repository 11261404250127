<template>
  <section class=" mb-4 is-blue">
    <div class="display-flex-space-btw">
      <div class="display-flex">
        <div @click="goBack">
          <b-icon pack="fas" icon="arrow-left" size="is-medium" />
        </div>
        <span class="page-title">{{ routeNameHumanized }}</span>
      </div>

      <div v-if="hasShareOption">
        <b-button @click="toggleShareContainer">Share</b-button>
        <div v-if="showShareContainer">
          <SocialSharing :url="url" :title="title" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SocialSharing from "../../Blog/SocialSharing.vue";
export default {
  components: {
    SocialSharing,
  },
  props: {
    routeName: {
      type: String,
      default: null,
    },
    routeNameHumanized: {
      type: String,
      default: "",
    },
    hasShareOption: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: "",
    },
  },
  data(){
    return{
        showShareContainer: false
    }
  },
  methods: {
    goBack() {
      this.$emit("on-leave");
      if (this.routeName) {
        this.$router.push({ name: this.routeName });
        return;
      }
      if (window.history.length) {
        this.$router.go(-1);
        return;
      }
      this.$router.push({ name: "home" });
    },
    toggleShareContainer(){
        this.showShareContainer= !this.showShareContainer
    }
  },
};
</script>

<style lang="scss" scoped>
.page-title,
.icon {
  color: var(--primary);
  font-family: "Inter", sans-serif;
}

.icon {
  height: 18px;
  width: auto;
}

.page-title {
  width: 100%;
  font-size: 1rem;
  text-align: center;
  font-weight: 600;
}
</style>
