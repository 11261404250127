<template>
    <router-link class="btn-container mb-3" @click="emitAction" :to="{ name: routerLink }">
        <div class="display-flex-space-btw">
            <span class="title" :class="{'is-danger': isDanger}">{{ title }} </span>
            <b-icon v-if="withArrow" icon="chevron-right"></b-icon>
        </div>

        <span v-if="!!description" class="description mt-1">{{ description }} </span>
    </router-link>
</template>

<script>
export default {
    props: {
        routerLink: {
            type: String,
            default: ""
        },
        title: {
            type: String,
            default: ""
        },
        action: {
            type: String,
            default: ""
        },
        description: {
            type: String,
            default: ""
        },
        isDanger: {
            type: Boolean,
            default: false
        },
        withArrow: {
            type: Boolean,
            default: true
        },
    },
    methods: {
        emitAction(){
            this.$emit(this.action)
        }
    }
}
</script>

<style lang="scss" scoped>
.title {
    font-weight: 600;
    font-size: .9rem;
    margin-bottom: 0;
}

.title,
.description {
    font-family: 'Inter', sans-serif;
}

.description {
    font-size: 13px;
    color: #0e3d4d;
    display: block;
    max-width: 90%;
}

.btn-container {
    height: 100%;
    padding: .75rem;
    background-color: #FFFFFF;
    color: var(--primary);
    display: block;
    border-radius: 10px;
    border: 1px solid #EDEBF2;
    box-shadow: 5px 5px 16px rgba(27, 3, 91, 0.04);
}

.is-danger {
    color: #FF4868;
}
</style>