<template>
    <section>
            <section class="modal-card-body">
                <div class="icon--container mt-4">
                    <b-icon type="is-danger" size="is-large" :icon="icon">
                    </b-icon>
                </div>

                <p class="title mt-5 mb-2">{{ title }}</p>
                <p class="subtitle mt-2 mb-5">{{ subtitle }}</p>
                <slot name="custom"/>
                <div class="is-flex">
                    <b-button @click="$emit('onActionConfirmed')" type="is-danger" class="mb-2">{{ confirmButtonText }}</b-button>
                <b-button @click="$emit('toggleDeleteWarning', false)" type="is-ghost">Cancel</b-button>

                </div>
                
            </section>
    </section>
</template>

<script>
export default {
    props: {
        icon: {
            type: String,
            default: undefined
        },
        title: {
            type: String,
            default: 'Are you sure you want to proceed'
        },
        subtitle: {
            type: String,
            default: "This action can't be undone"
        },
        confirmButtonText: {
            type: String,
            default: 'Confirm'
        },
    },
}
</script>

<style lang="scss" scoped>
.icon--container {
    padding: 10px;
    border-radius: 100px;
    background-color: #FFE5EA;
    width: fit-content;
}

.modal-card-body {
    margin: 28px;
    border-radius: 10px;
    text-align: -webkit-center;
}

.title {
    font-size: 1rem;
}

.subtitle {
    font-size: .9rem;
}

.is-flex{
    flex-direction: column;
    align-items: center;
}


</style>