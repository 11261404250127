<template>
    <section>
        <b-loading v-if="!eventInfo"></b-loading>
        <div v-else class="container">
            <div>
                <GoBack class="mb-6" :route-name-humanized="titles[progress]" />

                <b-progress :value="(100 / titles.length) * (progress + 1)" type="is-primary" size="is-small"></b-progress>

                <keep-alive>
                    <EventData :event-info="eventData" @dataChanged="dataChanged" v-if="progress == 0" />

                    <review :event-info="eventInfo" v-if="progress === 4"></review>
                </keep-alive>

                <div class="buttons">
                    <b-button v-if="progress !== 0" @click="progress -= 1" type="is-text">Back</b-button>
                    <div v-if="progress == 0"></div>

                    <!-- <b-button v-if="progress !== 1" @click="validateForm"
                        :class="[valid ? 'valid' : 'invalid']">Next</b-button> -->
                    <b-tooltip :active="!formHasChanged" label="No changes have been made">
                        <b-button :loading="buttonLoading" @click="onFormSubmit" :disabled="!formHasChanged" type="is-primary">{{ buttonText
                        }}</b-button>
                    </b-tooltip>

                </div>
            </div>
        </div>
    </section>
</template>
  
<script>
import { db } from "../firebase";
import { mapMutations, mapState, mapActions } from "vuex";
import GoBack from "./elements/GoBack.vue";
import EventData from '@/EventsForm/EventData'
import Review from "../CreationFormComponents/Review.vue";
import {
    EventsMutations, EventsStates, EventsActions,
    ActivitiesStates
} from "../store/Storetypes";
import { isEqual } from 'lodash'

const initialEventInfo = {
    price: null,
    isVisible: true,
    isEvent: true,
    priceLink: null,
    eventDate: null,
    eventTime: null,
    eventActivity: null,
    whatsappGroupLink: null,
}

export default {
    components: {
        EventData,
        Review,
        GoBack
    },
    data() {
        return {
            buttonText: "Confirm",
            progress: 0,
            tourCreated: false,
            valid: false,
            isLoading: true,
            eventInfo: initialEventInfo,
            eventToEditData: {},
            eventIndex: -1,
            buttonLoading: false
        };
    },
    computed: {
        ...mapState({
            events: state => state.events[EventsStates.EVENTS],
            activities: state => state.activities[ActivitiesStates.ACTIVITIES]
        }),
        isEdit() {
            return this.$route.name === "edit-event"
        },
        formHasChanged() {
            const hasChanged = this.isEdit ? !isEqual(this.eventToEditData, this.eventInfo) : !isEqual(initialEventInfo, this.eventInfo)
            return hasChanged
        },
        titles() {
            return [
                // this.isEdit ? "Edit event" : "Create event",
                "Event Information",
                "Review",
            ]
        },
        eventData() {
            if (this.$route.name === "edit-event") {
                const eventData = this.setEditEventData()
                return eventData
            }
            this.finishPreparingEventData(true)
            return this.eventInfo
        }
    },
    beforeMount() {
        window.addEventListener("beforeunload", this.preventNav);
        this.$once("hook:beforeDestroy", () => {
            window.removeEventListener("beforeunload", this.preventNav);
        });
    },
    created(){
        this.getEvents()
    },
    methods: {
        ...mapActions({
            getEvents: EventsActions.GET_EVENTS
        }),
        ...mapMutations({
            getEventData: EventsMutations.GET_EVENT_DATA,
            updateEventData: EventsMutations.UPDATE_EVENT_DATA
        }),
        setEditEventData() {
            this.eventIndex = this.getSelectedEventIndex()
            if (this.eventIndex < 0) {
                this.finishPreparingEventData(true)
                return
            }
            const eventData = this.events[this.eventIndex]
            this.eventToEditData = eventData;
            this.eventInfo = eventData;
            return eventData
        },
        getSelectedEventIndex() {
            if (!this.events.length) this.getEvents()
            const activityId = this.$route.params.id
            console.log(this.events, activityId);
            return this.events.findIndex((event) => event.id === activityId)
        },
        finishPreparingEventData(activityFound) {
            this.isLoading = false
            this.notFound = activityFound;
        },
        retrieveEventData() {
            db.firestore()
                .collection("events")
                .doc(this.$route.params.id)
                .get()
                .then((doc) => {
                    if (doc.data()) {
                        this.eventToEditData = doc.data();
                        this.eventInfo = doc.data();
                        this.isLoading = false;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.notFound = true;
                });
        },
        preventNav(event) {
            event.preventDefault();
            event.returnValue = "";
        },
        deleteDropFile(index) {
            this.dropFiles.splice(index, 1);
        },
        dataChanged(value) {
            if (typeof value === "string") {
                this.valid = false;
            } else {
                this.eventInfo = { ...this.eventInfo, ...value };
                this.valid = true;
            }
        },
        validateForm() {
            if (this.valid) {
                this.progress += 1;
                this.valid = false;
            }
        },
        onFormSubmit() {
            if (!this.formHasChanged) return
            this.buttonLoading= true
            if (this.isEdit) {
                // edit event
                db.firestore()
                    .collection("events")
                    .doc(this.$route.params.id)
                    .update(this.eventInfo)
                    .then(() => {
                        this.editProductInStripe();
                        this.updateEventData({ eventIndex: this.eventIndex, eventData: this.eventInfo })
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                    this.buttonLoading= false
                return
            }

            //create event
            db.firestore()
                .collection("events")
                .add(this.eventInfo)
                .then((data) => {
                    this.createProductInStripe(data.id);
                    window.removeEventListener("beforeunload", this.preventNav);
                    this.tourCreated = true;
                })
                .catch((error) => {
                    console.log(error);
                });
                this.buttonLoading= false
        },
        getEventActivityData(activityId) {
            this.activityIndex = this.activities.findIndex((activity) => activity.id === activityId)
            if (this.activityIndex < 0) return
            return this.activities[this.activityIndex]
        },
        async editProductInStripe() {
            window.removeEventListener("beforeunload", this.preventNav);
            if (!this.eventInfo?.eventActivity) return
            const eventData = this.getEventActivityData(this.eventInfo.eventActivity)
            console.log({ eventData });

            let data = {
                productId: this.$route.params.id,
                description: eventData.description,
                name: eventData.title,
                image: [eventData.coverImage], // get event image or tour cover image
                priceId: this.eventInfo.priceLink,
                price: this.eventInfo.price
            };

            await fetch(`${process.env.VUE_APP_API_URL}/stripe/product`, {
                method: "PUT",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "same-origin",
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        db.firestore()
                            .collection("events")
                            .doc(this.$route.params.id)
                            .update({ priceLink: data.message.id })
                            .then(() => {
                                this.$buefy.toast.open({
                                    duration: 10000,
                                    message: "Event updated!",
                                    position: "is-bottom",
                                });
                                this.$router.push({ name: "manage-activities" });
                            })
                            .catch(() => {
                                this.$buefy.toast.open({
                                    duration: 10000,
                                    message:
                                        "There has been an error updating the event. Try again later.",
                                    position: "is-bottom",
                                    type: "is-danger",
                                });
                            });
                    }
                })
                .catch((error) => {
                    this.$buefy.toast.open({
                        duration: 10000,
                        message: error.message,
                        position: "is-bottom",
                        type: "is-danger",
                    });
                });
        },
        async createProductInStripe(productId) {
            window.removeEventListener("beforeunload", this.preventNav);
            const eventData = this.getEventActivityData(this.eventInfo.eventActivity)
            console.log({ description: eventData.description, });

            let data = {
                id: productId,
                name: this.eventInfo.title,
                description: eventData.description,
                price: this.eventInfo.price,
                image: [eventData.coverImage],
            };

            await fetch(`${process.env.VUE_APP_API_URL}/stripe/create`, {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "same-origin",
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        db.firestore()
                            .collection("events")
                            .doc(productId)
                            .update({ priceLink: data.message.id })
                            .then(() => {
                                this.$buefy.toast.open({
                                    duration: 10000,
                                    message: "Event created!",
                                    position: "is-bottom",
                                });
                                this.$router.push({ name: "manage-activities" });
                            })
                            .catch(() => {
                                this.$buefy.toast.open({
                                    duration: 10000,
                                    message:
                                        "There has been an error creating the event. Try again later.",
                                    position: "is-bottom",
                                    type: "is-danger",
                                });
                            });
                    }
                })
                .catch((error) => {
                    this.$buefy.toast.open({
                        duration: 10000,
                        message: error.message,
                        position: "is-bottom",
                        type: "is-danger",
                    });
                    return true;
                });
        },
    },
};
</script>
  
<style lang="scss" scoped>
::v-deep {
    .buttons .button:last-of-type {
        margin-right: 0;
    }

    .valid {
        background-color: #0e3d4d;
        color: #fff;
    }

    .submit-button {
        float: right;
        margin-bottom: 60px;
    }

    .buttons {
        margin-top: 24px;
        justify-content: flex-end;
    }

    .progress.is-small,
    .is-small.progress-wrapper.is-not-native {
        height: 0.35rem;
        margin-bottom: 24px;
    }

    .progress,
    .progress-wrapper.is-not-native {
        border-radius: 5px;
    }

    .button.is-text:hover,
    .button.is-text.is-hovered,
    .button.is-text:focus,
    .button.is-text.is-focused {
        background-color: none;
    }

    .invalid {
        background-color: #ededed;
        color: #0e3d4d;
        cursor: not-allowed;
    }

    .label {
        font-weight: 500;
        margin-bottom: 0.75rem !important;
    }
}

.info-block {
    margin-top: 25vh
}

.title {
    margin-top: 15px;
}

.verification-message {
    margin-top: 28vh;
    text-align-last: center;
}

.verification-message a {
    margin-top: 12px;
    text-decoration: underline;
    color: #0e3d4d80;
}

.upload {
    width: 100%;
}

.field:not(:last-child) {
    margin-bottom: 1.75rem;
}

@media only screen and (max-width: 768px) {
    div.notification {
        background-color: transparent;
        padding: 0px;
        border: none;
    }
}
</style>
  