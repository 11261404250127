<template>
  <section>
    <b-field :label="isOptionalField('Cover Photo')">
      <b-upload
        v-model="coverPhoto"
        drag-drop
        required
        expanded
        accept="image/*"
      >
        <section class="section">
          <div class="content has-text-centered">
            <p>
              <b-icon icon="upload" size="is-large"> </b-icon>
            </p>
            <p v-if="!isEditing">Upload an image for the cover.</p>
            <p v-else>Change cover image</p>
          </div>
        </section>
      </b-upload>
    </b-field>
    <div class="tags" v-if="coverPhoto !== null && !isEditing">
      <span class="tag">
        {{ coverPhoto.name }}
        <button
          class="delete is-small"
          type="button"
          @click="coverPhoto = null"
        ></button>
      </span>
    </div>

    <img class="cover-image" :src="coverImage" v-if="isEditing" />

    <b-field :label="isOptionalField('Gallery Photos')">
      <b-upload
        v-model="galeryPhotos"
        multiple
        drag-drop
        required
        expanded
        accept="image/*"
        v-if="!isEditing"
      >
        <section class="section">
          <div class="content has-text-centered">
            <p>
              <b-icon icon="upload" size="is-large"> </b-icon>
            </p>
            <p>Upload images for the gallery.</p>
          </div>
        </section>
      </b-upload>
    </b-field>

    <div class="tags" v-if="!isEditing">
      <span v-for="(file, index) in galeryPhotos" :key="index" class="tag">
        {{ file.name }}
        <button
          class="delete is-small"
          type="button"
          @click="deleteDropFile(index)"
        ></button>
      </span>
    </div>

    <div v-if="isEditing" class="images columns  is-mobile is-multiline">
      <div v-for="image in galleryImages" :key="image" class="image column is-4">
        <b-button
          type="is-danger"
          icon-left="trash"
          @click.prevent="deleteImage(image)"
          class="delete-image"
          size="is-small"
        >
        </b-button>

        <img :src="image" />
      </div>
      <div class="column is-4">
        <b-upload v-model="galeryPhotos" drag-drop required accept="image/*">
          <section class="section">
            <div class="content has-text-centered">
              <p>
                <b-icon icon="upload"> </b-icon>
              </p>
            </div>
          </section>
        </b-upload>
      </div>
    </div>
  </section>
</template>

<script>
import {db} from "../firebase";
import { ActivityFormMixin } from './mixins/ActivityFormMixin'

export default {
  mixins: [ActivityFormMixin],
  props: ["activityInfo"],
  data() {
    return {
      coverPhoto: null,
      galeryPhotos: [],
      uploadValue: 0,
      coverImage: this.activityInfo.coverImage,
      galleryImages: this.activityInfo.galleryImages,
      isLoading: false,
      isEditing: false,
      rndString: Math.random().toString(36).substr(2, 14)
    };
  },

  computed: {
    changeData() {
      const { coverPhoto, galeryPhotos } = this;
      return {
        coverPhoto,
        galeryPhotos,
      };
    },
  },

  created() {
    if (this.$route.name === "edit-activity") {
      this.isEditing = true;
    }
  },

  watch: {
    changeData: {
      handler: function() {
        this.emitData();
      },
      deep: true,
    },
    galeryPhotos() {
      if (this.isEditing) {
        this.addPhotoToGalery();
      }
    },
    coverPhoto() {
      if (this.isEditing) {
        this.changeCoverPhoto();
      }
    },
  },

  activated() {
     if (this.$route.name === "edit-activity") {
          this.$emit("dataChanged", {
            coverImage: this.coverImage,
            galleryImages: this.galleryImages,
          });
        } else {
          this.emitData();
        }
  },

  methods: {
    deleteImage(id) {
      this.galleryImages = this.galleryImages.filter((image) => {
        return image != id;
      });
      this.$emit("dataChanged", {
        coverImage: this.coverImage,
        galleryImages: this.galleryImages,
      });
    },
    onUploadCoverPhoto() {
      this.isLoading = true;
      const storageRef = db.storage()
        .ref(`${this.rndString}/${this.coverPhoto.name}`)
        .put(this.coverPhoto);
      storageRef.on(
        `state_changed`,
        () => {
          this.$emit("dataChanged", "loading");
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.coverImage = url;
            this.onUploadGaleryPhotos();
          });
        }
      );
    },

    changeCoverPhoto() {
      const storageRef = db
        .storage()
        .ref(`${this.coverPhoto.name}`)
        .put(this.coverPhoto);
      storageRef.on(
        `state_changed`,
        () => {
          this.$emit("dataChanged", "loading");
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.coverImage = url;
            this.$emit("dataChanged", {
              coverImage: this.coverImage,
              galleryImages: this.galleryImages,
            });
          });
        }
      );
    },

    addPhotoToGalery() {
      //console.log(this.galeryPhotos.name);
      const storageRef = db
        .storage()
        .ref(`${this.galeryPhotos.name}`)
        .put(this.galeryPhotos);
      storageRef.on(
        `state_changed`,
        () => {
          this.$emit("dataChanged", "loading");
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.galleryImages.push(url);
            this.isLoading = false;
            this.$emit("dataChanged", {
              coverImage: this.coverImage,
              galleryImages: this.galleryImages,
            });
          });
        }
      );
    },

    onUploadGaleryPhotos() {
      this.galleryImages = [];
      this.galeryPhotos.map((image) => {
        const storageRef = db
          .storage()
          .ref(`${this.rndString}/${image.name}`)
          .put(image);
        storageRef.on(
          `state_changed`,
          () => {
            this.$emit("dataChanged", "loading");
          },
          (error) => {
            console.log(error.message);
          },
          () => {
            this.uploadValue = 100;
            storageRef.snapshot.ref.getDownloadURL().then((url) => {
              this.galleryImages.push(url);
              this.isLoading = false;
              this.$emit("dataChanged", {
                coverImage: this.coverImage,
                galleryImages: this.galleryImages,
              });
            });
          }
        );
      });
    },
    deleteDropFile(index) {
      this.galeryPhotos.splice(index, 1);
    },

    emitData() {
      if (typeof this.coverPhoto === "object" && this.galeryPhotos.length > 0) {
        this.onUploadCoverPhoto();
      } else {
        this.$emit("dataChanged", "invalid");
      }
    },
  },
};
</script>

<style scoped>
.icon.is-large {
  height: 3rem;
  width: 3rem;
  color: #ececec;
}

.delete-image {
  position: absolute;
  top: 18px;
  right: 18px;
}

.images img {
  height: 124px;
  width: auto;
  -o-object-fit: contain;
  object-fit: cover;
  border-radius: 6px;
}

.images,
.cover-image {
  margin: 32px 0px;
}

.cover-image {
  border-radius: 6px;
  max-height: 200px;
}

.image {
  position: relative;
}

.tags {
  margin: 32px 0px;
}
</style>
