<template>
    <section class="modal-card-body">
        <p class="title mt-5 mb-2">Registration code</p>
        
        <div class="mt-5 mb-5">
            <b-field>
                <b-input icon="envelope" v-model="email" type="email"> </b-input>
            </b-field>
        </div>

        <b-button class="mb-4" @click="createRandomCode" type="is-primary">Send registration code</b-button>
    </section>
</template>

<script>
import { db } from "../firebase";
import { generateRandomCode } from "../helpers";
export default {
    data() {
        return {
            code: undefined,
            email: '',
            generateRandomCode
        }
    },
    methods: {
        createRandomCode() {
            const code = generateRandomCode(7)
            db.firestore()
                .collection("codes")
                .doc(code)
                .set({ code: code })
                .then(() => {
                    this.sendRegistrationCodeToCompany(code)
                })
                .catch((error) => {
                    this.$buefy.toast.open({
                        duration: 10000,
                        message: error,
                        position: "is-bottom",
                        type: "is-danger",
                    });
                });
        },
        sendRegistrationCodeToCompany(code){
            const data= {
                email: this.email,
                registrationCode: code
            }
            fetch(`${process.env.VUE_APP_API_URL}/send/mail/guider-registration-password`, {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "same-origin",
            }).then((response) => {
                if (response.status !== 200) return false
                this.$buefy.toast.open({
                    duration: 10000,
                    message: "An email with a registration code has been sent to the company",
                    position: "is-bottom",
                    type: "is-success",
                });
                if (window.history.length) {
                    this.$router.go(-1);
                    return
                }
            }).catch(() => {
                this.$buefy.toast.open({
                    duration: 10000,
                    message:
                        "There has been an error sending the registration code to the company. If this error persists contact support.",
                    position: "is-bottom",
                    type: "is-danger",
                });
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.code-container {
    display: flex;
    background-color: #ECF3FF;
    font-weight: 600;
    padding: 6px 16px;
    border-radius: 4px;
    width: max-content;
    font-size: 1rem;
}


.modal-card-body {
    margin: 28px;
    border-radius: 10px;
    text-align: -webkit-center;
}

.title {
    font-size: 1.2rem;
}
.code-section {
    display: flex;
    justify-content: center;
    align-items: center;
}


</style>