<template>
  <section>
    <b-field :label="isOptionalField('Cancelation Policy')">
      <b-input v-model.trim="cancellationPolicy" minlength="10" maxlength="200" type="textarea"
        validation-message="Deve ter de 10 a 200 caractéres"></b-input>
    </b-field>

    <b-field :label="isOptionalField('Not suitable for')">
      <b-input v-model.trim="notSuitableFor" maxlength="2750" type="textarea"
        validation-message="Deve ter no máximo 2750 caractéres"></b-input>
    </b-field>

    <b-field :label="isOptionalField('What´s Included')" class="pb-5">
      <b-input v-model.trim="whatsIncluded" type="textarea" maxlength="2750"
        validation-message="Deve ter no máximo 2750 caractéres"></b-input>
    </b-field>

    <b-field :label="isOptionalField('What to expect')" class="pb-5">
      <b-input v-model.trim="whatToExpect" type="textarea" maxlength="2750"
        validation-message="Deve ter no máximo 2750 caractéres"></b-input>
    </b-field>

    <b-field :label="isOptionalField('Additional information')" class="pb-5">
      <b-input v-model.trim="additionalInfo" type="textarea" maxlength="2750"
        validation-message="Deve ter no máximo 2750 caractéres"></b-input>
    </b-field>

    <b-field :label="isOptionalField('Accessibility')" class="pb-5">
      <b-input v-model.trim="accessibility" type="textarea" maxlength="2750"
        validation-message="Deve ter no máximo 2750 caractéres"></b-input>
    </b-field>

    <b-field :label="isOptionalField('Language(s)')" class="pb-6">
      <b-taginput v-model="languages" :closable="languages.length > 1" ellipsis icon="tag" type="primary"
        placeholder="Add a language" aria-close-label="Delete this language">
      </b-taginput>
    </b-field>

    <b-field :label="isOptionalField('Time slots (shared)')" class="pb-6">
      <b-taginput v-model="sharedTimeSlots" :closable="sharedTimeSlots?.length > 1" ellipsis icon="tag" type="primary"
        placeholder="Add a time slot" aria-close-label="Delete this time slot">
      </b-taginput>
    </b-field>

    <b-field :label="isOptionalField('Time slots (private)')" class="pb-6">
      <b-taginput v-model="privateTimeSlots" :closable="privateTimeSlots?.length > 1" ellipsis icon="tag" type="primary"
        placeholder="Add a time slot" aria-close-label="Delete this time slot">
      </b-taginput>
    </b-field>



    <div class="columns is-flex field pb-2">

      <b-field :label="isOptionalField('Min. num. of participants')">
        <b-numberinput type="is-primary" v-model="minNumOfParticipants" min="1" controls-rounded></b-numberinput>
      </b-field>

      <b-field :label="isOptionalField('Max. num. of participants')">
        <b-numberinput type="is-primary" v-model="maxNumOfParticipants" :min="minNumOfParticipants || 1"
          controls-rounded></b-numberinput>
      </b-field>


    </div>
    <!-- 
    <div class="columns field pb-5">
      <div class="column is-two-thirds">
        <b-field :label="isOptionalField('Max. num. of participants')"> </b-field>
      </div>
      <div class="column">
        <b-numberinput
          type="is-primary"
          v-model="maxNumOfParticipants"
          min="1"
          controls-rounded
        ></b-numberinput>
      </div>
    </div>

    <div class="columns field pb-5">
      <div class="column is-two-thirds">
        <b-field :label="isOptionalField('Min. num. of participants')"> </b-field>
      </div>
      <div class="column">
        <b-numberinput
          type="is-primary"
          v-model="minNumOfParticipants"
          min="1"
          controls-rounded
        ></b-numberinput>
      </div>
    </div> -->
  </section>
</template>

<script>
import { ActivityFormMixin } from './mixins/ActivityFormMixin'
export default {
  mixins: [ActivityFormMixin],
  props: ["activityInfo"],
  data() {
    return {
      maxNumOfParticipants: this.activityInfo.maxNumOfParticipants,
      minNumOfParticipants: this.activityInfo.minNumOfParticipants,
      notSuitableFor: this.activityInfo.notSuitableFor || "",
      cancellationPolicy: this.activityInfo.cancellationPolicy,
      languages: this.activityInfo.languages,
      sharedTimeSlots: this.activityInfo.sharedTimeSlots?.length ? this.activityInfo.sharedTimeSlots : ["10:00", "14:00"],
      privateTimeSlots: this.activityInfo.privateTimeSlots?.length ? this.activityInfo.privateTimeSlots : ["10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00"],
      whatsIncluded: this.activityInfo.whatsIncluded || "",
      whatToExpect: this.activityInfo.whatToExpect || "",
      additionalInfo: this.activityInfo.additionalInfo || "",
      accessibility: this.activityInfo.accessibility || ""
    };
  },

  activated() {
    this.emitData();
  },
  computed: {
    changeData() {
      const {
        maxNumOfParticipants,
        minNumOfParticipants,
        notSuitableFor,
        cancellationPolicy,
        languages,
        privateTimeSlots,
        sharedTimeSlots,
        whatsIncluded,
        whatToExpect,
        accessibility,
        additionalInfo
      } = this;
      return {
        maxNumOfParticipants,
        minNumOfParticipants,
        notSuitableFor,
        cancellationPolicy,
        languages,
        privateTimeSlots,
        sharedTimeSlots,
        whatsIncluded,
        whatToExpect,
        accessibility,
        additionalInfo
      };
    },
  },
  watch: {
    changeData: {
      handler: function () {
        this.emitData();
      },
      deep: true,
    },
  },

  methods: {
    emitData() {
      if (
        /* this.notSuitableFor.length >= 10 && */
        this.cancellationPolicy.length >= 10 &&
        this.languages.length > 0 /* &&
        this.privateTimeSlots.length >= 0 &&
        this.sharedTimeSlots.length >= 0 */
      ) {
        this.$emit("dataChanged", {
          maxNumOfParticipants: this.maxNumOfParticipants,
          minNumOfParticipants: this.minNumOfParticipants,
          notSuitableFor: this.notSuitableFor,
          cancellationPolicy: this.cancellationPolicy,
          languages: this.languages,
          privateTimeSlots: this.privateTimeSlots,
          sharedTimeSlots: this.sharedTimeSlots,
          whatsIncluded: this.whatsIncluded,
          whatToExpect: this.whatToExpect,
          additionalInfo: this.additionalInfo,
          accessibility: this.accessibility
        });
      } else {
        this.$emit("dataChanged", "invalid");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .columns {
    margin-left: 0;
    margin-right: 0;
    align-items: flex-start;
  }
}
</style>
