
import { ToastProgrammatic as toast } from "buefy";
import { db } from "../firebase";

export const HelpersMixin = {
    data() {
        return {
            tabletWidth: 1023,
            mobileWidth: 768
        }
    },
    computed: {
        isMobileView() {
            return window.innerWidth <= this.mobileWidth
        },
        isDesktopView(){
            return window.innerWidth > this.tabletWidth
        }
    },
    methods: {
        copyToClipboard(textToCopy) {
            // Copy the text inside the text field
            navigator.clipboard.writeText(textToCopy);

            toast.open({
                duration: 10000,
                message:
                    "Text copied to clipboard.",
                position: "is-bottom",
            });
        },
        getOverallRating(ratings) {
            if (ratings.length === 0) {
                return 0; // Return 0 if no ratings provided
            }

            // Calculate sum of ratings
            const sumOfRatings = ratings.reduce((total, rating) => total + rating, 0);

            // Calculate overall rating by dividing sum by number of ratings
            const overallRating = sumOfRatings / ratings.length;

            // Round to 2 decimal places
            return parseFloat(overallRating.toFixed(2));
        },
        isValidEmail(email) {
            // Regular expression for validating email addresses
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        },
        slugify(url) {
            const decodedUrl = decodeURIComponent(url);
            const seoFriendlyUrl = decodedUrl.toLowerCase().replace(/\s+/g, '-').replace(/:/g, '');
            return seoFriendlyUrl;
        },
        subscribeToNewsletter(email){
            db.firestore()
                .collection("newsletter")
                .add({ email })
                .then(() => {
                    this.$buefy.toast.open({
                        duration: 10000,
                        message: "Thank you for subscribing to our newsletter!",
                        position: "is-bottom",
                        type: "is-success",
                    });
                })
                .catch((error) => {
                    this.$buefy.toast.open({
                        duration: 10000,
                        message: error,
                        position: "is-bottom",
                        type: "is-danger",
                    });
                });
        }
    }
}