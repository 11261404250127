<template>
    <section>
        <b-field class="column pr-1 is-6" :label="isOptionalField('Event title')">
            <b-input v-model="title"></b-input>
        </b-field>

        <b-field class="column pr-1 is-6" :label="isOptionalField('Price (€)')">
            <b-input type="float" placeholder="0,00" v-model="price"></b-input>
        </b-field>

        <b-field label="Date" class="mt-4">

            <b-datepicker placeholder="Click to select..." v-model="eventDate" :min-date="minDate">
            </b-datepicker>

        </b-field>

        <b-field class="column pr-1 is-6 mt-4" :label="isOptionalField('Time (hh:mm)')">
            <b-input v-model="eventTime"></b-input>
        </b-field>

        <b-field label="Event's activity" class="mb-6 mt-4">
            <b-select placeholder="Choose an activity" expanded :loading="isLoadingTours" v-model="eventActivity">
                <option v-for="activity in activities" :key="activity.id" :value="activity.id">{{ activity.title }}</option>
            </b-select>
        </b-field>

        <b-field class="column pr-1 is-6" :label="isOptionalField('WhatsApp Group Link')">
            <b-input v-model="whatsappGroupLink"></b-input>
        </b-field>
    </section>
</template>

<script>
import { ActivityFormMixin } from '@/CreationFormComponents/mixins/ActivityFormMixin'
import { ActivitiesActions, ActivitiesStates } from "../store/Storetypes";
import { mapActions, mapState } from "vuex";

export default {
    mixins: [ActivityFormMixin],
    props: {
        eventInfo: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        const min = new Date();
        min.setHours(0);
        min.setMinutes(0);
        return {
            title: this.eventInfo.title,
            price: this.eventInfo.price,
            eventDate: this.eventInfo?.eventDate ? new Date(this.eventInfo?.eventDate) :  this.eventInfo?.eventDate  ,
            eventTime: this.eventInfo?.eventTime,
            eventActivity: this.eventInfo?.eventActivity,
            whatsappGroupLink: this.eventInfo?.whatsappGroupLink,
            isLoadingTours: true,
            minDate: min,
            isEditing: false,
        };
    },
    created() {
        this.getActivities()
        if (this.$route.name === "edit-event") {
            this.isEditing = true;
        }
    },
    computed: {
        ...mapState({
            activities: state => state.activities[ActivitiesStates.ACTIVITIES]
        }),
        changeData() {
            const {
                title,
                price,
                eventDate,
                eventTime,
                eventActivity,
                whatsappGroupLink
            } = this;
            return {
                title,
                price,
                eventDate,
                eventTime,
                eventActivity,
                whatsappGroupLink
            };
        },
    },
    watch: {
        changeData: {
            handler: function () {
                this.emitData();
            },
            deep: true,
        },
        activities: {
            handler: function () {
                this.Tours = this.activities
                this.isLoadingTours = false;
            },
            deep: true
        },
    },
    activated() {
        this.emitData();
    },

    methods: {
        ...mapActions({
            getActivities: ActivitiesActions.GET_ACTIVITIES
        }),
        emitData() {
            if (this.title && this.eventActivity && this.price && this.eventDate && this.eventTime) {
                this.$emit("dataChanged", {
                    title: this.title,
                    price: Number(this.price),
                    eventActivity: this.eventActivity,
                    whatsappGroupLink: this.whatsappGroupLink,
                    eventTime: this.eventTime,
                    eventDate: this.eventDate ? this.eventDate.setHours(0, 0, 0, 0) : this.eventDate
                });
            } else {
                this.$emit("dataChanged", "invalid");
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.column {
    padding: 0;
}

::v-deep {
    .help.is-invisible {
        display: none;
    }

    .columns {
        margin-left: 0;
        margin-right: 0;
        align-items: flex-start;
    }
}
</style>