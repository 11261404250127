<template>
  <section class="pt-3 pb-3">
    <!--  <b-navbar>
        <template #brand>
            <b-navbar-item tag="router-link" :to="{ path: '/' }">
                <img src="../assets/logo-white.png" />
            </b-navbar-item> 
        </template>

        <template #end>
            <b-navbar-item tag="div">
                    <b-button  size="is-medium" type="is-primary"  outlined>
                       Exit
                    </b-button>
            </b-navbar-item>
        </template>
    </b-navbar> -->

    <div class="container max-desktop mt-3">
      <p class="mb-6 title">Request a quote</p>
      <b-progress type="is-primary" :value="progressValue"></b-progress>
      <div>
        <div>
          <div v-if="activeTab === 0">
            <p class="mb-2 label">Where would you like to go?</p>
            <p class="section-subtitle mb-4">
              Please write the destinations you'd like to visit.
            </p>
            <b-field>
              <b-taginput
                v-model="destinations"
                :data="filteredTags"
                @typing="getFilteredTags"
                autocomplete
                allow-new
                open-on-focus
                field="destination"
                ellipsis
                icon="map-marker-alt"
                aria-close-label="Delete this city"
              >
              </b-taginput>
            </b-field>

            <p class="section-title mt-6 mb-2">When would you like to go?</p>
            <p class="section-subtitle mb-4">
              Feel free to enter specific dates, a specific month, or season!
            </p>

            <b-input v-model="dates" required> </b-input>
          </div>

          <div v-if="activeTab === 1">
            <b-field label="Who are you traveling with?" class="mb-5">
              <div class="grid">
                <div
                  v-for="(type, index) in tripType"
                  @click="selectedGroupType = type"
                  :key="index"
                  class="option cell mr-3 mt-3"
                  :class="{ selected: selectedGroupType === type }"
                >
                  <div class="mr-3" >
                    {{ type }}
                  </div>
                </div>
              </div>
            </b-field>

            <b-field label="How many people will be in the trip?" class="mb-5">
              <div class="is-flex is-align-items-center participants">
                <p class="mr-3">{{ nOfParticipants }}</p>
                <div>
                  <b-button
                    icon-left="minus"
                    class="mr-2"
                    rounded
                    @click="decrement"
                    :disabled="nOfParticipants === 1"
                  ></b-button>
                  <b-button
                    icon-left="plus"
                    rounded
                    @click="nOfParticipants += 1"
                  ></b-button>
                </div>
              </div>
            </b-field>

            <b-field label="What is the age range of the travelers?" class="mb-5">
              <b-input v-model="ageRange" required> </b-input>
            </b-field>
          </div>

          <div v-if="activeTab === 2">
            <b-field label="Do you need help planning you itinerary?" class="mb-5">
              <div class="grid">
                <div
                  class="option mr-3 mt-3 cell"
                  :class="{ selected: shouldPlanItinerary }"
                  @click="shouldPlanItinerary = true"
                >
                  <div class="mr-3" >
                    Yes, please!
                  </div>
                </div>
                <div
                  class="option mt-3 cell"
                  :class="{ selected: !shouldPlanItinerary }"
                  @click="shouldPlanItinerary = false"
                >
                  <div>
                    I already know what I want to do
                  </div>
                </div>
              </div>
            </b-field>

            <div v-if="shouldPlanItinerary">
              <b-field label="Which activities are you interested in?" class="mb-5">
                <div class="grid">
                  <div
                    v-for="(type, index) in activityTypes"
                    :key="index"
                    class="option mr-3 mt-3 cell"
                    :class="{ selected: selectedInterests.includes(type) }"
                    @click="setInterests(type)"
                  >
                    <div class="mr-3" >
                      {{ type }}
                    </div>
                  </div>
                </div>
              </b-field>
              <b-field label="Other interests (optional)" class="mb-5">
                <b-taginput
                  v-model="tags"
                  ellipsis
                  icon="tag"
                  placeholder="Add an interest"
                  aria-close-label="Delete this interest"
                >
                </b-taginput>
              </b-field>

              <b-field label="What are your non-negotiables? (optional)" class="mb-5">
                <div>
                  <span class="section-subtitle"
                    >Which destinations would you like to visit and how long
                    would you like to spend in each place?</span
                  >
                  <b-input
                    expanded
                    v-model="nonNegotiables"
                    maxlength="1000"
                    type="textarea"
                    class="pt-3"
                  ></b-input>
                </div>
              </b-field>
            </div>

            <div>
              <b-field label="Would you like a private guide?" class="mb-5">
                <div class="grid">
                  <div
                    v-for="(type, index) in ['Yes', 'No']"
                    :key="index"
                    class="option mr-3 mt-3 cell"
                    :class="{ selected: withPrivateGuide === type }"
                    @click="withPrivateGuide = type"
                  >
                    <div class="mr-3" >
                      {{ type }}
                    </div>
                  </div>
                </div>
              </b-field>

              <b-field label="Would you like an airport transfer?" class="mb-5">
                <div class="grid">
                  <div
                    v-for="(type, index) in ['Yes', 'No']"
                    :key="index"
                    class="option mr-3 mt-3 cell"
                    :class="{ selected: withAirportTransfer === type }"
                    @click="withAirportTransfer = type"
                  >
                    <div class="mr-3" >
                      {{ type }}
                    </div>
                  </div>
                </div>
              </b-field>
              <b-field label="Notes (optional)" class="mb-5">
                <div>
                  <b-input
                    expanded
                    placeholder="Eg. Special needs, food requirements or anything not included in the form that you'd like to add"
                    v-model="notes"
                    maxlength="1000"
                    type="textarea"
                  ></b-input>
                </div>
              </b-field>
            </div>
          </div>

          <div v-if="activeTab === 3">
            <b-field label="Full Name" class="name">
              <b-field>
                <b-input expanded v-model="name" required></b-input>
              </b-field>
            </b-field>

            <b-field label="Email">
              <b-input type="email" v-model="email" required> </b-input>
            </b-field>
          </div>

          <div
            class="buttons mb-4 mt-5"
            :class="{ 'is-flex is-justify-content-flex-end': activeTab === 0 }"
          >
            <b-button
              v-if="[1, 2, 3].includes(activeTab)"
              @click="goToPrevious"
              type="is-primary"
              >Previous</b-button
            >

            <b-button
              v-if="[0, 1, 2].includes(activeTab)"
              :disabled="!isCurrentStepValid"
              @click="goToNext"
              type="is-primary"
              >Next</b-button
            >
            <b-button
              :loading="isSendingRequest"
              :disabled="!isCurrentStepValid"
              v-if="activeTab === 3"
              @click="sendEmail"
              type="is-primary"
              >Request quote</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { HelpersMixin } from "../mixins";
export default {
  mixins: [HelpersMixin],
  data() {
    const today = new Date();
    return {
      isCurrentStepValid: false,
      minDate: new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + 3
      ),
      cities: [
        "Albufeira",
        "Alentejo",
        "Algarve",
        "Aveiro",
        "Coimbra",
        "Évora",
        "Faro",
        "Fátima",
        "Lagos",
        "Lisbon",
        "Nazaré",
        "Óbidos",
        "Portimão",
        "Porto",
        "Sintra",
        "Tomar",
      ],
      filteredTags: this.cities,
      mainOptions: [
        "I need help planning my trip",
        "I already have my trip planned",
      ],
      tripType: ["Solo", "Couple", "Family", "Friends"],
      activityTypes: [
        "City sightseeing",
        "Nightlife",
        "Food exploration",
        "Wine tasting",
        "Beaches",
        "Culture and History",
      ],
      activeTab: 0,
      dates: null,
      destinations: [],
      tags: [],
      nOfParticipants: 1,
      shouldPlanItinerary: true,
      nonNegotiables: "",
      notes: "",
      selectedInterests: [],
      withPrivateGuide: undefined,
      withAirportTransfer: undefined,
      selectedGroupType: undefined,
      email: "",
      ageRange: undefined,
      name: "",
      isSendingRequest: false,
      isFormSent: false,
    };
  },
  watch: {
    formValidation: {
      handler: function () {
        this.validateStep()
      },
      deep: true,
    },
  },
  computed: {
    formValidation() {
      const {
        dates,
        destinations,
        nOfParticipants,
        shouldPlanItinerary,
        selectedInterests,
        withPrivateGuide,
        withAirportTransfer,
        selectedGroupType,
        email,
        ageRange,
        name,
      } = this;
      return {
        dates,
        destinations,
        nOfParticipants,
        shouldPlanItinerary,
        selectedInterests,
        withPrivateGuide,
        withAirportTransfer,
        selectedGroupType,
        email,
        ageRange,
        name,
      };
    },
    progressValue() {
      let progress = 0;
      if (this.activeTab === 0) {
        progress = 0;
      } else if (this.activeTab === 1) {
        progress = 33; // 33% for step 2
      } else if (this.activeTab === 2) {
        progress = 66; // 66% for step 3
      } else if (this.activeTab === 3) {
        progress = 100; // 100% for step 4
      }
      return progress;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    validateStep(){
        let valid = false;
console.log( this.destinations);
        if (this.activeTab === 0) {
          valid = this.destinations.length && this.dates?.length;
        }
        if (this.activeTab === 1) {
          valid = this.ageRange.length > 1 && !!this.selectedGroupType;
          console.log({valid});
        }
        if (this.activeTab === 2) {
          const withIteneraryValidation =
            (this.selectedInterests.length || this.tags.length) &&
            !!this.withPrivateGuide &&
            !!this.withAirportTransfer &&
            !!this.selectedGroupType;

          const withoutIteneraryValidation =
            !!this.withPrivateGuide && !!this.withAirportTransfer;

          valid = this.shouldPlanItinerary
            ? withIteneraryValidation
            : withoutIteneraryValidation;
        }
        if (this.activeTab === 3) {
          valid = this.isValidEmail(this.email) && this.name.length;
        }
        this.isCurrentStepValid = valid;
    },
    goToNext(){
        this.isCurrentStepValid = false;
        this.activeTab += 1;
        this.validateStep()
    },
    goToPrevious(){
       
        this.activeTab -= 1;
        this.validateStep()
    },
    getFilteredTags(text) {
      this.filteredTags = this.cities.filter((option) => {
        return option.toLowerCase().indexOf(text.toLowerCase()) >= 0;
      });
    },
    humanizedDate() {
      return this.dates.length ? this.date.toDateString() : "";
    },
    decrement() {
      if (this.nOfParticipants === 1) return;

      this.nOfParticipants -= 1;
    },
    setInterests(selectedInterest) {
      if (this.selectedInterests.includes(selectedInterest)) {
        this.selectedInterests = this.selectedInterests.filter(
          (interest) => interest !== selectedInterest
        );
        return;
      }
      this.selectedInterests.push(selectedInterest);
    },

    async sendEmail() {
      const interests = this.selectedInterests.concat(this.tags);
      const destinations = this.destinations.concat(this.destinations);
      const data = {
        clientName: `${this.name}`,
        dates: `${this.dates}`,
        nOfParticipants: this.nOfParticipants,
        shouldPlanItinerary: this.shouldPlanItinerary,
        nonNegotiables: this.nonNegotiables,
        notes: this.notes,
        selectedInterests: interests.join(", "),
        destinations: destinations.join(", "),
        withPrivateGuide: this.withPrivateGuide,
        withAirportTransfer: this.withAirportTransfer,
        selectedGroupType: this.selectedGroupType,
        email: this.email,
        ageRange: this.ageRange,
      };
      this.isSendingRequest = true;
      await fetch(`${process.env.VUE_APP_API_URL}/send//mail/quote-request`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            window.scrollTo(0, 0);
            this.$router.push({ name: "quote-request-sent" });
            // this.isFormSent = true
          } else {
            this.$buefy.toast.open({
              duration: 10000,
              message:
                "There has been an error sending your quote request. If this error persists please contact support.",
              position: "is-bottom",
              type: "is-danger",
            });
          }
          this.isSendingRequest = false;
        })
        .catch((error) => {
          this.$buefy.toast.open({
            duration: 10000,
            message: error,
            position: "is-bottom",
            type: "is-danger",
          });
          this.isSendingRequest = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-family: "Playfair Display", serif;
  color: #0e3d4d;
  font-size: 1.6rem;
  font-weight: 300;
}

.navbar-menu,
.navbar {
  background-color: #0e3d4d;
}

.button.is-primary.is-outlined {
  background-color: transparent;
  border-color: white;
  color: white;
  font-size: 1rem;
  padding: 22px 27px;
}

.tabs a.completed {
  background-color: #0e3d4d;
}

.modal-card-body {
  border-radius: 10px;
  background-color: #fff;
  min-height: 100vh;
}

.buttons {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  button {
    font-size: 1rem;
    height: 2.5rem;
    margin-bottom: 0.75rem;
    padding: 2px 19px;
    border: none;
  }
}

.name {
  button {
    border-color: transparent;
    background-color: #f6fbff;
  }
}

.participants {
  margin-top: 0.5rem;

  p {
    width: 3rem;
  }

  button {
    height: 2.25rem;
  }
}

.option {
  padding: 12px;
  border-radius: 0;
  background-color: #fff;
  border: 1px solid #859ea7;
  font-weight: 400;
  color: #0e3d4d;
  &.selected {
    background-color: #0e3d4d;
    color: white;
  }
}

.section-title,
label.label {
  font-size: 1.1rem;
  font-weight: 300;
  color: #0e3d4d;
}

.progress.is-success {
  background-color: #124f69 !important;
}

.section-subtitle {
  font-weight: 300;
  color: #6b8f9c;
  font-size: 0.85rem;
}

.b-checkbox.checkbox.button {
  border-radius: 12px;
  height: 2.5rem;
  font-weight: 700;
  font-size: 0.9rem;
}

.b-checkbox.checkbox.button:not(.is-primary) {
  border-color: transparent;
  background-color: #f6fbff;
  color: #0e3d4d;
}

.calendar-icon {
  height: 2.5rem !important;
  width: 41px;
  margin-top: 0.5rem;
  border-radius: 6px;
  img {
    height: 1.25em;
    width: 1.5em;
  }
}

::v-deep {
  .taginput .taginput-container.is-focusable {
    .input {
      border-color: transparent !important ;
    }
  }
  .navbar-menu {
    padding: 9px 51px 8px 40px;
  }

  .navbar-menu,
  .navbar {
    background-color: #0e3d4d;
  }
  label.label,
  .label {
    font-size: 1.1rem;
    font-weight: 300;
    color: #0e3d4d;
  }

  .pagination-previous,
  .pagination-next,
  .pagination-link {
    border-color: transparent;
  }
  .datepicker .dropdown-content {
    box-shadow: none;
  }

  .datepicker {
    .field {
      margin-bottom: 0;
    }
    .select select {
      border-bottom: none !important;
    }
  }

  .tabs a {
    border-radius: 0;
    margin-right: 0;
  }
  .tabs li.is-active a {
    background-color: #bfd3d9;
  }

  .name {
    span {
      font-size: 1rem;
    }
  }

  .field {
    margin-bottom: 1.5rem;
  }

  .b-tabs .tab-content {
    margin-top: 2rem;
    padding: 0;
  }

  .button.is-info {
    background-color: #0e3d4d;
    color: white;
    &:hover {
      opacity: 0.95;
    }
  }

  .input,
  .select,
  .select select,
  .textarea {
    border-radius: 0;
    border: 1px solid #e2e9ec !important;
    background-color: rgb(174 217 231 / 3%);
  }

  .borderless {
    input {
      border-color: transparent;
    }
  }
}
</style>
