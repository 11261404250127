<template>
    <section>
        <b-notification v-if="isCompany && !user?.hasFinishedSetUp" class="mt-4" type="is-danger is-light"
            aria-close-label="Close notification" role="alert">
            <router-link :to="{ name: 'company-profile' }">Finish filling out your company profile </router-link>to get paid
        </b-notification>

        <b-tabs v-model="activeTab" v-if="activityGroups.length" class="mt-5">
            <b-tab-item label="Needs attention" v-if="isAdmin" class="tab-danger">
                <UpcomingActivities empty-state-title="No activities need attention" needs-attention :is-title-clickable="false" />
            </b-tab-item>

            <b-tab-item label="Today">
                <UpcomingActivities empty-state-title="No activities scheduled for today" :is-title-clickable="false" show-today-only />
            </b-tab-item>
            <b-tab-item label="Upcoming">
                <UpcomingActivities show-upcoming-only empty-state-title="No activities scheduled"
                    :is-clickable="isAdmin" />
            </b-tab-item>
            <b-tab-item label="Finished">
                <UpcomingActivities :employee-only="isEmployee" empty-state-title="There are no activities finished yet"
                    show-previous-only :is-clickable="isAdmin" :is-title-clickable="false"/>
            </b-tab-item>
        </b-tabs>

        <div v-else class="container--info is-justify-content-center">
            There are no tours scheduled.
        </div>
    </section>
</template>

<script>
import UpcomingActivities from './UpcomingActivities.vue'
import { mapActions, mapState } from "vuex";
import { ActivitiesStates, UsersStates, ActivitiesActions } from '../store/Storetypes'

export default {
    components: {
        UpcomingActivities
    },
    data() {
        return {
            activeTab: 0,
        }
    },
    created() {
        this.getActivityGroups()
    },
    computed: {
        ...mapState({
            user: state => state.users[UsersStates.USER],
            activityGroups: state => state.activities[ActivitiesStates.ACTIVITY_GROUPS],
            isAdmin: state => state.users[UsersStates.IS_ADMIN],
            isEmployee: state => state.users[UsersStates.IS_EMPLOYEE],
            isCompany: state => state.users[UsersStates.IS_COMPANY],
        }),
    },
    methods: {
        ...mapActions({
            getActivityGroups: ActivitiesActions.GET_ACTIVITY_GROUPS,
        }),
    }
}
</script>

<style scoped lang="scss">
::v-deep {
    .column {
        padding: 0.75rem 0;
    }

    .b-tabs .tab-content {
        padding: 0
    }

    .tabs li.is-active a {
        font-weight: 600;
    }
}
</style>