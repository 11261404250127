<template>
    <section>
        <GoBack route-name-humanized="Change password" />

        <div class="mb-6 pt-6">
            <b-field class="pt-6" label="Current password">
                <b-input icon="lock" icon-pack="fas" v-model="currentPassword" autocomplete="current-password"
                    type="password" password-reveal>
                </b-input>
            </b-field>

            <b-field label="New password">
                <b-input icon="lock" icon-pack="fas" v-model="newPassword" autocomplete="current-password" type="password"
                    password-reveal>
                </b-input>
            </b-field>

        </div>

        <b-button :loading="isLoading" @click.prevent="updatePassword()" class="mb-3" type="is-primary" expanded
            :disabled="!hasChangedPassword || isLoading">
            Change password
        </b-button>

    </section>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import GoBack from '../components/elements/GoBack.vue';
import { isEqual } from 'lodash'
import { UserActions, UsersStates } from '../store/Storetypes';

export default {
    components: {
        GoBack
    },
    data() {
        return {
            newPassword: null,
            currentPassword: null,
            isLoading: false,
            hasChangedPassword: false,
        };
    },
    computed: {
        ...mapState({
            passwordChanged: state => state.users[UsersStates.PASSWORD_CHANGED],
        }),
        changesToPassword() {
            const { newPassword, currentPassword } = this;
            return {
                newPassword,
                currentPassword,
            };
        },
    },
    watch: {
        changesToPassword() {
            if (this.currentPassword?.length > 8 && this.newPassword?.length > 8) {
                this.hasChangedPassword = true;
                return
            }
            this.hasChangedPassword = false;
        },
        passwordChanged(){
            if (this.passwordChanged) {
                this.currentPassword= undefined
                this.newPassword= undefined
            }
        }
    },
    methods: {
        ...mapActions({
            changePassword: UserActions.CHANGE_PASSWORD
        }),
        async updatePassword() {
            if (!this.hasChangedPassword) return
            if (isEqual(this.currentPassword, this.newPassword)) {
                this.$buefy.toast.open({
                    duration: 10000,
                    message: "The new password has to be different from your current password",
                    position: "is-bottom",
                    type: "is-danger",
                });
                return
            }
            this.changePassword({currentPassword: this.currentPassword,newPassword:this.newPassword})
        }
    }
}
</script>