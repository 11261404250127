<template>
    <div class="ticket--container">
        <div class="columns is-mobile" @click="onActivityClicked">
            <div class="column is-1">
                <b-checkbox native-value="isSelected" v-model="isSelected" disabled></b-checkbox>
            </div>
            <div class="column">
                <b-tag class="mb-2 mr-2" type="is-light">{{ dateWithMonthAndDay(date) }}</b-tag>
                <b-tag class="mb-2" type="is-primary">{{ seatsToFill }} seats to fill</b-tag>
                <div class="display-flex-space-btw">
                    <p class="activity-title is-blue mb-3 mr-3">{{ activityInfo?.title || "Unknown" }} </p>
                </div>

                <div class="description">
                    <div class="display-flex mr-2 g-button-primary--light">
                        <b-icon pack="fas" icon="clock" size="is-medium"></b-icon>
                        <span class="is-blue">{{ timeFrame }}</span>
                    </div>

                    <div class="display-flex g-button-primary--light">
                        <b-icon pack="fas" icon="wallet" size="is-medium"></b-icon>
                        <span>{{ payAmount }}€</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="mb-3 ml-5 pl-2">
            <b-field label="How many seats do you have?">
                <b-numberinput :disabled="!isSelected" class="participants-field" type="is-primary" v-model="numberOfSeats"
                    min="4" controls-alignment="right"></b-numberinput>
            </b-field>
        </div>

        <div class="mb-3 ml-5 pl-2">
            <b-field label="How many vehicles do you have?">
                <b-numberinput :disabled="!isSelected" class="participants-field" type="is-primary" v-model="numberOfVehicles"
                    min="1" controls-alignment="right"></b-numberinput>
            </b-field>
        </div>
    </div>
</template>

<script>
import { dateWithMonthAndDay, convertMinutesToHoursAndMinutes, addDurationToTime } from '../../helpers';
import { mapState } from "vuex";
import { UsersStates } from '../../store/Storetypes'
export default {
    name: "Ticket",
    data() {
        return {
            dateWithMonthAndDay,
            convertMinutesToHoursAndMinutes,
            addDurationToTime,
            isSelected: false,
            numberOfSeats: 4,
            numberOfVehicles: 1
        }
    },
    props: {
        activityInfo: {
            type: Object,
            default: () => { }
        },
        activityGroupInfo: {
            type: Object,
            default: () => { }
        },
        time: {
            type: String,
            default: "Unknown"
        },
        endTime: {
            type: String,
            default: undefined
        },
        date: {
            type: [String, Number],
            default: "Unknown"
        }
    },
    // TODO: Disable ticket if date is not current or go to info of activity
    methods: {
        onActivityClicked() {
            this.isSelected = !this.isSelected
            this.$emit('on-btn-click', {
                isSelected: this.isSelected,
                activityGroup: this.activityGroupInfo,
                nOfSeats: this.numberOfSeats,
                numberOfVehicles: this.numberOfVehicles
            })
        }
    },
    computed: {
        ...mapState({
            user: state => state.users[UsersStates.USER],
        }),
        timeFrame() {
            if (this.endTime) return `${this.time} - ${this.endTime}`
            const duration = convertMinutesToHoursAndMinutes(this.activityInfo.duration)
            const endTime = addDurationToTime(this.time, duration.hours, duration.minutes)
            return `${this.time} - ${endTime}`
        },
        payAmount() {
            return this.activityInfo.guiderPayment || 'Unknown'
        },
        seatsToFill() {
            if (!this.activityGroupInfo?.guiders || !this.activityGroupInfo?.guiders.length) return this.activityGroupInfo?.groupSize
            const initialValue = 0; // Initial sum is 0

            const filledSeats = this.activityGroupInfo?.guiders.reduce((accumulator, currentValue) => {
                return accumulator + currentValue.nOfSeats;
            }, initialValue);
            const seatsToFill = this.activityGroupInfo?.groupSize -  filledSeats
            return seatsToFill 
        }
    },
    watch: {
        numberOfSeats() {
            if (!this.isSelected) return
            this.$emit('on-btn-click', {
                isSelected: this.isSelected,
                activityGroup: this.activityGroupInfo,
                nOfSeats: this.numberOfSeats,
                numberOfVehicles: this.numberOfVehicles
            })
        },
        numberOfVehicles() {
            if (!this.isSelected) return
            this.$emit('on-btn-click', {
                isSelected: this.isSelected,
                activityGroup: this.activityGroupInfo,
                nOfSeats: this.numberOfSeats,
                numberOfVehicles: this.numberOfVehicles
            })
        },
    }
}

</script>

<style lang="scss" scoped>
.ticket--container {
    margin: 1.25rem 0;
    padding: 0.75rem;
    background-color: #FFFFFF;
    display: block;
    border-radius: 10px;
    border: 1px solid #EDEBF2;
    box-shadow: 5px 5px 16px rgba(27, 3, 91, 0.04);
}

.activity-title {
    font-weight: 500;
    font-size: 1rem;
}

.icon {
    height: 14px;
    padding-right: .25rem;
    width: auto;
}

.description {
    font-size: 12px;
    display: flex;
    align-items: center;
}

.columns.is-mobile {
    align-items: flex-start;
}

.b-checkbox.checkbox:not(.button) {
    margin-top: .25rem;
}

::v-deep {

    .field .label {
        font-size: .9rem;
    }

    .b-checkbox.checkbox[disabled] {
        opacity: 1;
    }

    .b-numberinput input[type=number] {
        width: 40px
    }

    .b-numberinput.field.is-grouped div.control {
        -webkit-box-flex: 0;
        -ms-flex-positive: 1;
        flex-grow: 0;
        flex-shrink: 0;
    }

    .participants-field .control {

        .button.is-primary[disabled],
        .button.is-primary {
            background-color: transparent;
            border-color: transparent;
            color: #0e3d4d
        }

        .button.is-primary[disabled] {
            opacity: .1
        }
    }
}
</style>