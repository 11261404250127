import {
    AffiliatesMutations as Mutations,
    AffiliatesStates as States,
    AffiliatesActions as Actions
} from '../Storetypes'

import { db } from "../../firebase";
import { ToastProgrammatic as toast } from "buefy";

export default {
    state: {
        [States.AFFILIATES]: [],
        [States.SELECTED_AFFILIATE_DATA]: {},
        [States.AFFILIATES_COUPONS]: undefined,
    },

    mutations: {
        [Mutations.SET_AFFILIATES](state, affiliates) {
            state[States.AFFILIATES] = affiliates
        },
        [Mutations.SET_SELECTED_AFFILIATE_DATA](state, affiliate) {
            state[States.SELECTED_AFFILIATE_DATA] = affiliate
        },
        [Mutations.SET_AFFILIATES_COUPONS](state, coupons) {
            state[States.AFFILIATES_COUPONS] = coupons
        },
    },
    actions: {
        [Actions.GET_AFFILIATES]({ commit }) {
            let Affiliates = [];
            db.firestore()
                .collection("affiliates")
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        Affiliates.push({
                            ...doc.data(),
                            id: doc.id,
                        });
                    });
                    commit(Mutations.SET_AFFILIATES, Affiliates)
                })
        },
        [Actions.GET_AFFILIATE_DATA]({ state }, affiliateId) {
            const affiliates = state[States.AFFILIATES]
            const selectedAffiliateIndex = affiliates.findIndex((affiliates) => affiliates.id === affiliateId)
            if (selectedAffiliateIndex < 0) return {}
            state[States.SELECTED_AFFILIATE_DATA] = affiliates[selectedAffiliateIndex]
            return affiliates[selectedAffiliateIndex]
        },
       async [Actions.GET_ALL_AFFILIATES_COUPONS]({ commit }) {
            await fetch(`${process.env.VUE_APP_API_URL}/stripe/coupons`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                },
                credentials: "same-origin",
              })
                .then((response) => response.json())
                .then((data) => {
                  if (data.success) {
                    commit(Mutations.SET_AFFILIATES_COUPONS, data.coupons.data)
                  } else {
                    toast.open({
                        duration: 10000,
                        message: 'There has been an error retrieving the affiliates coupons.',
                        position: "is-bottom",
                        type: "is-danger",
                      });
                  }
                })
                .catch((error) => {
                    toast.open({
                        duration: 10000,
                        message: error,
                        position: "is-bottom",
                        type: "is-danger",
                      });
                }); 
        }
    }
}