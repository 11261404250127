
<template>
  <section>
    <b-loading v-if="!activityInfo"></b-loading>
    <div v-else class="container">
      <div>
        <GoBack class="mb-6" :route-name-humanized="titles[progress]" />

        <b-progress :value="(100 / titles.length) * (progress + 1)" type="is-primary" size="is-small"></b-progress>

        <keep-alive>
          <initial-information :activity-info="activityData" @dataChanged="dataChanged"
            v-if="progress == 0"></initial-information>

          <images :activity-info="activityData" @dataChanged="dataChanged" v-if="progress === 1"></images>

          <important-information :activity-info="activityData" @dataChanged="dataChanged"
            v-if="progress === 2"></important-information>

          <meeting-point :activity-info="activityData" @dataChanged="dataChanged" v-if="progress === 3"></meeting-point>

          <review :activity-info="activityInfo" v-if="progress === 4"></review>
        </keep-alive>

        <div class="buttons">
          <b-button v-if="progress !== 0" @click="progress -= 1" type="is-text">Back</b-button>
          <div v-if="progress == 0"></div>

          <b-button v-if="!isUploadingImages && progress !== 4" @click="validateForm"
            :class="[valid ? 'valid' : 'invalid']">Next</b-button>
          <b-tooltip :active="!formHasChanged" label="No changes have been made">
            <b-button v-if="progress === 4 && !isUploadingImages" @click="onFormSubmit" :disabled="!formHasChanged"
              type="is-primary">{{ buttonText }}</b-button>
          </b-tooltip>

          <b-button v-if="isUploadingImages" loading>Loading</b-button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { db } from "../firebase";
import { mapMutations, mapState } from "vuex";
import GoBack from "./elements/GoBack.vue";
import Images from "../CreationFormComponents/Images.vue";
import InitialInformation from "../CreationFormComponents/InitialInformation.vue";
import ImportantInformation from "../CreationFormComponents/ImportantInformation.vue";
import MeetingPoint from "../CreationFormComponents/MeetingPoint.vue";
import Review from "../CreationFormComponents/Review.vue";
import { ActivitiesMutations, ActivitiesStates } from "../store/Storetypes";
import { isEqual } from 'lodash'

const initialActivityInfo = {
  cancellationPolicy: "",
  city: "Lisbon, Portugal",
  coverImage: null,
  description: "",
  duration: null,
  galleryImages: [],
  languages: ["English"],
  meetingPoint: "Praça da Figueira, 1100-241 Lisboa, Portugal",
  notSuitableFor: "",
  whatToExpect: "",
  additionalInfo: "",
  accessibility: "",
  maxNumOfParticipants: 4,
  minNumOfParticipants: 2,
  sharedTimeSlots: ["10:00", "14:00"],
  privateTimeSlots: ["10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00"],
  categories: [],
  price: null,
  marker: {
    lat: 38.71364974975586,
    lng: -9.138160705566406,
  },
  privatePrice: null,
  marketPrice: null,
  //reservationPrice: null,
  guiderPayment: null,
  isPrivateOnly: true,
  stops: [
    {
      title: '',
      imageUrl: null,
      description: ''
    }
  ],
  title: "",
  whatsIncluded: "",
  isVisible: true,
  reports: [],
  evaluations: [],
  stars: null,
  isFeatured: false,
  priceLink: null,
  sharedPriceLink: null
}

export default {
  components: {
    Images,
    InitialInformation,
    ImportantInformation,
    MeetingPoint,
    Review,
    GoBack
  },
  data() {
    return {
      buttonText: "Confirm",
      progress: 0,
      tourCreated: false,
      valid: false,
      isUploadingImages: false,
      isLoading: true,
      activityInfo: initialActivityInfo,
      activityToEditData: {},
      activityIndex: -1
    };
  },
  computed: {
    ...mapState({
      activities: state => state.activities[ActivitiesStates.ACTIVITIES]
    }),
    isEdit() {
      return this.$route.name === "edit-activity"
    },
    formHasChanged() {
      const hasChanged = this.isEdit ? !isEqual(this.activityToEditData, this.activityInfo) : !isEqual(initialActivityInfo, this.activityInfo)
      return hasChanged
    },
    titles() {
      return [
        // this.isEdit ? "Edit activity" : "Create activity",
        "Initial information",
        "Images",
        "About this activity",
        "Location & Itinerary",
        "Review",
      ]
    },
    activityData() {
      if (this.$route.name === "edit-activity") {
        const activityData = this.setEditActivityData()
        return activityData
      }
      this.finishPreparingActivityData(true)
      return this.activityInfo
    }
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  },
  methods: {
    ...mapMutations({
      getActivityData: ActivitiesMutations.GET_ACTIVITY_DATA,
      updateActivityData: ActivitiesMutations.UPDATE_ACTIVITY_DATA
    }),
    setEditActivityData() {
      this.activityIndex = this.getSelectedActivityIndex()
      if (this.activityIndex < 0) {
        this.finishPreparingActivityData(true)
        return
      }
      const activityData = this.activities[this.activityIndex]
      this.activityToEditData = activityData;
      this.activityInfo = activityData;
      return activityData
    },
    getSelectedActivityIndex() {
      const activityId = this.$route.params.id
      return this.activities.findIndex((activity) => activity.id === activityId)
    },
    finishPreparingActivityData(activityFound) {
      this.isLoading = false
      this.notFound = activityFound;
    },
    retrieveActivityData() {
      db.firestore()
        .collection("tours")
        .doc(this.$route.params.id)
        .get()
        .then((doc) => {
          if (doc.data()) {
            this.activityToEditData = doc.data();
            this.activityInfo = doc.data();
            this.isLoading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.notFound = true;
        });
    },
    preventNav(event) {
      event.preventDefault();
      event.returnValue = "";
    },
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
    dataChanged(value) {
      this.isUploadingImages = false;
      if (typeof value === "string" && value === "loading") {
        this.isUploadingImages = true;
      }
      if (typeof value === "string") {
        this.valid = false;
      } else {
        this.activityInfo = { ...this.activityInfo, ...value };
        this.valid = true;
      }
    },
    validateForm() {
      if (this.valid) {
        this.progress += 1;
        this.valid = false;
      }
    },
    onFormSubmit() {
      this.isUploadingImages = true;
      if (!this.formHasChanged) return
      if (this.isEdit) {
        // edit tour
        db.firestore()
          .collection("tours")
          .doc(this.$route.params.id)
          .update(this.activityInfo)
          .then(() => {
            if (this.activityInfo.price > 0) this.editProductInStripe();
            this.updateActivityData({ activityIndex: this.activityIndex, activityData: this.activityInfo })
          })
          .catch((error) => {
            console.log(error);
          });
        return
      }

      //create tour
      db.firestore()
        .collection("tours")
        .add(this.activityInfo)
        .then((data) => {
          if(this.activityInfo>0) this.createProductInStripe(data.id);
          window.removeEventListener("beforeunload", this.preventNav);
          this.tourCreated = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async editProductInStripe() {
      window.removeEventListener("beforeunload", this.preventNav);
      let data = {
        productId: this.$route.params.id,
        description: this.activityInfo.description,
        name: this.activityInfo.title,
        image: [this.activityInfo.coverImage],
        priceId: this.activityInfo.priceLink,
        //price: this.activityInfo.price
        price: this.activityInfo.privatePrice - this.activityInfo.guiderPayment
      };
      await fetch(`${process.env.VUE_APP_API_URL}/stripe/product`, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            db.firestore()
              .collection("tours")
              .doc(this.$route.params.id)
              .update({ priceLink: data.message.id })
              .then(() => {
                this.editSharedProductInStripe()
              })
              .catch(() => {
                this.isUploadingImages = false;
                this.$buefy.toast.open({
                  duration: 10000,
                  message:
                    "There has been an error updating the activity. Try again later.",
                  position: "is-bottom",
                  type: "is-danger",
                });
              });
          }
        })
        .catch((error) => {
          this.isUploadingImages = false;
          this.$buefy.toast.open({
            duration: 10000,
            message: error.message,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },

    async editSharedProductInStripe() {
      window.removeEventListener("beforeunload", this.preventNav);
      let data = {
        productId: this.$route.params.id,
        description: this.activityInfo.description,
        name: this.activityInfo.title,
        image: [this.activityInfo.coverImage],
        priceId: this.activityInfo.sharedPriceLink,
        price: 10, //TODO: GET A DYNAMIC PRICE
      };

      await fetch(`${process.env.VUE_APP_API_URL}/stripe/product`, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            db.firestore()
              .collection("tours")
              .doc(this.$route.params.id)
              .update({ sharedPriceLink: data.message.id })
              .then(() => {
                this.isUploadingImages = false;
                this.$buefy.toast.open({
                  duration: 10000,
                  message: "Activity updated!",
                  position: "is-bottom",
                });
                this.$router.push({ name: "manage-activities" });
              })
              .catch(() => {
                this.isUploadingImages = false;
                this.$buefy.toast.open({
                  duration: 10000,
                  message:
                    "There has been an error updating the activity. Try again later.",
                  position: "is-bottom",
                  type: "is-danger",
                });
              });
          }
        })
        .catch((error) => {
          this.isUploadingImages = false;
          this.$buefy.toast.open({
            duration: 10000,
            message: error.message,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    async createProductInStripe(productId) {
      window.removeEventListener("beforeunload", this.preventNav);
      let data = {
        id: productId,
        name: this.activityInfo.title,
        description: this.activityInfo.description,
        //price: this.activityInfo.price,
        price: this.activityInfo.privatePrice - this.activityInfo.guiderPayment,
        image: [this.activityInfo.coverImage],
      };

      await fetch(`${process.env.VUE_APP_API_URL}/stripe/create`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            db.firestore()
              .collection("tours")
              .doc(productId)
              .update({ priceLink: data.message.id })
              .then(() => {
                this.createSharedProductInStripe()
              })
              .catch(() => {
                this.isUploadingImages = false;
                this.$buefy.toast.open({
                  duration: 10000,
                  message:
                    "There has been an error creating the activity. Try again later.",
                  position: "is-bottom",
                  type: "is-danger",
                });
              });
          }
        })
        .catch((error) => {
          this.isUploadingImages = false;
          this.$buefy.toast.open({
            duration: 10000,
            message: error.message,
            position: "is-bottom",
            type: "is-danger",
          });
          return true;
        });
    },
    async createSharedProductInStripe(productId) {
      window.removeEventListener("beforeunload", this.preventNav);
      let data = {
        id: productId,
        name: this.activityInfo.title,
        description: this.activityInfo.description,
        price: 10, //todo: GET A DYNAMIC PRICE
        image: [this.activityInfo.coverImage],
      };

      await fetch(`${process.env.VUE_APP_API_URL}/stripe/create`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            db.firestore()
              .collection("tours")
              .doc(productId)
              .update({ sharedPriceLink: data.message.id })
              .then(() => {
                this.isUploadingImages = false;
                this.$buefy.toast.open({
                  duration: 10000,
                  message: "Activity created!",
                  position: "is-bottom",
                });
                this.$router.push({ name: "manage-activities" });
              })
              .catch(() => {
                this.isUploadingImages = false;
                this.$buefy.toast.open({
                  duration: 10000,
                  message:
                    "There has been an error creating the activity. Try again later.",
                  position: "is-bottom",
                  type: "is-danger",
                });
              });
          }
        })
        .catch((error) => {
          this.isUploadingImages = false;
          this.$buefy.toast.open({
            duration: 10000,
            message: error.message,
            position: "is-bottom",
            type: "is-danger",
          });
          return true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .buttons .button:last-of-type {
    margin-right: 0;
  }

  .valid {
    background-color: #0e3d4d;
    color: #fff;
  }

  .submit-button {
    float: right;
    margin-bottom: 60px;
  }

  .buttons {
    margin-top: 24px;
    justify-content: flex-end;
  }

  .progress.is-small,
  .is-small.progress-wrapper.is-not-native {
    height: 0.35rem;
    margin-bottom: 24px;
  }

  .progress,
  .progress-wrapper.is-not-native {
    border-radius: 5px;
  }

  .button.is-text:hover,
  .button.is-text.is-hovered,
  .button.is-text:focus,
  .button.is-text.is-focused {
    background-color: none;
  }

  .invalid {
    background-color: #ededed;
    color: #0e3d4d;
    cursor: not-allowed;
  }

  .label {
    font-weight: 500;
    margin-bottom: 0.75rem !important;
  }
}

.info-block {
  margin-top: 25vh
}

.title {
  margin-top: 15px;
}

.verification-message {
  margin-top: 28vh;
  text-align-last: center;
}

.verification-message a {
  margin-top: 12px;
  text-decoration: underline;
  color: #0e3d4d80;
}

.upload {
  width: 100%;
}

.field:not(:last-child) {
  margin-bottom: 1.75rem;
}

@media only screen and (max-width: 768px) {
  div.notification {
    background-color: transparent;
    padding: 0px;
    border: none;
  }
}
</style>
