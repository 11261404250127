<template>
    <section class="aip-container mb-5" v-if="activityInProgress?.activityData">
        <b class="act-title"> <b-icon pack="fas" icon="stopwatch" class="mr-1"></b-icon>Activity in progress</b>
        <div class="activity-info">
            <p class="pb-2 mt-5"><b class="is-blue">Activity name:</b> {{ activityInProgress?.activityData?.title }} </p>
            <p><b class="is-blue">Started at:</b> {{ getHourHumanized(activityInProgress?.startTime) }}</p>
            <div v-if="activityInProgress?.activityData?.stops && activityInProgress?.activityData?.stops.length">
                <b-collapse class="card" animation="slide" aria-id="contentIdForA11y3" :open="isItineraryOpen == 0"
                    @open="isItineraryOpen = 0">
                    <template #trigger="props">
                        <div class="card-header" role="button" aria-controls="contentIdForA11y3"
                            :aria-expanded="props.open">
                            <p class="card-header-title">
                                Itinerary
                            </p>
                            <a class="card-header-icon" v-show="props.open">
                                <b-icon icon="chevron-up"></b-icon>
                            </a>
                            <a class="card-header-icon" v-show="!props.open">
                                <b-icon icon="chevron-down"></b-icon>
                            </a>
                        </div>
                    </template>

                    <div class="card-content">
                        <div class="content">
                            <div class="rightbox">
                                <div class="rb-container">
                                    <ul class="rb">
                                        <li class="rb-item" ng-repeat="itembx"
                                            v-for="(stop, index) in activityInProgress?.activityData?.stops" :key="index">
                                            <div class="item-title mb-2">{{ stop.title }}</div>
                                            <div class="item-subtitle">{{ stop.description }}</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-collapse>
            </div>
        </div>



        <b-button expanded class="mt-5 g--primary-btn" type="is-primary" @click="addFinishedActivitiesDataToDB">
            🎉 Finish activity
        </b-button>
    </section>
</template>

<script>

import { mapMutations, mapActions, mapState } from "vuex";
import { GuidersStates, GuidersMutations, UsersStates, ActivitiesActions, ActivitiesStates, GuiderActions } from '../../store/Storetypes'
import { ActivityMixin, UserMixin, GuidersMixin } from "../../mixins";
import { db, /* FieldValue */ } from '../../firebase';
import { getHourHumanized } from "../../helpers";
export default {
    data() {
        return {
            isOpen: true,
            isItineraryOpen: -1,
            activityInProgress: {},
            getHourHumanized,
            activityInProgressIndex: undefined
        }
    },
    mixins: [ActivityMixin, UserMixin, GuidersMixin],
    methods: {
        ...mapMutations({
            setActivityInProgress: GuidersMutations.SET_ACTIVITY_IN_PROGRESS_DATA,
            setFinishedActivities: GuidersMutations.SET_FINISHED_ACTIVITIES
        }),
        ...mapActions({
            getGuiderFinishedActivities: GuiderActions.GET_FINISHED_ACTIVITIES,
            getActivityData: ActivitiesActions.GET_ACTIVITY_DATA
        }),
        async addFinishedActivitiesDataToDB() {
            const docId = this.isEmployee ? this.user?.companyId : this.user?.uid
            let finishedActivities = this.guiderFinishedActivities
            finishedActivities[this.activityInProgressIndex] = {
                ...finishedActivities[this.activityInProgressIndex],
                activityInProgress: false,
                endTime: new Date().toISOString()
            }
            db.firestore()
                .collection("finishedActivities")
                .doc(docId)
                .update({ tours: finishedActivities })
                .then(async () => {
                    /* const updatedFinishedActivities = [
                        ...this.guiderFinishedActivities,
                        finishedActivityData
                    ] */
                    this.setFinishedActivities(finishedActivities)
                    this.activityInProgress = {}
                    this.setActivityInProgress({})
                    await this.getGuiderFinishedActivities(docId)
                    this.$buefy.toast.open({
                        duration: 10000,
                        message: "The activity has been completed.",
                        position: "is-bottom",
                        type: "is-success",
                    });
                })
                .catch(() => {
                    this.$buefy.toast.open({
                        duration: 10000,
                        message:
                            "There has been an error finishing your tour. Try again later, if this error persists please contact support.",
                        position: "is-bottom",
                        type: "is-danger",
                    });
                });

        },
        async getActivityInProgressData() {
            this.activityInProgressIndex = this.guiderFinishedActivities.findIndex((activity) => activity.employeeId === this.user?.uid && activity.activityInProgress)
            if (this.activityInProgressIndex < 0) return

            const activityInProgress = this.guiderFinishedActivities[this.activityInProgressIndex]

            const activityData = await this.getActivityData(activityInProgress?.activityId)
            this.activityInProgress = {
                ...activityInProgress,
                activityData
            }
        }
    },
    computed: {
        ...mapState({
            user: state => state.users[UsersStates.USER],
            isGuider: state => state.users[UsersStates.IS_USER_A_GUIDER],
            isEmployee: state => state.users[UsersStates.IS_EMPLOYEE],
            guiderActivities: state => state.guiders[GuidersStates.GUIDER_ACTIVITIES],
            // activityInProgress: state => state.guiders[GuidersStates.ACTIVITY_IN_PROGRESS_DATA],
            isCreatingStripeLink: state => state.guiders[GuidersStates.IS_CREATING_STRIPE_ACCOUNT_LINK],
            guiderFinishedActivities: state => state.guiders[GuidersStates.FINISHED_ACTIVITIES],
            selectedActivity: state => state.activities[ActivitiesStates.SELECTED_ACTIVITY_DATA],
        }),
    },
    async mounted() {
        const docId = this.isEmployee ? this.user?.companyId : this.user?.uid
        await this.getGuiderFinishedActivities(docId)
        this.getActivityInProgressData()
    }
}
</script>

<style lang="scss" scoped>
.aip-container {
    height: 100%;
    padding: 1.4rem 1rem;
    color: #1B035B;
    display: flex;
    border-radius: 10px;
    border: 2px solid #0e3d4d;
    box-shadow: 5px 5px 16px rgba(27, 3, 91, 0.04);
    align-items: center;
    flex-direction: column;
}

.act-title {
    background-color: #EEFBC6;
    padding: 4px 15px;
    border-radius: 6px;
}

.card-header {
    box-shadow: none;
}

.card-header-title {
    padding: 0;
}

.collapse-trigger {
    padding: 12px 0px !important;
}

.collapse .collapse-trigger .icon {
    transform: translateY(4px);
}

.collapse-content {
    margin-top: 12px;
}

.card-content {
    padding: 0;
}

// ITINERARY

.rb-container ul.rb li:last-child {
    padding-bottom: 0.5rem;
}

.rb-container {
    margin: 20px;
    display: block;
    position: relative;
}

.rb-container ul.rb {
    margin: 0;
    padding: 0;
    display: inline-block;
}

.rb-container ul.rb li {
    list-style: none;
    margin: auto;
    margin-left: 0;
    min-height: 50px;
    border-left: 1.5px dashed #0e3d4d;
    padding: 0 0 50px 30px;
    position: relative;
}

.rb-container ul.rb li:last-child {
    border-left: 0;
}

.rb-container ul.rb li::before {
    position: absolute;
    left: -10px;
    top: 0;
    content: " ";
    border: 8px solid #0e3d4d;
    border-radius: 500%;
    background: #fff;
    height: 20px;
    width: 20px;
    transition: all 500ms ease-in-out;
}

.rb-container ul.rb li:hover::before {
    border-color: #232931;
    transition: all 1000ms ease-in-out;
}

.item-title {
    font-weight: 600;
}

.item-title,
.item-subtitle {
    color: #0e3d4d;
}

::v-deep {
    .dropdown-content {
        background-color: white;
        border-radius: 4px;
        -webkit-box-shadow: none;
        box-shadow: none;
        padding-bottom: 0;
        padding-top: 0;
    }

    .card {
        background-color: hsl(0deg, 0%, 100%);
        border-radius: 0.25rem;
        box-shadow: none;
        color: hsl(0deg, 0%, 29%);
        max-width: 100%;
        position: relative;
    }
}
</style>