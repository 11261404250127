<template>
    <section>
        <GoBack @on-leave="resetActivityGroup" route-name-humanized="Start activity" />

        <ActivityInfoCard class="mt-5" :activity-info="selectedActivityData" :end-time="selectedActivityGroupData?.endTime"
            :time="selectedActivityGroupData?.time" :activity-group-info="selectedActivityGroupData"
            :date="selectedActivityGroupData.date" />

        <div class="display-flex-space-btw mt-5 mb-5 ">
            <p class="is-size-6 title">Activity group</p>
            <p class="is-size-8 is-light-purple">Number of participants: {{ totalParticipants }}</p>
        </div>

        <div v-if="!totalParticipants" class="container--info no-participants">
            <img class="no-participants--icon mb-4" src="../assets/info.png" />
            No participants added yet. Scan tickets to start adding participants.
        </div>

        <div v-else v-for="(group, index) in participantsBySubGroup" :key="index">
            <div class="container--info display-flex-space-btw">
                <span class="is-blue">{{ group[0].clientName }}</span>
                <span class="is-light-purple is-size-8">{{ group.length }} / {{ group[0].tickets.length }}</span>
            </div>
        </div>

        <div class="btn-group">
            <b-button class="g--primary-btn" type="is-primary" tag="router-link" :to="{ name: 'scan-ticket' }"
                icon-left="qrcode" expanded outlined>
                Scan tickets
            </b-button>
            <b-button class="mt-2 g--primary-btn" :loading="isStartingActivity" type="is-primary" expanded
                :disabled="!canStartActivity" @click="startActivity">
                🎉 Start activity
            </b-button>
        </div>
    </section>
</template>

<script>
import { db, FieldValue } from "../firebase";
import GoBack from '../components/elements/GoBack.vue'
import ActivityInfoCard from '../components/elements/ActivityInfoCard.vue'
import { mapState, mapMutations, mapActions } from "vuex";
import { GuidersStates, GuidersMutations, ActivitiesStates, ActivitiesMutations, UsersStates, UserActions } from '../store/Storetypes'
import { groupBy } from '../helpers';
import { TicketMixin, AffiliatesMixin } from "../mixins";

export default {
    components: {
        GoBack,
        ActivityInfoCard
    },
    mixins: [TicketMixin, AffiliatesMixin],
    data() {
        return {
            canStartActivity: false,
            activityId: null,
            activityGroupId: null,
            isStartingActivity: false
        }
    },
    created() {
        const docId = this.isEmployee ? this.user?.companyId : this.user?.uid
        this.getCompanyData(docId)
        const selectedActivityId = this.$route.params.activityId
        const selectedActivityGroupId = this.$route.params.activityGroupId
        if (!selectedActivityId) return
        this.activityId = selectedActivityId
        this.getSelectedActivityData(selectedActivityId)
        if (!selectedActivityGroupId) return
        this.activityGroupId = selectedActivityGroupId
    },
    computed: {
        ...mapState({
            user: state => state.users[UsersStates.USER],
            companyInfo: state => state.users[UsersStates.SELECTED_EMPLOYEE_DATA],
            isEmployee: state => state.users[UsersStates.IS_EMPLOYEE],
            isCompany: state => state.users[UsersStates.IS_COMPANY],
            groupParticipantsInfo: state => state.guiders[GuidersStates.ACTIVITY_PARTICIPANTS],
            selectedActivityData: state => state.activities[ActivitiesStates.SELECTED_ACTIVITY_DATA],
            activityGroupsData: state => state.activities[ActivitiesStates.ACTIVITY_GROUPS],
            isGuestGuider: state => state.guiders[GuidersStates.IS_GUEST_GUIDER],
            scannedTickets: state => state.guiders[GuidersStates.SCANNED_TICKETS],
            guiderFinishedActivities: state => state.guiders[GuidersStates.FINISHED_ACTIVITIES],

        }),
        participantsBySubGroup() {
            const participantsInfo = this.groupParticipantsInfo.map(({ participantInfo }) => participantInfo)
            const subGroups = groupBy(participantsInfo, 'participantId')
            if (Object.keys(subGroups).length) {
                this.setCanStartActivity(true)
            }

            return subGroups
        },
        totalParticipants() {
            let totalParticipants = 0
            if (!this.participantsBySubGroup) return totalParticipants
            Object.values(this.participantsBySubGroup).map((subGroup) => totalParticipants += subGroup.length)
            return totalParticipants
        },
        selectedActivityGroupData() {
            return this.activityGroupsData.find((activityGroup) => activityGroup.id === this.activityGroupId)
        }
    },
    methods: {
        ...mapMutations({
            resetActivityGroup: GuidersMutations.RESET_ACTIVITY_GROUP_CREATION,
            setActivityInProgressData: GuidersMutations.SET_ACTIVITY_IN_PROGRESS_DATA,
            getSelectedActivityData: ActivitiesMutations.GET_ACTIVITY_DATA
        }),
        ...mapActions({
            getCompanyData: UserActions.GET_EMPLOYEE_DATA,
        }),
        setCanStartActivity(canStartActivity) {
            this.canStartActivity = canStartActivity
        },
        createFinishedActivityDoc(companyId, activityInProgressData) {
            const companyInfo = this.isCompany ? 'user' : 'companyInfo'
            const data = {
                name: this[companyInfo].displayName || this[companyInfo].name,
                uid: this[companyInfo].uid,
                stripeAccountId: this[companyInfo].stripeAccountId || '',
                tours: [activityInProgressData]
            }
            db.firestore()
                .collection("finishedActivities")
                .doc(companyId)
                .set(data).then(()=> this.resetActivityGroup())
                .catch(() => {
                    this.$buefy.toast.open({
                        duration: 10000,
                        message: "There has been an error finishing your tour. Try again later, if this error persists please contact support.",
                        position: "is-bottom",
                        type: "is-danger",
                    });
                });
        },
        startActivity() {
            if (this.isGuestGuider) return
            let today = new Date();
            let h = today.getHours();
            let m = today.getMinutes();
            const activityInProgressData = {
                activityId: this.activityId,
                activityGroupId: this.activityGroupId,
                activityData: this.selectedActivityData,
                participantsInfo: this.participantsBySubGroup,
                startTime: new Date().toISOString(),
                startedAtHumanized: `${h}:${m}`,
                activityInProgress: true,
                employeeId: this.user?.uid
            }
            this.setActivityInProgressData(activityInProgressData)

            const finishedActivityData = {
                activityId: this.activityId,
                activityGroupId: this.activityGroupId,
                startTime: activityInProgressData.startTime,
                guiderHasBeenPayed: false,
                transferred: false,
                payAmount: activityInProgressData.activityData.guiderPayment,
                activityInProgress: true,
                employeeId: this.user?.uid,
                numOfScannedTickets: this.totalParticipants
            }
            this.addFinishedActivitiesDataToDB(finishedActivityData)
            this.startTour()
        },
        async addFinishedActivitiesDataToDB(activityInProgressData) {
            const docId = this.isEmployee ? this.user?.companyId : this.user?.uid

            const ref = db.firestore()
                .collection("finishedActivities")
                .doc(docId)
            const doc = await ref.get();

            if (!doc.exists) {
                // create
                this.createFinishedActivityDoc(docId, activityInProgressData)
                return
            }

            // update
            db.firestore()
                .collection("finishedActivities")
                .doc(docId)
                .update({ tours: FieldValue.arrayUnion(activityInProgressData) })
                .then(() => {
                    this.resetActivityGroup()
                })
                .catch(() => {
                    this.$buefy.toast.open({
                        duration: 10000,
                        message:
                            "There has been an error setting the activity in progress. Try again later, if this error persists please contact support.",
                        position: "is-bottom",
                        type: "is-danger",
                    });
                });

        },
        startTour() {
            this.isStartingActivity = true
            this.scannedTickets.map((ticket) => {
                const ticketInfo = this.getTicketData(ticket)
                const { groupId, participantId, ticketNumber } = ticketInfo
                if (!groupId || !participantId || !ticketNumber) {
                    this.notifyError("Couldn't start tour")
                    return
                }

                const participantInfo = this.getParticipantInfo({ groupId, participantId })

                if (!participantInfo) {
                    this.notifyError()
                    return
                }

                const updatedParticipants = [...this.savedGroupsList[groupId].participants]
                const participantToUpdateIndex = this.getParticipantIndex({ groupId, participantId })

                updatedParticipants[participantToUpdateIndex].tickets[ticketNumber] = {
                    ...updatedParticipants[participantToUpdateIndex].tickets[ticketNumber],
                    isTicketValid: false,
                    guiderUid: this.user.uid
                }
                // TODO: add affiliate commission to earned amount and pay them
                db.firestore()
                    .collection("groups")
                    .doc(groupId)
                    .update({ participants: updatedParticipants }).then(() => {
                        updatedParticipants.forEach((participant) => this.updateAffiliateCommission(participant.participantId))
                        this.isStartingActivity = false
                        if (this.isEmployee) {
                            this.$router
                                .push({
                                    name: 'guider-upcoming-activities',
                                })
                            return
                        }
                        if (this.isCompany) {
                            this.$router
                                .push({
                                    name: 'company-upcoming-activities',
                                })
                            return
                        }
                    })
                    .catch((err) => {
                        this.isStartingActivity = false
                        this.$buefy.toast.open({
                            duration: 10000,
                            message: err.message,
                            position: "is-bottom",
                            type: "is-danger",
                        });
                    });
            })
        },
        async updateAffiliateCommission(participantId) {
            if (!participantId) return
            await fetch(`${process.env.VUE_APP_API_URL}/stripe/paymentIntent/pi_${participantId}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "same-origin",
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        const { affiliateId, commission } = data?.message?.metadata
                        if (!affiliateId || !commission) return
                        this.updateAffiliateApprovedCommission({ affiliateId, commission: Number(commission) })
                    } else {
                        console.log(data.message);
                    }
                })
                .catch((error) => {
                    this.isStartingActivity = false
                    this.$buefy.toast.open({
                        duration: 10000,
                        message: error,
                        position: "is-bottom",
                        type: "is-danger",
                    });
                });
        }
    }
};
</script>


<style lang="scss" scoped>
.button.is-outlined {
    border: 2px solid;
}

.btn-group {
    bottom: 0;
    position: fixed;
    width: 100%;
    padding-right: 40px;
    margin-bottom: 20px;
}

.no-participants {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &--icon {
        height: 3rem;
        width: fit-content;
    }
}

.title:not(:last-child) {
    margin-bottom: 0;
}
</style>