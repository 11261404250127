<template>
  <section>
    <div class="app">
      <navbar
        :isSignedIn="isSignedIn"
        :class="{ 'is-invisible navbar--hidden': hideNavbar }"
      />
      <router-view class="app-page" />
      
      <cursor-fx />

    </div>

    <footer class="footer" :class="{ 'is-hidden': hideFooter }" v-if="loaded">
      <div class="columns">
        <div class="column">
          <p class="mb-4">
            <strong class="title is-6 has-text-weight-bold">Company</strong>
          </p>
          <p>
            <router-link class="option" to="/concierge">
              <!-- <img src="./assets/bell--dark.png" class="mr-2 icon--sm" /> -->Travel
              Concierge
            </router-link>
          </p>
          <p>
            <a
              class="option"
              href="https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/documents%2FAboutUs.pdf?alt=media&token=9bf2d5e0-ec0b-4d4b-9aa0-b2e5943ab1c4"
              target="_blank"
              >About us</a
            >
          </p>
          <!-- <p>
            <a
              class="option"
              href="https://www.guiders.pt/frequently-asked-questions"
              >Support</a
            >
          </p> -->
          <a href="https://www.guiders.pt/guide/registration" class="blue-container">
           
            <p>Become a guider</p>
            <b-icon icon="arrow-right" class="ml-2"></b-icon>
          </a>

         <!--  <a
            :to="{ name: 'affiliates' }"
            href="https://www.guiders.pt/affiliates"
            class="blue-container"
          >
            <b-icon icon-right="arrow-right" class="mr-2"></b-icon>
            <p>Become an affiliate</p>
          </a> -->
        </div>

        <div class="column">
          <div class="mb-5">
            <p class="mb-4">
              <strong class="title is-6 has-text-weight-bold"
                >Tour packages</strong
              >
            </p>
            <div>
              <div class="display-flex mb-2">
                <router-link class="option" :to="{ name: 'full-day-tours' }"
                  >Day tours</router-link
                >
              </div>
              <div class="display-flex mb-2">
                <router-link class="option" :to="{ name: 'tuk-tuk-tours' }"
                  >Tuk Tuk Tours</router-link
                >
              </div>
              <div class="display-flex mb-2">
                <router-link class="option" :to="{ name: 'cultural-tours' }"
                  >Cultural Tours</router-link
                >
              </div>
              <div class="display-flex mb-2">
                <router-link class="option" :to="{ name: 'historical-tours' }"
                  >Historical Tours</router-link
                >
              </div>
              <div class="display-flex">
                <router-link class="option" :to="{ name: 'quote' }"
                  >Customize your trip</router-link
                >
              </div>
            </div>
          </div>
        </div>

        <div class="column">
          <div class="mb-5">
            <p class="mb-4">
              <strong class="title is-6 has-text-weight-bold"
                >Contact us</strong
              >
            </p>

            <div>
              <div class="display-flex mb-2">
                <a class="link mb-1 option" href="mailto:support@guiders.pt"
                  >support@guiders.pt</a
                >
              </div>

              <div class="display-flex">
                <a class="link option" href="https://wa.me/351920043375"
                  >(+351) 920 043 375
                </a>
              </div>
            </div>
          </div>
          <!--  <p class="mt-6 mb-4"><strong class="title is-6 has-text-weight-bold">Follow us</strong></p>

          <div class="social--media is-flex is-align-items-center">
             <a href="https://www.facebook.com/guiders.pt" target="_blank" class="mr-2"><img class="social-icon"
                src="./assets/fb-circle.svg" /> </a> 
            <a href="https://www.instagram.com/guiders.pt/" target="_blank" class="mr-2"><img class="social-icon"
                src="./assets/insta-circle.svg" /></a>

            <a href="https://www.tiktok.com/@guiders.pt" target="_blank" class="mr-2"><img class="social-icon"
                src="./assets/tiktok-circle.svg" /></a>
              <a href='https://wa.me/351920043375' target="_blank"><img class="social-icon"
                  src="./assets/wp-circle.svg" /></a> 
          </div> -->
        </div>

        <!--   <div class="column">
          <p class="mb-4"><strong class="title is-6 has-text-weight-bold">Resources</strong></p>
              <a :to="{ name: 'affiliate-dashboard' }"  class="option" href="https://www.guiders.pt/dashboard/affiliate">
                Affiliate Hub
              </a>
        </div> -->

        <div class="column">
          <!-- <p class="mb-4"><strong class="title is-6 has-text-weight-bold">Legal</strong></p>
          <p><a class="option"
              href="https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/documents%2FContracts.pdf?alt=media&token=adb6898d-d0c6-41bc-be5d-1e49740c99ba"
              target="_blank">Contracts</a></p>
          <p><a class="option"
              href="https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/documents%2FPrivacy%20policy.pdf?alt=media&token=e6925517-73ae-4405-bc01-feaeb8e1526d"
              target="_blank">Privacy Policy</a></p> -->

          <p class="mb-4">
            <strong class="title is-6 has-text-weight-bold"
              >Payment methods</strong
            >
          </p>

          <div class="payment-icons mt-4 mb-5">
            <img src="./assets/Visa.png" />
            <img src="./assets/Mastercard.png" />
            <img src="./assets/UnionPay.png" />
            <img src="./assets/Discover.png" />
            <img src="./assets/DinersClub.png" />
            <img src="./assets/Amex.png" />
            <img src="./assets/JCB.png" />
            <img src="./assets/Bancontact.png" />
            <img src="./assets/GooglePay.png" />
            <img src="./assets/ApplePay.png" />
            <img src="./assets/Alipay.png" />
            <img src="./assets/Giropay.png" />
            <img src="./assets/Ideal.png" />
            <img src="./assets/Klarna.png" />
          </div>
        </div>
      </div>

      <div class="green-container mb-2 mt-5">
        <div class="is-flex is-align-items-center mb-4">
          <a
            class="option mr-2"
            href="https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/documents%2FContracts.pdf?alt=media&token=adb6898d-d0c6-41bc-be5d-1e49740c99ba"
            target="_blank"
            >Contracts</a
          >
          •
          <a
            class="option mr-2 ml-2"
            href="https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/documents%2FPrivacy%20policy.pdf?alt=media&token=e6925517-73ae-4405-bc01-feaeb8e1526d"
            target="_blank"
            >Privacy Policy</a
          >
          •
            <a class="option mr-2 ml-2" href="https://www.guiders.pt/frequently-asked-questions">Support</a>
          
        </div>

        <!--   <img src="./assets/logo.png"/> -->
        <div
          class="is-flex is-justify-content-space-between mb-2 is-align-items-center"
        >
          <a
            href="https://www.visitportugal.com/pt-pt/content/guiders"
            target="_blank"
          >
            <img src="./assets/turismo_portugal.jpeg" />
          </a>

          <div class="social--media is-flex is-align-items-center">
            <a href="https://www.facebook.com/guiders.pt" target="_blank"
              ><img class="social-icon" src="./assets/fb-circle.svg" />
            </a>
            <a href="https://www.tiktok.com/@guiders.pt" target="_blank"
              ><img class="social-icon" src="./assets/tiktok-circle.svg"
            /></a>
            <a href="https://www.instagram.com/guiders.pt/" target="_blank"
              ><img class="social-icon" src="./assets/insta-circle.svg"
            /></a>
            <a href="https://wa.me/351920043375" target="_blank"
              ><img class="social-icon" src="./assets/wp-circle.svg"
            /></a>
          </div>
        </div>

        @2024 Guiders | RNAAT: 61/2023
      </div>
    </footer>
  </section>
</template>

<script>
import navbar from "./components/elements/navbar";
import "buefy/dist/buefy.css";
import { mapState, mapActions } from "vuex";
import "../node_modules/@fortawesome/fontawesome-free/js/all.js";
import {
  routesToHideFooter,
  routesToHideFooterAndNavBar,
} from "./enums/RouteNames";
import { UserActions, UsersStates } from "./store/Storetypes";
import AOS from 'aos';
import 'aos/dist/aos.css';
export default {
  name: "App",
  metaInfo() {
    return {
      title:
        "Guiders.pt | Tours and Experiences, Private Guides and Personalized itineraries",
      meta: [
        { property: "og:type", content: "website" },
        // Should the the same as your canonical link, see below.
        { property: "og:url", content: "https://guiders.pt" },
        {
          name: "keywords",
          content:
            "lisbon tours, excursiones en lisboa, tour por lisboa, tuk tuk tour lisbon, sintra tour, tuk tuk lisboa, lisboa tours, tuk tuk lisbon, lisbon tuk tuk, guide lisbon, guides lisbon, private guide portugal, private guide lisbon, guia privado lisboa, guia privado lisboa,tuk tuk lisboa, tuk tuk lisboa preço,tuk tuk tours lisboa, passeio tuk tuk lisboa, passeio de tuk tuk em lisboa, tuk tuk em lisboa, tuk tuk tour lisbon, tuk tuk lisbon, lisbon tuk tuk, best tuk tuk tour lisbon, tuk tuk tours in lisbon, private tuk tuk tour lisbon, tuk tuk lisbon tour, tuk tuk in lisbon , 4 hour tuk tuk tour lisbon lisbon tours tuk tuk, tuk tuk tour of lisbon, tuk tuk lisboa precios, tour tuk tuk lisboa, tuktuk tour lissabon, tuk tuk en lisboa",
        },

        // Add other meta tags as needed
      ],
    };
  },
  /* metaInfo: {
    htmlAttrs: {
      lang: 'en-US'
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: 'Book shared and private tours and activities for budget-friendly prices' },
      { name: "keywords", content: "tuk,lisbon,lisboa,eco-tuk,tuk_tuk,travel,budget-friendly,tour,guiders,guide,portugal,guiders,guiders.pt, Last-Minute Travel Deal, Solo Traveler Discounts, Adventure Travel Packages, Budget Travel Tips,Group Travel,Tuk-Tuk Tours in Lisbon, Explore Lisbon by Tuk-Tuk,Guided Tuk-Tuk Adventures, Unique Tuk-Tuk Experiences, Scenic Tuk-Tuk Rides, Sightseeing Tuk-Tuk Tours,Local Tuk-Tuk Guides,Eco-Friendly Tuk-Tuk Tours, Tuk-Tuk Tours, Historical Tuk-Tuk Sightseeing, Group Tuk-Tuk Tours,Tuk-Tuk Ride Discounts,Book Tuk-Tuk Tours Online,Best Tuk-Tuk Tour Packages, Guides in Portugal, Guides Portugal, Guides From Portugal, Guiders Portugal, Guiders Lisbon, Guides Lisbon, Guides From Lisbon" },

      // OpenGraph data (Most widely used)
      { property: 'og:title', content: 'Guiders.pt | Book shared and private tours and activities in Lisbon' },
      { property: 'og:guiders', content: 'Guiders.pt | Discover Lisbon with shared and private tours and activities.' },
      // The list of types is available here: http://ogp.me/#types
      { property: 'og:type', content: 'website' },
      // Should the the same as your canonical link, see below.
      { property: 'og:url', content: 'https://guiders.pt' },
      // Often the same as your meta description, but not always.
      { property: 'og:description', content: 'Book shared and private tours and activities for budget-friendly prices' },

      // Google / Schema.org markup:
      { itemprop: 'name', content: 'Guiders.pt | Discover Lisbon with shared and private tours and activities.' },
      { itemprop: 'description', content: 'Book shared and private tours and activities for budget-friendly prices' }
    ]
  }, */
  jsonld() {
    return {
      "@context": "https://schema.org",
      "@type": "WebPage",
      url: "https://guiders.pt/",
      logo: "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/Group%2038.png?alt=media&token=998a135f-69a5-4385-83a5-9269b08a5e81",
    };
  },
  components: {
    navbar,
  },
  data() {
    return {
      loaded: false,
      isSignedIn: null,
      routesToHideFooter,
      routesToHideFooterAndNavBar,
    };
  },

  updated() {
    if (!this.user) {
      this.isSignedIn = true;
      return;
    }
    this.isSignedIn = false;
  },
  created() {
    // this.authenticateUser();
    if (!this.user) {
      this.isSignedIn = true;
      return;
    }
    this.isSignedIn = false;

    AOS.init({
  // Global settings:
  disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
  initClassName: 'aos-init', // class applied after initialization
  animatedClassName: 'aos-animate', // class applied on animation
  useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
  disableMutationObserver: false, // disables automatic mutations' detections (advanced)
  //debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
  //throttleDelay: 20, // the delay on throttle used while scrolling the page (advanced)
  

  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  //offset: 120, // offset (in px) from the original trigger point
  delay: 0, // values from 0 to 3000, with step 50ms
 // duration: 400, // values from 0 to 3000, with step 50ms
  easing: 'ease', // default easing for AOS animations
  once: false, // whether animation should happen only once - while scrolling down
  mirror: false, // whether elements should animate out while scrolling past them
 // anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation

});
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  watch: {
    $route() {
      this.setLoaded(false);
      if (
        routesToHideFooter.includes(this.$route.name) ||
        routesToHideFooterAndNavBar.includes(this.$route.name)
      ) {
        this.setLoaded(true);
      }
      this.setLoaded(true);
    },
  },
  computed: {
    // ...mapGetters(["getUser", "isUserAuth"]),
    ...mapState({
      user: (state) => state.users[UsersStates.USER],
    }),
    hideFooter() {
      if (
        routesToHideFooter.includes(this.$route.name) ||
        routesToHideFooterAndNavBar.includes(this.$route.name)
      ) {
        return true;
      }
      return false;
    },
    hideNavbar() {
      if (routesToHideFooterAndNavBar.includes(this.$route.name)) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      authenticateUser: UserActions.AUTHENTICATE_USER,
    }),
    setLoaded(value) {
      this.loaded = value;
    },
  },
};
</script>

<style lang="scss">
@import "./styles/index";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@300;400&family=Inter:wght@300;400;500;600&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Tenor+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300..700;1,300..700&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

.green-container {
  color: #0e3d4d;
  padding: 8px 1.5rem;
  border-radius: 0 20px 0 0px;
  margin: -1.5rem;
  margin-top: 1rem;
  font-size: 0.85rem;
  display: flex;
  flex-direction: column;
  img {
    height: 4.5rem;
    width: max-content;
  }
}

body {
  margin: 0;
}

.trip-advisor {
  height: 3.5rem;
}

.stripe-badge--img {
  height: 2rem;
}
.icon--sm {
  height: 1rem;
}

.is-green {
  color: #61ce66;
}

.blue-container {
  padding: 8px 22px 0 22px;
  margin: 12px 0;
  background-color: rgb(249 251 251);
  color: #0e3d4d;
  border-radius: 22px;
  width: fit-content;
  font-weight: 600;
  display: flex;
  /* &:hover {
    color: #fff;
  }*/
}

.link {
  color: #0e3d4d;
}

.wavy-underline {
  text-decoration: underline wavy;
  text-decoration-color: #5995ed;
}

.stripe-badge {
  max-height: 9rem;
}

.app {
  display: inline;
}

.app-page:not(.full-screen) {
  // padding: 7rem 0 20px;
  padding: 1rem 20px;
}

body.has-navbar-fixed-top:has(.navbar--hidden) {
  padding-top: 20px;
}

body.has-navbar-fixed-top:has(.navbar--hidden):has(.full-screen) {
  padding-top: 0;
}

/*
.app:not(:has(.navbar--hidden)){
  .app-page {
    padding-top: 2rem;
  }
}*/


/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #0e3d4d;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #0e3d4d94;
}

::v-deep {
  @import "~bulma/sass/utilities/_all";

  $primary: #8c67ef;
  $primary-light: findLightColor($primary);
  $primary-dark: findDarkColor($primary);
  $primary-invert: findColorInvert($primary);

  $modal-card-head-background-color: white;
  $modal-card-body-background-color: white !important;

  // Links
  $link: $primary;
  $link-invert: $primary-invert;
  $link-focus-border: $primary;
  // Setup $colors to use as bulma classes (e.g. 'is-twitter')
  $colors: mergeColorMaps(
    (
      "primary": (
        $primary,
        $primary-invert,
        $primary-light,
        $primary-dark,
      ),
      "link": (
        $link,
        $link-invert,
        $link-light,
        $link-dark,
      ),
    ),
    $custom-colors
  );

  $checkbox-background-color: #f8f6ff;
  $checkbox-border-color: transparent;

  // Import Bulma and Buefy styles
  @import "~bulma";
  @import "~buefy/src/scss/buefy";
}

.app:has(.full-screen) {
  padding: 0;
  margin-bottom: 0;
}

.button {
  border-color: #edebf2;
}

@media screen and (min-width: 769px), print {
  .footer .columns:not(.is-desktop) {
    display: flex;
    align-items: flex-start;
    gap: 2rem;
  }
}

::v-deep .loading-overlay .loading-background {
  background: $primary;
  background: white;
}

img {
  object-fit: cover;
}

body {
  color: $primary;
}

.control.has-icons-left .icon,
.control.has-icons-right .icon {
  color: #dbdbdb;
  height: 3em;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 3.5em;
  z-index: 4;
  font-size: 13px;
}

.payment-icons {
  display: table-footer-group;
}

.payment-icons img {
  height: 25px;
  width: auto;
  object-fit: contain;
  margin-right: 12px;
  margin-bottom: 6px;
}

.social--media {
  display: table-footer-group;
  div {
    font-weight: 600;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    border-radius: 20px;
    padding: 0px 9px;
    background-color: #167df014;
  }

  a {
    display: flex;
    height: 36px;
    width: 36px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .social-icon {
    height: 20px;
    width: 20px;
  }
}

.footer {
  padding: 2rem 0.5rem 0 0.5rem;
  margin: 0 1rem;
  //border-radius: 20px 20px 0 0;
  background-color: white;
  border-top: 0.5px solid #dbdfe7;
  .logo {
    height: 2rem;
  }
  .title {
    font-size: 1rem;
  }

  .option,
  .title {
    font-size: 1rem;
    font-family: "Inter", sans-serif;
  }
}

html {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.footer .content {
  margin-top: 48px;
}

.navbar-menu {
  background-color: white;
  box-shadow: none;
  padding: 0;
}

.navbar-item,
.navbar-link {
  font-size: 16px;
  padding: 0.5rem 0.7rem;
}

.navbar-menu {
  align-items: flex-start !important;
  padding: 20px 51px 8px 40px;
}

.delete::before,
.modal-close::before,
.delete::after,
.modal-close::after {
  background-color: $primary;
}

.delete::after,
.modal-close::after {
  height: 50%;
}

.delete::before,
.modal-close::before {
  width: 50%;
}

.navbar-burger:hover {
  background-color: transparent;
}

.navbar-burger span {
  height: 3px;
  color: $primary;
  width: 25px;
  border-radius: 2px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.navbar-burger.is-active span:nth-child(3) {
  transform: translateY(-9px) rotate(316deg);
}

.navbar-burger span:nth-child(1) {
  top: calc(50% - 9px);
}

.navbar-burger span:nth-child(2) {
  top: calc(50% - 2px);
}

.navbar-burger span:nth-child(3) {
  top: calc(50% + 5px);
}

.footer p {
  margin-bottom: 8px;
}

.app {
  font-family: "Inter", sans-serif;
  margin-bottom: 90px;
  padding: 60px;
  min-height: 100vh;
  color: $primary;
}

.input:focus,
.textarea:focus,
.taginput .taginput-container.is-focusable:focus,
.select select:focus,
.is-focused.input,
.is-focused.textarea,
.taginput .is-focused.taginput-container.is-focusable,
.select select.is-focused,
.input:active,
.textarea:active,
.taginput .taginput-container.is-focusable:active,
.select select:active,
.is-active.input,
.is-active.textarea,
.taginput .is-active.taginput-container.is-focusable,
.select select.is-active {
  box-shadow: none;
  background-color: transparent;
  border-color: #e2e9ec;
}

.navbar-brand,
.navbar-tabs {
  margin-left: 22px;
}

.input:hover,
.textarea:hover,
.taginput .taginput-container.is-focusable:hover,
.select select:hover,
.is-hovered.input,
.is-hovered.textarea,
.taginput .is-hovered.taginput-container.is-focusable,
.select select.is-hovered {
  border-color: #e2e9ec;
  background-color: transparent;
}

@media only screen and (max-width: 768px) {
  .navbar-menu {
    margin-bottom: 2px;
  }

  .navbar-brand,
  .navbar-tabs {
    margin: 0px 20px;
  }

  .app {
    margin-bottom: 90px;
    width: 100%;
    overflow: hidden;
    padding: 0 20px;
  }
}

@media screen and (max-width: 1023px) {
  .navbar.is-fixed-top .navbar-menu,
  .navbar.is-fixed-top-touch .navbar-menu {
    height: 100vh;
  }

  .navbar-menu {
    padding: 18px;
  }
}

div.container {
  max-width: 600px !important;
}

.select select,
.taginput .taginput-container.is-focusable,
.textarea,
.input {
  border-color: #e2e9ec;
  background-color: transparent;
  box-shadow: none;
  border-radius: 5px;
  color: #171138a6;
}

.columns {
  align-items: center;
  justify-content: space-between;
}

.modal-background {
  background-color: rgba(10, 10, 10, 0.75);
}

.datepicker .dropdown .input[readonly],
.datepicker .dropdown-trigger .input[readonly] {
  cursor: pointer;
  -webkit-box-shadow: none;
  box-shadow: none;
}
</style>
