import { mapState, mapMutations } from "vuex";
import { UsersStates, GuidersMutations, UsersMutations } from '../store/Storetypes'
import { db } from "../firebase";

export const GuidersMixin = {
    created() {
        this.listenForGuiderDataUpdates()
    },
    computed: {
        ...mapState({
            user: state => state.users[UsersStates.USER],
            isEmployee: state => state.users[UsersStates.IS_EMPLOYEE]
        }),
    },
    methods: {
        ...mapMutations({
            setIsCreatingStripeAccountLink: GuidersMutations.SET_IS_CREATING_STRIPE_ACCOUNT_LINK,
            updateUser: UsersMutations.SET_USER
        }),
        async listenForGuiderDataUpdates() {
            await db.firestore()
                .collection("guides")
                .where("uid", "==", this.user.uid).onSnapshot(docSnapshot => {
                    docSnapshot.docChanges().forEach((change) => {
                        if (change.type === 'added' ||
                            change.type === 'modified' ||
                            change.type === 'removed') {
                            this.updateUser(change.doc.data())
                        }
                    });
                }, err => {
                    console.log(`Encountered error: ${err}`);
                });
        },
        async goToStripeDashboard() {
            const data = {
                accountId: this.user?.stripeAccountId
            }

            await fetch(`${process.env.VUE_APP_API_URL}/stripe/dashboard/login`, {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "same-origin",
            })
                .then((response) => response.json()).then((data) => {
                    if (data.success) window.open(data.url, '_blank').focus();
                })
                .catch((error) => {
                    this.$buefy.toast.open({
                        duration: 10000,
                        message: error.message,
                        position: "is-bottom",
                        type: "is-danger",
                    });
                    return true;
                });
        },
        async setUpStripeAccount() {
            this.setIsCreatingStripeAccountLink(true)
            const data = {
                user: this.user, // remove user if we end up using this
                accountId: this.user?.stripeAccountId
            }

            await fetch(`${process.env.VUE_APP_API_URL}/stripe/account`, {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "same-origin",
            })
                .then((response) => response.json()).then((data) => {
                    this.setIsCreatingStripeAccountLink(false)
                    if (data.success) window.location.href = data.url;
                })
                .catch((error) => {
                    this.setIsCreatingStripeAccountLink(false)
                    this.$buefy.toast.open({
                        duration: 10000,
                        message: error.message,
                        position: "is-bottom",
                        type: "is-danger",
                    });
                    return true;
                });
        },
        async getStripeAccountInfo() {
            if (!this.user.stripeAccountId) return
            await fetch(`${process.env.VUE_APP_API_URL}/stripe/account/details`, {
                method: "POST",
                body: JSON.stringify({ accountId: this.user.stripeAccountId }),
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "same-origin",
            })
                .then((response) => response.json()).then((data) => {
                    const { account } = data
                    if (account && account.details_submitted && !this.user.hasFinishedSetUp) {
                        // this.getPersonInfo()
                        db.firestore().collection("guides")
                            .where("stripeAccountId", "==", account.id)
                            .get()
                            .then((querySnapshot) => {
                                querySnapshot.forEach(() => {
                                    this.updateGuiderData({
                                        uid: this.user.uid,
                                        userData: {
                                            hasFinishedSetUp: account.details_submitted
                                        },
                                    })
                                });
                            })
                    }
                })
                .catch((error) => {
                    this.$buefy.toast.open({
                        duration: 10000,
                        message: error.message,
                        position: "is-bottom",
                        type: "is-danger",
                    });
                    return true;
                });
        },
        updateGuiderData({ uid = this.user.uid, userData }) {
            db.firestore().collection("guides")
                .doc(uid)
                .update(userData)
                .catch((error) => {
                    this.$buefy.toast.open({
                        duration: 10000,
                        message: error,
                        position: "is-bottom",
                        type: "is-danger",
                    });
                });
        }
    }
}