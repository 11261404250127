import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
//import  VueI18n from "vue-i18n"
import VueCookies from "vue-cookies";

import { db } from "./firebase";
import './registerServiceWorker'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import VueJsonLD from 'vue-jsonld' 

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import '../node_modules/@fortawesome/fontawesome-free/js/all.js'
import VueMeta from 'vue-meta'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueSocialSharing from 'vue-social-sharing'
import CursorFx from '@luxdamore/vue-cursor-fx';
import '@luxdamore/vue-cursor-fx/dist/CursorFx.css';
    // Install
    Vue.use(
        CursorFx
    );

Vue.use(VueSocialSharing);
 
//const renderer = require('vue-server-renderer').createRenderer()


library.add(fas)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(Buefy, { defaultIconPack: 'fas' })
Vue.config.productionTip = false;
Vue.use(VueCookies);
Vue.use(VueMeta)
Vue.use(VueJsonLD)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAgZ3R7nwmd08kbQPr9LpTUATBMf8Dg0-o',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
 
    //// If you want to set the version, you can do so:
    // v: '3.26',
  },
 
  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,
 
  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
})


/* // import translations
import en from "./locales/en.json";

// configure i18n
const i18n = VueI18n.createI18n({
  locale: "en",
  fallbackLocale: "en",
  messages: { en },
});

Vue.use(i18n); */
let app;

db.auth().onAuthStateChanged(() => {
  if (!app) {
    new Vue({
      el: '#app',
      router,
      store,
     // i18n,
      render: (h) => h(App),
    })
  }
});

