export const BgColors = [
    '#009BFF',
    '#45C6FE',
    '#FBE68E',
    '#FB8655',
    '#46237A',
    '#BFB5FF',
    '#4ED0D7',
    '#29D7A2',
    '#FFD370',
    '#FF495C',
    '#9CEAAE',
    '#F66D42',
]