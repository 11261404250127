<template>
    <section>

        <p class="has-text-weight-bold mb-2 is-size-5">Payment history</p>

        <b-field grouped group-multiline class="field" :class="{ 'is-invisible': !checkedRows.length }">
            <b-button label="Clear selection" type="is-danger" icon-left="times" @click="checkedRows = []" class="mr-2" />
            <b-button label="Transfer" :loading="transferring" type="is-primary" :disabled="!checkedRows.length"
                @click="transferToGuider" />
        </b-field>

        <b-table class="pt-4" :data="computedFinishedActivities" ref="table" paginated per-page="5" detailed detail-key="id"
            detail-transition="fade" show-detail-icon aria-next-label="Next page" aria-previous-label="Previous page"
            aria-page-label="Page" aria-current-label="Current page" pagination-rounded :checked-rows.sync="checkedRows"
            checkbox-position="left" checkable :is-row-checkable="(row) => !row.transferred">

            <b-table-column field="companyName" label="Company name" sortable v-slot="props">
                <a @click="goToCompanyDetails(props.row.companyId)" class="link"> {{ props.row.companyName }} <b-icon
                        class="ml-1" icon="external-link-alt"></b-icon></a>
            </b-table-column>

            <b-table-column field="tourName" label="Tour name" sortable v-slot="props">
                {{ getActivityInfo(props.row.activityId).title }}
            </b-table-column>

            <b-table-column field="date" label="Date" v-slot="props">
                {{ getDate(props.row.startTime) }}
            </b-table-column>

            <b-table-column field="payAmount" label="Payment amount" sortable v-slot="props">
                {{ props.row.payAmount }} €
            </b-table-column>

            <b-table-column field="transferred" label="Payment status" sortable v-slot="props">
                <b-tag :type="getTransferStatus(props.row.transferred)"> <span class="is-capitalized">{{
                    getTransferStatus(props.row.transferred) }} </span></b-tag>
            </b-table-column>

            <template #detail="props">

                <p><b>Start time</b></p>
                {{ getHourHumanized(props.row.startTime) }}

                <p class="mt-2"><b>End time</b></p>
                {{ getHourHumanized(props.row.endTime) }}

                <p class="mt-2"><b>Number of participants</b></p>
                {{ props.row.numOfScannedTickets || 'Unknown' }}
            </template>

            <template #empty>
                <div class="has-text-centered">There are no activities finished yet</div>
            </template>
        </b-table>
    </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { formattedDate } from '../helpers';
import { GuiderActions, GuidersStates, GuidersMutations } from '../store/Storetypes'
import { ActivityMixin, HelpersMixin } from "../mixins";

export default {
    mixins: [ActivityMixin, HelpersMixin],
    data() {
        return {
            formattedDate,
            checkedRows: [],
            transferring: false
        }
    },
    created() {
        this.getFinishedActivities()
    },
    methods: {
        ...mapMutations({
            setGuiderFinishedActivities: GuidersMutations.SET_FINISHED_ACTIVITIES,
            setSelectedGuider: GuidersMutations.SET_SELECTED_GUIDER,
        }),
        ...mapActions({
            sendPayment: GuiderActions.PROCESS_GUIDER_PAYMENT,
            getFinishedActivities: GuiderActions.GET_FINISHED_ACTIVITIES
        }),
        copyTextToClipboard(text) {
            if (!text) return
            this.copyToClipboard(text)
        },
        getHourHumanized(dateString) {
            const date = new Date(dateString);
            let h = date.getHours();
            let m = date.getMinutes();
            return `${h}:${m}`
        },
        getTransferStatus(status) {
            return status ? 'paid' : 'unpaid'
        },
        getDate(date) {
            const dateParsed = Date.parse(date)
            return formattedDate(dateParsed)
        },
        async getUpdatedToursArray() {
            await this.getFinishedActivities()
            const updatedToursArray = this.finishedActivities.map((tour) => {
                const isSelected = this.checkedRows.some((activity) => activity.activityGroupId === tour.activityGroupId)
                if (isSelected) return { ...tour, transferred: true }
                return tour
            })
            return updatedToursArray;
        },
        goToCompanyDetails(companyId) {
            this.$router.push({ name: 'guider-details', params: { guiderUid: companyId } })
        },
        /*  async transferToGuider() {
             this.transferring = true
             let amount = 0
             this.checkedRows.forEach((row) => {
                 amount += row.payAmount * 100
             })
             const updatedToursArray = await this.getUpdatedToursArray()
             const data = {
                 amount,
                 destination: this.guider.stripeAccountId,
                 guiderUid: this.guider.uid,
                 updatedToursArray
             }
             await fetch(`${process.env.VUE_APP_API_URL}/stripe/guider/pay`, {
                 method: "POST",
                 body: JSON.stringify(data),
                 headers: {
                     "Content-Type": "application/json",
                 },
                 credentials: "same-origin",
             })
                 .then((response) => response.json()).then((data) => {
                     this.transferring = false
                     if (data.success) {
                         this.setGuiderFinishedActivities(updatedToursArray)
                         this.$buefy.toast.open({
                             duration: 10000,
                             message: 'The money has been transferred to the company',
                             position: "is-bottom",
                             type: "is-success",
                         });
                         return
                     }
                     this.$buefy.toast.open({
                         duration: 10000,
                         message: data.message,
                         position: "is-bottom",
                         type: "is-danger",
                     });
                 })
                 .catch((error) => {
                     this.transferring = false
                     this.$buefy.toast.open({
                         duration: 10000,
                         message: error.message,
                         position: "is-bottom",
                         type: "is-danger",
                     });
                 });
 
         } */
        async transferToGuider() {
            this.transferring = true
           /*  const updatedToursArray = await this.getUpdatedToursArray()


            const historyRef =  db.collection('finishedActivities').doc(guiderUid);

            historyRef.update({ tours: updatedToursArray })
                .then(() => {
                    this.setGuiderFinishedActivities(updatedToursArray)
                }).catch(() => {
                    this.$buefy.toast.open({
                        duration: 10000,
                        message: 'There was a problem marking the tour(s) as paid',
                        position: "is-bottom",
                        type: "is-danger",
                    });
                })
 */


        }
    },
    computed: {
        ...mapState({
            finishedActivities: state => state.guiders[GuidersStates.FINISHED_ACTIVITIES],
        }),
        computedFinishedActivities() {
            let finishedActivities = []
            this.finishedActivities.map((activity, index) => {
                if (activity.activityInProgress) return
                finishedActivities.push({ ...activity, id: index })
            })
            return finishedActivities
        }
    },
}
</script>

<style lang="scss" scoped>
.container--info {
    display: block;
    padding: 1rem;

    .columns {
        margin: 0;
    }

    .column {
        padding: 0
    }
}

.profile-photo {
    width: auto;
    object-fit: contain;
    max-height: 3rem;
    border-radius: 50%;
}

.guider-details {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: start;
    max-width: 10rem;
    color: #373F4F;
    font-weight: 300;
}

.user-details.columns {
    .column:not(:first-child) {
        padding: 0;
    }
}

.paid,
.unpaid {
    font-weight: 600;
}

.paid {
    background-color: #EBFBEF;
    color: #37D25F;
}

.unpaid {
    background-color: #F9EDF7;
    color: #FF4868;

}

.column {
    padding: 0 0.75rem;
}
</style>