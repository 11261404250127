<template>
    <section>
        <b-loading v-if="!ticketInfo"></b-loading>
        <div v-else class="container">
            <div>
                <GoBack class="mb-6" :route-name-humanized="titles[0]" />

                <keep-alive>
                    <TicketsData :ticket-info="ticketData" @dataChanged="dataChanged" />
                </keep-alive>

                <div class="buttons">

                    <b-tooltip :active="!formHasChanged" label="No changes have been made">
                        <b-button v-if="isUploadingImages" loading>Loading</b-button>
                        <b-button v-else :loading="buttonLoading" @click="onFormSubmit" :disabled="!formHasChanged"
                            type="is-primary">{{ buttonText
                            }}</b-button>
                    </b-tooltip>

                </div>
            </div>
        </div>
    </section>
</template>
  
<script>
import { db } from "../firebase";
import { mapMutations, mapState, mapActions } from "vuex";
import GoBack from "./elements/GoBack.vue";
import TicketsData from "../CreationFormComponents/TicketsForm/TicketsData.vue";
import {
    TicketsMutations, TicketsStates, TicketsActions
} from "../store/Storetypes";
import { isEqual } from 'lodash'

const initialTicketInfo = {
    title: null,
    price: null,
    city: "Lisbon, Portugal",
    isVisible: true,
    ticketLink: '',
    coverImage: null,
    description: null,
    categories: []
}

export default {
    components: {
        TicketsData,
        GoBack
    },
    data() {
        return {
            buttonText: "Confirm",
            ticketCreated: false,
            valid: false,
            isLoading: true,
            ticketInfo: initialTicketInfo,
            ticketToEditData: {},
            ticketIndex: -1,
            buttonLoading: false,
            isUploadingImages: false
        };
    },
    computed: {
        ...mapState({
            tickets: state => state.tickets[TicketsStates.TICKETS],
        }),
        isEdit() {
            return this.$route.name === "edit-ticket"
        },
        formHasChanged() {
            const hasChanged = this.isEdit ? !isEqual(this.ticketToEditData, this.ticketInfo) : !isEqual(initialTicketInfo, this.ticketInfo)
            return hasChanged
        },
        titles() {
            return [
                "Ticket Information",
                "Review",
            ]
        },
        ticketData() {
            if (this.$route.name === "edit-ticket") {
                const ticketData = this.setEditTicketData()
                return ticketData
            }
            this.finishPreparingTicketData(true)
            return this.ticketInfo
        }
    },
    beforeMount() {
        window.addEventListener("beforeunload", this.preventNav);
        this.$once("hook:beforeDestroy", () => {
            window.removeEventListener("beforeunload", this.preventNav);
        });
    },
    created() {
        this.getTickets()
    },
    methods: {
        ...mapActions({
            getTickets: TicketsActions.GET_TICKETS
        }),
        ...mapMutations({
            updateTicketData: TicketsMutations.UPDATE_TICKET_DATA
        }),
        setEditTicketData() {
            this.ticketIndex = this.getSelectedTicketIndex()
            if (this.ticketIndex < 0) {
                this.finishPreparingTicketData(true)
                return
            }
            const ticketData = this.tickets[this.ticketIndex]
            this.ticketToEditData = ticketData;
            this.ticketInfo = ticketData;
            return ticketData
        },
        getSelectedTicketIndex() {
            if (!this.tickets.length) this.getTickets()
            const activityId = this.$route.params.id
            return this.tickets.findIndex((ticket) => ticket.id === activityId)
        },
        finishPreparingTicketData(activityFound) {
            this.isLoading = false
            this.notFound = activityFound;
        },
        retrieveTicketData() {
            db.firestore()
                .collection("tickets")
                .doc(this.$route.params.id)
                .get()
                .then((doc) => {
                    if (doc.data()) {
                        this.ticketToEditData = doc.data();
                        this.ticketInfo = doc.data();
                        this.isLoading = false;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.notFound = true;
                });
        },
        preventNav(ticket) {
            ticket.preventDefault();
            ticket.returnValue = "";
        },
        deleteDropFile(index) {
            this.dropFiles.splice(index, 1);
        },
        dataChanged(value) {
            this.isUploadingImages = false;
            if (typeof value === "string" && value === "loading") {
                this.isUploadingImages = true;
            }
            if (typeof value === "string") {
                this.valid = false;
            } else {
                this.ticketInfo = { ...this.ticketInfo, ...value };
                this.valid = true;
            }
        },
        onFormSubmit() {
            this.isUploadingImages = true;
            if (!this.formHasChanged) return
            this.buttonLoading = true
            if (this.isEdit) {
                // edit ticket
                db.firestore()
                    .collection("tickets")
                    .doc(this.$route.params.id)
                    .update(this.ticketInfo)
                    .then(() => {
                        this.isUploadingImages = false;
                        this.updateTicketData({ ticketIndex: this.ticketIndex, ticketData: this.ticketInfo })
                        this.$router.push({ name: "manage-activities" });

                    })
                    .catch((error) => {
                        console.log(error);
                    });
                this.buttonLoading = false
                return
            }

            //create ticket
            db.firestore()
                .collection("tickets")
                .add(this.ticketInfo)
                .then(() => {
                    window.removeEventListener("beforeunload", this.preventNav);
                    this.isUploadingImages = false;
                    this.ticketCreated = true;
                    this.$router.push({ name: "manage-activities" });

                })
                .catch((error) => {
                    this.isUploadingImages = false;
                    console.log(error);
                });

          
            this.buttonLoading = false
        },
        getTicketActivityData(activityId) {
            this.activityIndex = this.activities.findIndex((activity) => activity.id === activityId)
            if (this.activityIndex < 0) return
            return this.activities[this.activityIndex]
        },
    },
};
</script>
  
<style lang="scss" scoped>
::v-deep {
    .buttons .button:last-of-type {
        margin-right: 0;
    }

    .valid {
        background-color: #0e3d4d;
        color: #fff;
    }

    .submit-button {
        float: right;
        margin-bottom: 60px;
    }

    .buttons {
        margin-top: 24px;
        justify-content: flex-end;
    }

    .progress.is-small,
    .is-small.progress-wrapper.is-not-native {
        height: 0.35rem;
        margin-bottom: 24px;
    }

    .progress,
    .progress-wrapper.is-not-native {
        border-radius: 5px;
    }

    .button.is-text:hover,
    .button.is-text.is-hovered,
    .button.is-text:focus,
    .button.is-text.is-focused {
        background-color: none;
    }

    .invalid {
        background-color: #ededed;
        color: #0e3d4d;
        cursor: not-allowed;
    }

    .label {
        font-weight: 500;
        margin-bottom: 0.75rem !important;
    }
}

.info-block {
    margin-top: 25vh
}

.title {
    margin-top: 15px;
}

.verification-message {
    margin-top: 28vh;
    text-align-last: center;
}

.verification-message a {
    margin-top: 12px;
    text-decoration: underline;
    color: #0e3d4d80;
}

.upload {
    width: 100%;
}

.field:not(:last-child) {
    margin-bottom: 1.75rem;
}

@media only screen and (max-width: 768px) {
    div.notification {
        background-color: transparent;
        padding: 0px;
        border: none;
    }
}
</style>
  