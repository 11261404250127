export const routesToHideFooterAndNavBar = [
    "my-tours",
    "add-activity",
    "edit-activity",
    "create-event",
    "create-ticket",
    "edit-event",
    "edit-ticket",
    "login",
    "signin-guider",
    "register",
    "register-guider",
    "register-employee",
    "scan-ticket",
    "start-activity",
    "add-employee",
    "manage-employee",
    "quote",
    "company-profile"
]

export const routesToHideFooter= [
   
    "details",
    "event-details",
    "manage-activities",
    "settings",
    "guider-dashboard",
    "schedule",
    "guider-settings",
    "company-settings",
    "guider-basic-information",
    "settings-basic-information",
    "activity-group-details",
    "guide-settings",
    "admin-dashboard",
    "manage-guiders",
    'manage-payments',
    "affiliates-program",
    "employees",
    "company-upcoming-activities",
    "company-change-password",
    "guider-change-password",
    "guider-details",
    "guider-upcoming-activities",
    "upcoming-activities",
    "affiliateStripeRefresh"
]