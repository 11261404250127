<template>
    <section>

        <p class="is-blue page-title mb-5">Affiliates</p>

        <EmptyStateSearch class="mb-5" v-if="!affiliates || !affiliates.length" type="no-messages"
            title="You don't have any affiliates yet" />

        <b-table class="mt-4" :data="affiliatesComputed" stripped ref="table" paginated per-page="10"
            aria-next-label="Next page" detailed aria-previous-label="Previous page" aria-page-label="Page"
            aria-current-label="Current page" pagination-rounded searchable>

            <b-table-column field="email" label="Email" sortable v-slot="props">
                {{ props.row.email }}
            </b-table-column>

            <b-table-column field="discountCode" label="Discount Code" v-slot="props">
                <div class="is-flex">
                    {{ props.row.discountCode }}
                    <div @click="copyToClipboard(props.row.discountCode)">
                        <b-icon pack="far" class="ml-2 icon--copy" icon="copy"></b-icon>
                    </div>
                </div>

            </b-table-column>

            <b-table-column field="timesRedeemed" label="Times redeemed" sortable v-slot="props">
                {{ getAffiliateCouponData(props.row.id).times_redeemed }}
            </b-table-column>

            <b-table-column field="pendingEarnings" label="Expected commission" sortable v-slot="props">
                {{ getEarnings(props.row.pendingEarnings) }}
            </b-table-column>

            <b-table-column field="approvedEarnings" label="Pending commission" sortable v-slot="props">
                {{  getEarnings(props.row.approvedEarnings) }}
            </b-table-column>

            <b-table-column field="paidEarnings" label="Paid commission" sortable v-slot="props">
                {{  getEarnings(props.row.earnings) }}
            </b-table-column>


            <template #detail="props">
                <span class="is-flex"><strong class="mr-1">Stripe account: </strong>{{ props.row.id }}
                    <div @click="copyToClipboard(props.row.id)">
                        <b-icon pack="far" class="ml-2 icon--copy" icon="copy"></b-icon>
                    </div>
                </span>
            </template>

            <template #empty>
                <div class="has-text-centered">This activity doesn't have any participants</div>
            </template>
        </b-table>

        <b-modal v-model="isAddAffiliateModalActive" scroll="keep">
            <AddAffiliateForm />
        </b-modal>
    </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { AffiliatesActions, AffiliatesStates } from "../store/Storetypes";
import AddAffiliateForm from './AddAffiliateForm.vue'
import { HelpersMixin } from '../mixins'
import EmptyStateSearch from "../components/elements/EmptyStateSearch.vue";
export default {
    mixins: [HelpersMixin],
    components: {
        AddAffiliateForm,
        EmptyStateSearch
    },
    computed: {
        ...mapState({
            affiliates: state => state.affiliates[AffiliatesStates.AFFILIATES],
            affiliatesCoupons: state => state.affiliates[AffiliatesStates.AFFILIATES_COUPONS]
        }),
        affiliatesComputed() {
            return this.affiliates.filter((affiliate) => affiliate.discountCode)
        }
    },
    data() {
        return {
            isAddAffiliateModalActive: false
        }
    },
    created() {
        this.getAffiliates()
        this.getAffiliatesCoupons()
    },
    methods: {
        ...mapActions({
            getAffiliatesCoupons: AffiliatesActions.GET_ALL_AFFILIATES_COUPONS,
            getAffiliates: AffiliatesActions.GET_AFFILIATES
        }),
        getAffiliateCouponData(affiliateId) {
            // console.log(this.affiliatesCoupons, affiliateId);
            const index = this.affiliatesCoupons.findIndex((affiliate) => affiliate.metadata.affiliateId === affiliateId)
            if (index < 0) return []
            // console.log(this.affiliatesCoupons[index]);
            return this.affiliatesCoupons[index]
        },
        getEarnings(earnings){
            return earnings? `${earnings / 100} €` : 'None'
        }
    }
}
</script>

<style lang="scss" scoped>
.add-btn.button {
    border-radius: 4px;
}

.add-btn,
.icon--copy {
    color: #446DF6
}
</style>