<template>
    <section>

        <h4 class=" title is-5">{{ activityInfo.title }}</h4>
        <h4>{{ activityInfo.description }}</h4>

        <hr>
        <p><b>Market price:</b> {{ activityInfo.marketPrice }} €</p><br>
        <p><b>Price:</b> {{ activityInfo.price }} €</p><br>
       <!--  <p v-if="activityInfo.reservationPrice"><b>Reservation Price:</b> {{ activityInfo.reservationPrice }} €</p><br>
         -->
         <p><b>Guider payment:</b> {{ activityInfo.guiderPayment }} €</p><br>
        <p><b>Duration:</b> {{ getHoursAndMinutes(activityInfo.duration) }} </p><br>

        <p class="pb-2"><b>Activity category:</b></p>
        <b-field v-for="categoryId in activityInfo.categories" :key="categoryId">
            <b-tag rounded type="is-primary is-light" class="mr-2 is-blue">{{ Categories[categoryId].icon }} {{
                Categories[categoryId].name }}</b-tag>
        </b-field>
        <br>
        <p><b>Min. num. of participants:</b> {{ activityInfo.minNumOfParticipants }} </p><br>
        <p><b>Max. num. of participants:</b> {{ activityInfo.maxNumOfParticipants }} </p><br>

        <hr>
        <p><b>Meeting Point:</b> {{ activityInfo.meetingPoint }}</p><br>
        <p><b>Location:</b> {{ activityInfo.city }}</p><br>
        <div class="display-flex">
            <b class="pr-3">Language(s):</b>

            <b-taglist class="mb-2">
                <b-tag v-for="(language, index) in activityInfo.languages" :key="index" type="is-primary is-light"
                    size="is-medium">{{ language }}</b-tag>
            </b-taglist>
        </div>

        <div class="display-flex">
            <b class="pr-3">Time slots (shared):</b>

            <b-taglist class="mb-2">
                <b-tag v-for="(slot, index) in activityInfo.sharedTimeSlots" :key="index" type="is-primary is-light"
                    size="is-medium">{{ slot }}</b-tag>
            </b-taglist>
        </div>

        <div class="display-flex">
            <b class="pr-3">Time slots (private):</b>

            <b-taglist class="">
                <b-tag v-for="(slot, index) in activityInfo.privateTimeSlots" :key="index" type="is-primary is-light"
                    size="is-medium">{{ slot }}</b-tag>
            </b-taglist>
        </div>

    </section>
</template>

<script>
import { ActivityCategories as Categories } from '../enums/ActivityCategories';
import { getHoursAndMinutes } from '../helpers'
export default {
    props: ['activityInfo'],
    data() {
        return {
            Categories,
            getHoursAndMinutes
        }
    }
}
</script>