<template>
    <section>
        <b-field class=" pr-1 is-6" :label="isOptionalField('Title')">
            <b-input v-model="title"></b-input>
        </b-field>

        <b-field class=" pr-1 is-6" :label="isOptionalField('Description')">
            <b-input maxlength="750" type="textarea" v-model.trim="description"></b-input>
        </b-field>

        <b-field class="mb-5" :label="isOptionalField('City')">
            <b-autocomplete v-model="city" placeholder="e.g. Lisboa" :data="filteredDataObj" field="city"
              @select="(option) => (selectedCity = option.city)" validation-message="Select a city from the list">
            </b-autocomplete>
          </b-field>

        <b-field :label="isOptionalField('Categories')" class="categories mb-2">
            <b-checkbox-button v-model="categories" v-for="(category, index) in ActivityCategories" :key="index"
                :native-value="index" type="is-primary">{{ category.name
                }}</b-checkbox-button>
        </b-field>

        <b-field class=" pr-1 is-6" :label="isOptionalField('Price (€)')">
            <b-input type="float" placeholder="0,00" v-model="price"></b-input>
        </b-field>

        <b-field class=" pr-1 is-6" :label="isOptionalField('Ticket Link')">
            <b-input v-model="ticketLink"></b-input>
        </b-field>

        <b-field :label="isOptionalField('Cover Photo')">
            <b-upload v-model="coverPhoto" drag-drop required expanded accept="image/*">
                <section class="section">
                    <div class="content has-text-centered">
                        <p>
                            <b-icon icon="upload" size="is-large"> </b-icon>
                        </p>
                        <p v-if="!isEditing">Upload an image for the cover.</p>
                        <p v-else>Change cover image</p>
                    </div>
                </section>
            </b-upload>
        </b-field>
        <div class="tags" v-if="coverPhoto !== null && !isEditing">
            <span class="tag">
                {{ coverPhoto?.name }}
                <button class="delete is-small" type="button" @click="coverPhoto = null"></button>
            </span>
        </div>
        <img class="cover-image" :src="coverImage" v-if="isEditing" />

    </section>
</template>

<script>
import { db } from "../../firebase";
import CityNames from "../../assets/CityNames.js";
import { ActivityFormMixin } from '@/CreationFormComponents/mixins/ActivityFormMixin'
import { ActivityCategories } from '@/enums/ActivityCategories'

export default {
    mixins: [ActivityFormMixin],
    props: {
        ticketInfo: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {
            CityNamesList: CityNames.cityNames,
            ActivityCategories,
            coverPhoto: null,
            uploadValue: 0,
            title: this.ticketInfo?.title,
            description: this.ticketInfo?.description || '',
            price: this.ticketInfo?.price,
            ticketLink: this.ticketInfo?.ticketLink,
            coverImage: this.ticketInfo?.coverImage,
            categories: this.ticketInfo?.categories.length ? this.ticketInfo?.categories : [],
            city: this.ticketInfo?.city ? this.ticketInfo?.city  : "Lisbon, Portugal",
            selectedCity: this.ticketInfo?.city ? this.ticketInfo?.city  : "Lisbon, Portugal",
            isEditing: false,
            isLoading: false,
            rndString: Math.random().toString(36).substr(2, 14)
        };
    },
    created() {
        if (this.$route.name === "edit-ticket") {
            this.isEditing = true;
        }
    },
    computed: {
        changeData() {
            const {
                title,
                price,
                ticketLink,
                categories,
                coverPhoto,
                coverImage,
                description
            } = this;
            return {
                title,
                price,
                ticketLink,
                categories,
                coverPhoto,
                coverImage,
                description
            };
        },
    filteredDataObj() {
      return this.CityNamesList.filter((option) => {
        return (
          option.city
            .toString()
            .toLowerCase()
            .indexOf(this.city.toLowerCase()) >= 0
        );
      });
    },
    },
    watch: {
        changeData: {
            handler: function () {
                this.emitData();
            },
            deep: true,
        },
        coverPhoto() {
            if (this.isEditing) {
                this.changeCoverPhoto();
            }
        },
    },
    activated() {
        /*  if (this.$route.name === "edit-ticket") {
             this.$emit("dataChanged", {
                 title: this.title,
                 description: this.description,
                 categories: this.categories,
                 price: Number(this.price),
                 ticketLink: this.ticketLink,
                 coverImage: this.coverImage,
             });
         } else { */
        this.emitData();
        // }
    },

    methods: {
        onUploadCoverPhoto() {
            if (!this.coverPhoto) return
            this.isLoading = true;
            const storageRef = db.storage()
                .ref(`${this.rndString}/${this.coverPhoto.name}`)
                .put(this.coverPhoto);
            storageRef.on(
                `state_changed`,
                () => {
                    this.$emit("dataChanged", "loading");
                },
                (error) => {
                    console.log(error.message);
                },
                () => {
                    this.uploadValue = 100;
                    storageRef.snapshot.ref.getDownloadURL().then((url) => {
                        this.coverImage = url;

                        this.$emit("dataChanged", {
                            /*   title: this.title,
                              description: this.description,
                              categories: this.categories,
                              price: Number(this.price),
                              ticketLink: this.ticketLink, */
                            coverImage: this.coverImage,
                        });
                        this.isLoading = false;
                    });
                }
            );
        },

        changeCoverPhoto() {
            if (!this.coverPhoto) return
            const storageRef = db
                .storage()
                .ref(`${this.coverPhoto.name}`)
                .put(this.coverPhoto);
            storageRef.on(
                `state_changed`,
                () => {
                    this.$emit("dataChanged", "loading");
                },
                (error) => {
                    console.log(error.message);
                },
                () => {
                    this.uploadValue = 100;
                    storageRef.snapshot.ref.getDownloadURL().then((url) => {
                        this.coverImage = url;
                        this.$emit("dataChanged", {
                            title: this.title,
                            description: this.description,
                            city: this.selectedCity,
                            categories: this.categories,
                            price: Number(this.price),
                            ticketLink: this.ticketLink,
                            coverImage: this.coverImage,
                        });
                    });
                }
            );
        },
        emitData() {
            if (this.coverPhoto === "object") {
                this.onUploadCoverPhoto()
            }
            if (this.title && this.description && this.price && this.ticketLink) {
                this.$emit("dataChanged", {
                    title: this.title,
                    description: this.description,
                    city: this.selectedCity,
                    categories: this.categories,
                    price: Number(this.price),
                    ticketLink: this.ticketLink,
                    coverImage: this.coverImage,
                });
            } else {
                this.$emit("dataChanged", "invalid");
            }


            /* this.$emit("dataChanged", {
                title: this.title,
                description: this.description,
                categories: this.categories,
                price: Number(this.price),
                ticketLink: this.ticketLink,
                coverImage: this.coverImage,
            }); */

            /*  if (this.title && this.description && this.price && this.ticketLink && typeof this.coverPhoto === "object") {
                 this.onUploadCoverPhoto()
             } else {
                 this.$emit("dataChanged", "invalid");
             } */
        },
    },
};
</script>

<style lang="scss" scoped>
.column {
    padding: 0;
}

::v-deep {
    .help.is-invisible {
        display: none;
    }

    .columns {
        margin-left: 0;
        margin-right: 0;
        align-items: flex-start;
    }

    .categories {
        .field.has-addons {
            display: grid;
            grid-template-columns: repeat(2, minmax(max-content, 1fr));
            margin-bottom: 2rem;
        }
    }
}
</style>