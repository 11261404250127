<template>
  <section class="container is-max-desktop">
    <iframe
      data-tally-src="https://tally.so/embed/3EJ9Eo?alignLeft=1&transparentBackground=1&dynamicHeight=1"
      loading="lazy"
      width="100%"
      height="789"
      frameborder="0"
      marginheight="0"
      marginwidth="0"
      title="Transfers Information Request
    "
    ></iframe>
    <script  type="application/javascript">
      var d = document,
        w = "https://tally.so/widgets/embed.js",
        v = function () {
          "undefined" != typeof Tally
            ? Tally.loadEmbeds()
            : d
                .querySelectorAll("iframe[data-tally-src]:not([src])")
                .forEach(function (e) {
                  e.src = e.dataset.tallySrc;
                });
        };
      if ("undefined" != typeof Tally) v();
      else if (d.querySelector('script[src="' + w + '"]') == null) {
        var s = d.createElement("script");
        (s.src = w), (s.onload = v), (s.onerror = v), d.body.appendChild(s);
      }
    </script>
  </section>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "Guiders.pt | Work with us",
      meta: [
        { property: "og:title", content: "Guiders.pt | Work with us" },
        {
          property: "og:description",
          content: "Do you do transfers? Work with us!",
        },

        // Google / Schema.org markup:
        { itemprop: "name", content: "Guiders.pt | Work with us" },
        {
          itemprop: "description",
          content: "Do you do transfers? Work with us!",
        },

        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          name: "description",
          content: "Do you do transfers? Work with us!",
        },
        {
          name: "keywords",
          content:
            "guiders,Become a tuk tuk guide,Become a tuk tuk guider,Become a guider guiders.pt,  Guides in Portugal, Guides Portugal, Guides From Portugal, Guiders Portugal, Guiders Lisbon, Guides Lisbon, Guides From Lisbon",
        },
      ],
      link: [
        { rel: "canonical", href: "https://guiders.pt/transfer/registration" },
      ],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
