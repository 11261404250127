import { mapState, mapMutations } from "vuex";
import { db } from "../firebase";
import { ActivitiesStates, GuidersStates, GuidersMutations, UsersStates } from '../store/Storetypes'
import { formattedDate, dateWithName} from '../helpers';
export const ActivityMixin = {
    data() {
        return {
            formattedDate,
            dateWithName
        }
    },
    methods: {
        ...mapMutations({
            setGuiderActivities: GuidersMutations.SET_GUIDER_ACTIVITIES,
        }),
        getActivityInfo(activityId) {
            if (!activityId) return
            const activityInfo = this.activitiesFromStore.find(activity => activity.id === activityId)
            return activityInfo
        },
        canGuiderDoActivity(activityInfo) {
            if (this.isGuestGuider) return true
            if (!activityInfo) return
            const isActivityInWaitlist = this.waitlistedActivities.filter((activity)=> activity.id === activityInfo.id)
           /*  const isGuiderAlreadyDoingActivity = this.guiderActivities.find((activity) =>
                activity.date === activityInfo.date &&
                activity.time === activityInfo.time) 

            return isGuiderAlreadyDoingActivity ? false : true*/
            return isActivityInWaitlist.length < 1
        },
        /* isInTheTimeFrame(scheduledActivityTime, activityTimeToCompare){
           const scheduledActivityTimeSplit = scheduledActivityTime.split('-');
           const activityTimeToCompareSplit = activityTimeToCompare.split('-');
           return 

        } */
        getActivityDate(date) {
            return this.isActivityForToday(date) ? "Today" : dateWithName(date)
        },
        isActivityForToday(date) {
            let dateToday = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
            const isDateToday = formattedDate(dateToday) === formattedDate(date)
            return isDateToday
        },
        // TODO: REMOVE COMMENTS
        async getGuiderTours() {
            let activities = []
            const groups = db.firestore().collection("groups");
            const idToCheck = this.isEmployee ? this.user?.companyId : this.user?.uid
            //const snapshot = await guiderActivities.where("guiders", "array-contains", idToCheck).get();
            const snapshot = await groups.get();
            if (snapshot.empty) {
                this.setGuiderActivities([])
                return activities
            }
            /*  let dateToday = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
             dateToday = Date.parse(dateToday) */
            snapshot.forEach(doc => {
               // console.log(doc.id, '=>', doc.data());
                if (!doc.data()?.guiders) return
                //if (doc.data().date >= dateToday)
                const exists =  doc.data()?.guiders.find((guiderInfo)=> guiderInfo.guiderId === idToCheck )
                if (!exists) return
                activities.push(doc.data())
              
            });
            this.setGuiderActivities(activities)
            return activities
        },
    },
    computed: {
        ...mapState({
            activitiesFromStore: state => state.activities[ActivitiesStates.ACTIVITIES],
            guiderActivities: state => state.guiders[GuidersStates.GUIDER_ACTIVITIES],
            waitlistedActivities: state => state.guiders[GuidersStates.GUIDER_WAITLISTED_ACTIVITIES],
            isGuestGuider: state => state.guiders[GuidersStates.IS_GUEST_GUIDER],
            isEmployee: state => state.users[UsersStates.IS_EMPLOYEE]
        }),
    }
}