import firebase from "firebase";
import "firebase/firestore";
import "firebase/storage"
import "firebase/auth";

let firebaseConfig = {
    apiKey: process.env.VUE_APP_API_KEY,
    authDomain: process.env.VUE_APP_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_DATABASE_URL,
    projectId: process.env.VUE_APP_PROJECT_ID,
    storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_APP_ID,
    measurementId: "G-MNS3JRHF0C"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
export const FieldValue = firebase.firestore.FieldValue
export const db = firebaseApp;