import {
    EventsMutations as Mutations,
    EventsStates as States,
    EventsActions as Actions
} from '../Storetypes'
import { db } from "../../firebase";

export default {
    state: {
        [States.SELECTED_EVENT_ID]: undefined,
        [States.EVENTS]: undefined,
        [States.EVENT_GROUPS]: undefined,
        [States.SELECTED_EVENT_DATA]: undefined,
        [States.SELECTED_EVENT_GROUP_DATA]: undefined,
    },
    mutations: {
        [Mutations.SET_EVENTS](state, events) {
            state[States.EVENTS] = events
        },
        [Mutations.SET_EVENT_GROUPS](state, eventGroups) {
            state[States.EVENT_GROUPS] = eventGroups
        },
        [Mutations.SET_SELECTED_EVENT_ID](state, id) {
            state[States.SELECTED_EVENT_ID] = id
        },
        [Mutations.SET_SELECTED_EVENT_DATA](state, eventData) {
            state[States.SELECTED_EVENT_DATA] = eventData
        },
        [Mutations.SET_SELECTED_EVENT_GROUP_DATA](state, groupData) {
            state[States.SELECTED_EVENT_GROUP_DATA] = groupData
        },
        [Mutations.UPDATE_EVENT_DATA](state, { eventIndex, eventData }) {
            state[States.EVENTS][eventIndex] = eventData
        },
        [Mutations.DELETE_EVENT](state, eventId) {
            const filteredActivities = state[States.EVENTS].filter((event) => event.id !== eventId)
            state[States.EVENTS] = filteredActivities
        },
        [Mutations.GET_EVENT_DATA](state, eventId) {
            const events = state[States.EVENTS]
            const selectedEventIndex = events.findIndex((event) => event.id === eventId)
            if (selectedEventIndex < 0) return {}
            state[States.SELECTED_EVENT_DATA] = events[selectedEventIndex]
            return events[selectedEventIndex]
        }
    },
    actions: {
        [Actions.GET_EVENTS]({ commit }) {
            let Events = [];
            db.firestore()
                .collection("events")
                .where("isVisible", "==", true)
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        Events.push({
                            ...doc.data(),
                            id: doc.id,
                        });
                    });
                    const eventsSortedByCheapest = Events.sort((a, b) => {
                        return a.price - b.price;
                    });
                    commit(Mutations.SET_EVENTS, eventsSortedByCheapest)
                })
            return Events
        },
        [Actions.GET_EVENT_GROUPS]({ commit }) {
            let ActivityGroups = [];
            db.firestore()
                .collection("groups")
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        ActivityGroups.push({
                            ...doc.data(),
                            id: doc.id,
                        });
                    });
                    const eventGroupsSortedByDate = ActivityGroups.sort((a, b) => {
                        return b.date - a.date;
                    });
                    commit(Mutations.SET_EVENT_GROUPS, eventGroupsSortedByDate)
                })
        },
        [Actions.GET_EVENT_DATA]({ state }, eventId) {
            const events = state[States.EVENTS]
            const selectedEventIndex = events.findIndex((event) => event.id === eventId)
            if (selectedEventIndex < 0) return {}
            state[States.SELECTED_EVENT_DATA] = events[selectedEventIndex]
            return events[selectedEventIndex]
        },
        [Actions.GET_EVENT_GROUP_DATA]({ commit }, groupId) {
            db.firestore()
                .collection("groups")
                .doc(groupId)
                .get()
                .then((doc) => {
                    if (doc.data()) {
                        commit(Mutations.SET_SELECTED_EVENT_GROUP_DATA, doc.data())
                    }
                })
                .catch((err) => {
                    this.$buefy.toast.open({
                        duration: 10000,
                        message: err.message,
                        position: "is-bottom",
                        type: "is-danger",
                    });
                });
        }
    }
}