import {
    NewsletterMutations as Mutations,
    NewsletterStates as States,
    NewsletterActions as Actions
} from '../Storetypes'

import { db } from "../../firebase";

export default {
    state: {
        [States.SUBSCRIBED_EMAILS]: []
    },

    mutations: {
        [Mutations.SET_EMAILS](state, emails) {
            state[States.SUBSCRIBED_EMAILS] = emails
        },
    },
    actions: {
        [Actions.GET_SUBSCRIBED_EMAILS]({ commit }) {
            let Emails = [];
            db.firestore()
                .collection("newsletter")
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        Emails.push({
                            ...doc.data(),
                            id: doc.id,
                        });
                    });
                    commit(Mutations.SET_EMAILS, Emails)
                })
        },
    }
}