export const formattedDate = (date) => {
    return new Date(Number(date)).toLocaleDateString()
}

export const dateWithName = (date) => {
    const formattedDate = new Date(Number(date))
    const options = {
        weekday: "short",
        month: "short",
        day: "numeric",
    };
    return formattedDate.toLocaleDateString("en-US", options);
}

export const dateWithNameFull = (date) => {
    const formattedDate = new Date(Number(date))
    const options = {
        weekday: "long",
        month: "long",
        day: "numeric",
    };
    return formattedDate.toLocaleDateString("en-US", options);
}

export const hasDateTimePassed = (targetTime, targetDate) => {
    const splitTargetTime = targetTime.split(':')
    const targetHour = splitTargetTime[0];
    const targetMinute = splitTargetTime[1];
    let date = new Date(Number(targetDate))
    date = date.setHours(targetHour, targetMinute, '00')
    return new Date(date) > new Date();
}

export const getHourHumanized = (dateString) => {
    const date = new Date(dateString);
    let h = date.getHours();
    let m = date.getMinutes();
    return `${h}:${m}`
}

export const dateWithMonthAndDay = (date) => {
    const formattedDate = new Date(Number(date))
    const options = {
        month: "long",
        day: "numeric",
    };
    return formattedDate.toLocaleDateString("en-US", options);
}

export const dateWithDayMonthYear = (date) => {
    const originalDate = new Date(date);

    const day = originalDate.getUTCDate();
    const month = originalDate.getUTCMonth() + 1; // Months are zero-based
    const year = originalDate.getUTCFullYear();

    return `${day}/${month}/${year}`;
}

export const dateTimeToMonthYear = (date) => {
    const originalDate = new Date(date);

    const monthNames = [
        "January", "February", "March", "April",
        "May", "June", "July", "August",
        "September", "October", "November", "December"
    ];

    const month = monthNames[originalDate.getUTCMonth()];
    const day = originalDate.getUTCDate();

    return `${month} ${day}`;
}

export const addDurationToTime = (time, hours = 0, minutes = 0) => {
    // convert the time string to a Date object
    const date = new Date(`1970-01-01T${time}`);

    // add the hours and minutes to the date object
    date.setHours(date.getHours() + hours);
    date.setMinutes(date.getMinutes() + minutes);

    // format the updated time as a string and return it
    const updatedTime = date.toTimeString().substring(0, 5);
    return updatedTime;
}

export const convertMinutesToHoursAndMinutes = (minutes) => {
    const hours = Math.floor(minutes / 60); // get the whole number of hours
    const remainingMinutes = minutes % 60; // get the remaining minutes
    return { hours, minutes: remainingMinutes }
}

export const convertHumanizedTime = (timeHumanized) => {
    const time = timeHumanized.split(':')
    const timeConverted = new Date(new Date().setHours(Number(time[0]), (Number(time[1]))))
    return timeConverted
}

export const getHoursAndMinutes = (minutes) => {
    const hours = Math.floor(minutes / 60); // get the whole number of hours
    const remainingMinutes = minutes % 60; // get the remaining minutes
    const minutesText = remainingMinutes === 1 ? 'minute' : 'minutes'
    if (hours === 0) return `${remainingMinutes} ${minutesText}`

    const hoursText = hours === 1 ? 'hour' : 'hours'
    if (remainingMinutes === 0) return `${hours} ${hoursText}`
    return `${hours} ${hoursText} and ${remainingMinutes} ${minutesText}`
}

export const dateTodayParsed = () => {
    let dateToday = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
    return dateToday
}

export const hasDatePassed = (date) => {
    let dateToday = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
    const hasDatePassed = Number(date) < Date.parse(dateToday)
    return hasDatePassed
}

export const getTimeDifferenceInMinutes = (startTime, endTime) => {
    const start = new Date(startTime);
    const end = new Date(endTime);
    const timeDiff = (end.getTime() - start.getTime()) / 60000;
    return timeDiff
}