<template>
  <section>
    <b-loading v-if="!form" is-full-page :can-cancel="false"></b-loading>
    <section v-else>
      <GoBack route-name-humanized="Personal Information" />

      <div class="block is-flex is-justify-content-center block-section mt-5">
        <img class="image is-rounded" :src="form?.photoURL" />

        <b-upload v-model="profilePhoto" class=" wrapper" accept="image/*">
          <span class="badge">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M13.6997 19.8981H20.0767" stroke="#0e3d4d" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M12.855 4.95573C13.592 4.01673 14.783 4.06573 15.723 4.80273L17.113 5.89273C18.053 6.62973 18.386 7.77273 17.649 8.71373L9.35996 19.2887C9.08296 19.6427 8.65996 19.8517 8.20996 19.8567L5.01296 19.8977L4.28896 16.7827C4.18696 16.3457 4.28896 15.8857 4.56596 15.5307L12.855 4.95573Z"
                stroke="#0e3d4d" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M11.3027 6.93616L16.0967 10.6942" stroke="#0e3d4d" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg></span>
        </b-upload>
      </div>

      <div class=" block">
        <p class="is-blue section-title mb-3 mt-5">Name</p>
        <b-input v-model="form.displayName"> </b-input>
      </div>

      <div class=" block">
        <p class="is-blue section-title mb-3 mt-5">Email</p>
        <b-input v-model="form.email"> </b-input>
      </div>

      <div v-if="isGuider">
        <p class="is-blue section-title mb-3 mt-5">How many seats do you have?</p>
        <b-field>
          <b-radio-button size="small" v-for="seatNumber in SeatNumbers" :key="seatNumber" v-model="form.numberOfSeats"
            :native-value="seatNumber" type="is-success">
            <span>{{ seatNumber }}</span>
          </b-radio-button>
        </b-field>

        <p class="is-blue section-title mt-5">Slack ID</p>
        <InlineNotification class="mt-3 mb-4" type="warning" title="Make sure you have the correct Slack ID"
          text="If you write the incorrect ID you will stop receiving slack messages. If you are not sure what your Slack ID is contact support."
          icon="📣" />

        <b-input v-model="form.slackID"> </b-input>
      </div>

      <b-button @click="saveChanges" class="mt-6" type="is-primary" expanded :disabled="!hasChanges">
        Save
      </b-button>
    </section>
  </section>
</template>

<script>
import InlineNotification from '../components/elements/InlineNotification.vue'
import GoBack from '../components/elements/GoBack.vue';
import { SeatNumbers } from '../enums/SeatNumbers';
import { GuidersMixin } from '../mixins';
import { isEqual } from 'lodash'
import { db } from "../firebase";
import { mapState } from "vuex";
import { UsersStates } from '../store/Storetypes'

export default {
  components: {
    InlineNotification,
    GoBack
  },
  mixins: [GuidersMixin],
  data() {
    return {
      SeatNumbers,
      profilePhoto: null,
      form: null,
      isLoading: true
    }
  },
  computed: {
    ...mapState({
      user: state => state.users[UsersStates.USER],
      isGuider: state => state.users[UsersStates.IS_USER_A_GUIDER],

    }),
    hasChanges() {
      if (!this.isGuider) return this.hasChanged(this.user.displayName, this.form.displayName) ||
        this.hasChanged(this.user.email, this.form.email) ||
        this.hasChanged(this.user.photoURL, this.form.photoURL)

      return this.hasChanged(this.user.displayName, this.form.displayName) ||
        this.hasChanged(this.user.numberOfSeats, this.form.numberOfSeats) ||
        this.hasChanged(this.user.email, this.form.email) ||
        this.hasChanged(this.user.slackID, this.form.slackID) ||
        this.hasChanged(this.user.photoURL, this.form.photoURL)
    },
  },
  watch: {
    profilePhoto() {
      // TODO: CHECK IF WORKS
      const storageRef = db
        .storage()
        .ref(`${this.user.uid}/${this.profilePhoto.name}`)
        .put(this.profilePhoto);
      storageRef.on(
        `state_changed`,
        () => { },
        (error) => {
          console.log(error.message);
        },
        () => {
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.form.photoURL = url;
          });
        }
      );
    },
    user() {
      if (!this.user?.uid) {
        this.form = {}
        return
      }
      this.form = {
        email: this.user?.email || '',
        displayName: this.user?.displayName || '',
        slackID: this.user?.slackID || '',
        photoURL: this.user?.photoURL || '',
        numberOfSeats: this.user?.numberOfSeats || null
      }
    },
    form: {
      handler: function () {
        this.isLoading = false
      },
      deep: true
    }
  },
  methods: {
    hasChanged(firstValue, secondValue) {
      return !isEqual(firstValue, secondValue)
    },
    saveChanges() {
      if (!this.hasChanges) return
      if (this.isGuider) {
        this.updateGuiderData({
          userData: {
            ...this.user,
            ...this.form,
            //email: this.user.email
          }
        })
      } else {
        this.updateUserDataInDB({
          userData: {
            ...this.user,
            ...this.form,
           // email: this.user.email
          }
        })
      }

      if (this.hasChanged(this.user.email, this.form.email)) this.updateEmail()
      if (
        this.hasChanged(this.user.displayName, this.form.displayName) ||
        this.hasChanged(this.user.photoURL, this.form.photoURL)
      ) this.updateUserData()
    },
    updateEmail() {
      db.auth()
        .currentUser.updateEmail(this.form.email)
        .then(() => {
          db.auth()
            .currentUser.sendEmailVerification()
          if (this.isGuider) {
            this.updateGuiderData({
              userData: {
                email: this.form.email
              }
            })
            return
          }

          this.updateUserDataInDB({
            userData: {
              email: this.form.email
            }
          })

        }).catch((err) => {
          this.isLoading = false;
          this.$buefy.toast.open({
            duration: 10000,
            message: err.message,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    updateUserData() {
      db.auth()
        .currentUser.updateProfile({
          displayName: this.form.displayName,
          photoURL: this.form.photoURL,
        })
        .catch((err) => {
          this.isLoading = false;
          this.$buefy.toast.open({
            duration: 10000,
            message: err.message,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    updateUserDataInDB({ uid = this.user.uid, userData }) {
      db.firestore().collection("users")
        .doc(uid)
        .update(userData)
        .catch((error) => {
          this.$buefy.toast.open({
            duration: 10000,
            message: error,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    }
  },
  
}
</script>

<style lang="scss" scoped>
.image {
  height: 128px;
  width: 128px;
  object-fit: contain;
  border-radius: 300px;
}

.b-radio.radio.button {
  padding-bottom: 0.75em;
  padding-left: 0.75em;
  padding-right: 0.75em;
  padding-top: 0.75em;
}

.badge {
  display: flex;
  min-width: 0.5rem;
  padding: 0.3em;
  border-radius: 50%;
  font-size: 24px;
  text-align: center;
  background: #fff;
  color: #fefefe;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  right: 36px;
  top: 48px;
}

.g-button-primary {
  border-radius: 10px;
  font-size: 0.9rem;
}

::v-deep {
  .b-radio.radio.button:not(.is-selected) {
    border-color: #EDEBF2;
    background-color: transparent;
  }
}
</style>