<template>
    <section>
        <div v-if="isLoading">
            <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
        </div>

        <section v-else>
            <p class="is-size-5 is-blue mb-4">Welcome back <strong class="is-blue">{{ user.displayName }}! 👋</strong></p>

            <div class="mb-2" v-if="isGuider && (!user?.slackID || !user?.numberOfSeats)">
                <b-button type="is-ghost" tag="router-link" :to="{ name: 'guider-basic-information' }"
                    icon-right="arrow-right" size="is-small" class="has-text-weight-semibold">Complete your
                    profile</b-button>
            </div>

            <InlineNotification class="mb-4" v-else-if="isGuider && user?.hasFinishedSetUp" type="warning"
                title="Start getting paid!"
                text="You haven’t finished setting up your guider account. Add the remaining information so you can start getting paid.">
               <!--  <template v-slot:custom-element>
                    <b-button :loading="isCreatingStripeLink" @click="onStripeAccountSetUpRequest" type="is-ghost"
                        icon-right="arrow-right" size="is-small"><b>Finish
                            setup</b></b-button>
                </template> -->
            </InlineNotification>

            <div class="display-flex-space-btw mb-5">
                <p class="is-blue page-title">Upcoming activities</p>
                <router-link :to="{ name: 'company-upcoming-activities' }">
                    <span class="is-light-purple link">See all</span>
                </router-link>
    
            </div>
    
            <UpcomingActivities empty-state-title="No activities scheduled for today" showTodayOnly :max-number-to-show="4" />

            <div class="widgets mt-5 mb-5 columns" :class="{ 'is-mobile-view': isMobileView }">
                <router-link class="column is-6 is-one-third-widescreen
                is-one-quarter-fullhd" :to="{ name: 'employees' }">
                    <RouteButtonWithIcon title="Manage employees" description="See their information and payment history.">
                        <template v-slot:icon>
                            <b-icon pack="fas" icon="users-cog"></b-icon>
                        </template>
                    </RouteButtonWithIcon>
                </router-link>
                </div>

        </section>
       
    </section>
</template>

<script>
import { mapState } from "vuex";
import { UsersStates } from '../store/Storetypes'
import { HelpersMixin } from '../mixins'
import UpcomingActivities from '../Admin/UpcomingActivities.vue'
import RouteButtonWithIcon from '../components/elements/RouteButtonWithIcon.vue';
import InlineNotification from "../components/elements/InlineNotification.vue";
export default {
    mixins: [HelpersMixin],
    components: {
        UpcomingActivities,
        RouteButtonWithIcon,
        InlineNotification
    },
    data() {
        return {
            isLoading: false,
        }
    },
    computed: {
        ...mapState({
            user: state => state.users[UsersStates.USER],
            isGuider: state => state.users[UsersStates.IS_USER_A_GUIDER],
        }),
    }
}

</script>

<style lang="scss" scoped>
.widgets.columns:not(.is-mobile-view) {
    display: -webkit-box;
}

.column {
    padding: 0 0.75rem;
}
::v-deep {
    .button.add-btn .icon:first-child:last-child {
        margin-left: -10px;
        margin-right: -10px;
    }
    .modal-close {
        right: 38px;
        top: 38px;
    }
}
</style>