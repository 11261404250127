<template>
  <section class="container is-max-desktop">
    <iframe
      data-tally-src="https://tally.so/embed/w2v7vA?alignLeft=1&transparentBackground=1&dynamicHeight=1"
      loading="lazy"
      width="100%"
      height="399"
      frameborder="0"
      marginheight="0"
      marginwidth="0"
      title="Client feedback survey"
    ></iframe>
    <script type="application/javascript">
      var d = document,
        w = "https://tally.so/widgets/embed.js",
        v = function () {
          "undefined" != typeof Tally
            ? Tally.loadEmbeds()
            : d
                .querySelectorAll("iframe[data-tally-src]:not([src])")
                .forEach(function (e) {
                  e.src = e.dataset.tallySrc;
                });
        };
      if ("undefined" != typeof Tally) v();
      else if (d.querySelector('script[src="' + w + '"]') == null) {
        var s = d.createElement("script");
        (s.src = w), (s.onload = v), (s.onerror = v), d.body.appendChild(s);
      }
    </script>
  </section>
</template>

<script>
export default {
  metaInfo: {
    title: 'Feedback Form | Guiders',
    meta: [
      {
        name: 'description',
        content: 'Submit your feedback for our services. We value your opinions to help us improve our private tours, transportation, and travel services across Portugal.'
      },
      {
        property: 'og:title',
        content: 'Feedback Form | Guiders'
      },
      {
        property: 'og:description',
        content: 'Share your feedback on our personalized travel services in Portugal. Help us improve our private guided tours, transport services, and custom itineraries.'
      },
      {
        property: 'og:url',
        content: 'https://guiders.pt/feedback'
      },
      {
        property: 'og:type',
        content: 'website'
      },
      {
        name: 'keywords',
        content: 'feedback form, Guiders, travel feedback, Portugal travel, private tours, concierge service, transportation feedback'
      },
      {
        name: 'robots',
        content: 'index,follow'
      },
    ]
  },
  mounted() {
    window.scrollTo(0, 0);
},
};
</script>