export const OptionalActivityFields = {
    notSuitableFor: 'Not suitable for',
    languages: 'Languages',
    whatsIncluded: 'What´s Included',
    whatToExpect: 'What to expect',
    accessibility: 'Accessibility',
    additionalInfo: 'Additional information',
    companyName: 'Company name',
    commercialRegistrationCode: 'Commercial registration code',
    whatsappGroupLink: 'WhatsApp Group Link'
}