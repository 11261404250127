
import { db, FieldValue } from "../firebase";

export const AffiliatesMixin = {
    methods: {
        updateAffiliateApprovedCommission({affiliateId, commission}){
              db.firestore().collection("affiliates")
            .doc(affiliateId)
            .update({ approvedEarnings: FieldValue.increment(commission),
                pendingEarnings:  FieldValue.increment(-commission)})
            .catch((error) => {
                console.log(error);
            });
        }
       
    }
}