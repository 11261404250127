import { mapState } from "vuex";
import { UsersStates} from '../store/Storetypes'
export const UserMixin = {
    computed: {
        ...mapState({
            user: state => state.users[UsersStates.USER],
        }),
        guiderUid() {
            return this.user.uid || 'hgytiasjNB7298BIQUHJ'
        },
    }
}