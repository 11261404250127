<template>
        <div @click="onClick" class="ticket--container">
            <!--   <img class="ticket" src="../../assets/ticket.png" /> -->
            <div class="activity-info"> 
                <div class="display-flex mb-1">
                    <div v-if="!!date" class="date gd-yellow--btn mr-2">{{ dateWithMonthAndDay(date) }}</div>
                    <b-tag v-if="nOfSeats" type="is-primary" class=" mr-2">{{ nOfSeats }} seats</b-tag>
                    <b-tag v-if="numberOfVehicles" type="is-primary">{{ numberOfVehicles }} vehicles</b-tag>
                    
            </div>
               
                
                <div class="display-flex">
                    <div class="is-flex is-align-items-center"  @click="goToDetailsPage(activityInfo.id, activityInfo.title)">
                    <span class="activity-title is-blue mb-2 mr-1">{{ activityInfo?.title || "Unknown" }}</span>
                    
                        <b-icon v-if="isTitleClickable" pack="fas" icon="question-circle" size="is-small"  type="is-primary" class="mb-2"></b-icon>    
                    </div>
                    
                   
                    <div v-if="groupSize" class="mb-2 is-purple">
                        <b-icon pack="fas" icon="users"></b-icon>
                        <span class="group-size">{{ groupSize }}</span>
                    </div>
                </div>
                <div class="description mb-1">
                    <div class="mr-3">
                        <p class="has-text-weight-medium">Start time</p>
                        <span>{{ time }}</span>
                    </div>
                    <div v-if="!!endTime" class="mr-3">
                        <p class="has-text-weight-medium">End time</p>
                        <span>{{ endTime }}</span>
                    </div>

                    <div v-if="!!payAmount">
                        <p class="has-text-weight-medium">Payment</p>
                        <span>{{ payAmount }}€</span>
                    </div>
                </div>
            </div>

        </div>
</template>

<script>
import {
    dateWithMonthAndDay, convertMinutesToHoursAndMinutes, addDurationToTime
} from '../../helpers';
import { ActivityMixin, HelpersMixin } from "../../mixins";
import { mapMutations } from 'vuex';
import { ActivitiesMutations } from '../../store/Storetypes';

export default {
    name: "Ticket",
    data() {
        return {
            dateWithMonthAndDay,
            convertMinutesToHoursAndMinutes,
            addDurationToTime,
        }
    },
    props: {
        activityInfo: {
            type: Object,
            default: () => { }
        },
        activityGroupInfo: {
            type: Object,
            default: () => { }
        },
        time: {
            type: String,
            default: "Unknown"
        },
        endTime: {
            type: String,
            default: undefined
        },
        date: {
            type: [Number, String],
            default: undefined
        },
        nOfSeats: {
            type: [Number, String],
            default: undefined
        },
        numberOfVehicles: {
            type: [Number, String],
            default: undefined
        },
        isClickable: {
            type: Boolean,
            default: false
        },
        isTitleClickable: {
            type: Boolean,
            default: true
        },
        payAmount: {
            type: Number,
            default: undefined
        },
        groupSize: {
            type: String,
            default: undefined
        }
    },
    mixins: [ActivityMixin, HelpersMixin],
    // TODO: Disable ticket if date is not current or go to info of activity
    methods: {
        ...mapMutations({
            setSelectedActivityId: ActivitiesMutations.SET_SELECTED_ACTIVITY_ID
        }),
        onClick() {
            if (!this.isClickable) return
            if (!this.activityInfo?.id || !this.activityGroupInfo?.id) return
            this.$router.push({
                name: "start-activity",
                params: {
                    activityId: this.activityInfo?.id,
                    activityGroupId: this.activityGroupInfo?.id
                }
            });
        },
        goToDetailsPage(activityId, title) {
            if(!this.isTitleClickable) return
            this.setSelectedActivityId(activityId)
            let route = this.$router.resolve({ name: 'details', params: { id: activityId, title: this.slugify(title) } })
            window.open(route.href);
        },
    },
    computed: {
        timeFrame() {
            if (this.endTime) return `${this.time} - ${this.endTime}`
            const activityDuration = this.getActivityInfo(this.activityGroupInfo.activityId)
            const duration = convertMinutesToHoursAndMinutes(activityDuration)
            const endTime = addDurationToTime(this.time, duration.hours, duration.minutes)
            return `${this.time} - ${endTime}`
        }
    }
}

</script>

<style lang="scss" scoped>
.ticket--container {
    background-image: url("../../assets/ticket.png");
    height: 125px;
    width: -webkit-fill-available;
    background-size: contain;
    background-repeat: no-repeat;
}

.activity-info {
    margin-left: 95px;
    padding-top: 10px;
    width: fit-content;
    text-align-last: start;

}

.activity-title {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: start;
    font-size: 1rem;
    max-width: 59vw;
}

.arrow {
    height: 2rem;
    float: right;
}

.icon {
    height: 14px;
    padding-right: .25rem;
    width: auto;
}

.group-size {
    font-size: .8rem;
    color: #927BD0;
}

.description {
    font-size: 12px;
    display: flex;
    align-items: center;

    display: grid;
    grid-template-columns: repeat(3, minmax(max-content, 1fr));
}

.g-button-danger--light,
.g-button-success--light {
    font-weight: 600;
}
</style>