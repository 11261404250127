import { mapState } from "vuex";
import { TicketsStates } from '../store/Storetypes'

export const TicketMixin = {
    data() {
        return {
          defaultInvalidQRCodeTitle: 'Invalid ticket'
        }
      },
      computed: {
        ...mapState({
          savedGroupsList: state => state.tickets[TicketsStates.SAVED_GROUPS_LIST],
        }),
    },
    methods: {
        getTicketData(ticket) {
            const qrCodeContent = ticket.split('@');
            const groupId = qrCodeContent[0]
            const participantId = qrCodeContent[1]
            const ticketNumber = qrCodeContent[2]
            return { groupId, participantId, ticketNumber }
          },
          getParticipantInfo({ groupId, participantId }) {
            //console.log(this.savedGroupsList[groupId], participantId, groupId);
            return this.savedGroupsList[groupId].participants.find((participant) => participant.participantId === participantId)
          },
          getParticipantIndex({ groupId, participantId }) {
            return this.savedGroupsList[groupId].participants.findIndex((participant) => participant.participantId === participantId)
          },
          notifyError(message = this.defaultInvalidQRCodeTitle) {
            this.$buefy.notification.open({
              duration: 10000,
              message,
              position: "is-bottom",
              type: "is-danger",
              hasIcon: true,
              closable: false,
              iconSize: 'is-small'
            });
          }
    }
}