import {
    TicketsMutations as Mutations,
    TicketsStates as States,
    TicketsActions as Actions
} from '../Storetypes'
import { db } from "../../firebase";

export default {
    state: {
        [States.SAVED_GROUPS_LIST]: [],
        [States.TICKETS]: []
    },
    mutations: {
        [Mutations.SET_SAVED_GROUPS_LIST](state, { groupInfo, groupId }) {
            state[States.SAVED_GROUPS_LIST][groupId] = groupInfo
        },
        [Mutations.SET_TICKETS](state, tickets) {
            state[States.TICKETS] = tickets
        },
        [Mutations.UPDATE_TICKET_DATA](state, { ticketIndex, ticketData }) {
            state[States.TICKETS][ticketIndex] = ticketData
        },
        [Mutations.DELETE_TICKET](state, ticketId) {
            const filteredTickets = state[States.TICKETS].filter((ticket) => ticket.id !== ticketId)
            state[States.TICKETS] = filteredTickets
        },
    },
    actions: {
        [Actions.GET_TICKETS]({ commit }) {
            let Tickets = [];
            db.firestore()
                .collection("tickets")
                .where("isVisible", "==", true)
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        Tickets.push({
                            ...doc.data(),
                            id: doc.id,
                        });
                    });
                    const ticketsSortedByCheapest = Tickets.sort((a, b) => {
                        return a.price - b.price;
                    });
                    commit(Mutations.SET_TICKETS, ticketsSortedByCheapest)
                })
            return Tickets
        },
        async [Actions.GET_TICKETS_BY_IDS]( documentIds) {
            const Tickets = [];
            for (const docId of documentIds) {
                try {
                    const docRef = await db.firestore()
                        .collection("tickets").doc(docId).get();
                    if (docRef.exists) {
                        Tickets.push({
                            ...docRef.data(),
                            id: docRef.id,
                        });
                    }
                } catch (error) {
                    console.error(`Error getting document with ID ${docId}:`, error);
                }
            }
            return Tickets;
        },
    }
}