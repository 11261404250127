<template>
    <section>
        <div class="display-flex-space-btw mb-5">
            <p class="is-blue page-title">Upcoming activities</p>
            <router-link :to="{ name: 'upcoming-activities' }">
                <span class="is-light-purple link">See all</span>
            </router-link>

        </div>
  
        <UpcomingActivities empty-state-title="No activities scheduled for today" showTodayOnly :max-number-to-show="4" />

        <div class="widgets mt-5 mb-5 columns" :class="{ 'is-mobile-view': isMobileView }">
            <router-link class="column is-6 is-one-third-widescreen
            is-one-quarter-fullhd" :to="{ name: 'manage-guiders' }">
                <RouteButtonWithIcon title="Manage guiders" description="See their information and payment history.">
                    <template v-slot:icon>
                        <b-icon pack="fas" icon="users-cog"></b-icon>
                    </template>
                </RouteButtonWithIcon>
            </router-link>

            <router-link class="column is-6 is-one-third-widescreen
            is-one-quarter-fullhd" :to="{ name: 'manage-activities' }">
                <RouteButtonWithIcon title="Manage activities" description="Create, edit and hide activities.">
                    <template v-slot:icon>
                        <b-icon pack="fas" icon="th-list"></b-icon>
                    </template>
                </RouteButtonWithIcon>
            </router-link>

            <router-link class="column is-6 is-one-third-widescreen
            is-one-quarter-fullhd" :to="{ name: 'affiliates-program' }">
                <RouteButtonWithIcon title="Affiliates program" description="Manage affiliates.">
                    <template v-slot:icon>
                        <b-icon pack="fas" icon="user-tag"></b-icon>
                    </template>
                </RouteButtonWithIcon>
            </router-link>

            <div class="column is-6 is-one-third-widescreen
            is-one-quarter-fullhd" @click="toggleModal(true)">
                <RouteButtonWithIcon title="Generate code"
                    description="Generate new codes to allow companies to register to the platform.">
                    <template v-slot:icon>
                        <b-icon pack="fas" icon="magic"></b-icon>
                    </template>
                </RouteButtonWithIcon>
            </div>
        </div>

        <b-modal v-model="isCodeGenerationModalActive">
            <GenerateGuiderRegistrationCode @toggleModal="toggleModal" />
        </b-modal>
    </section>
</template>

<script>
import RouteButtonWithIcon from '../components/elements/RouteButtonWithIcon.vue';
import UpcomingActivities from './UpcomingActivities.vue'
import GenerateGuiderRegistrationCode from './GenerateGuiderRegistrationCode.vue';
import { HelpersMixin } from '../mixins'

export default {
    mixins: [HelpersMixin],
    data() {
        return {
            isCodeGenerationModalActive: false
        }
    },
    components: {
        UpcomingActivities,
        RouteButtonWithIcon,
        GenerateGuiderRegistrationCode
    },
    methods: {
        toggleModal(isActive) {
            this.isCodeGenerationModalActive = isActive
        }
    }
}
</script>

<style scoped lang="scss">
.widgets.columns:not(.is-mobile-view) {
    display: -webkit-box;
}

.column {
    padding: 0 0.75rem;
}

::v-deep {
    .modal-close {
        right: 36px;
        top: 36px;
    }

}
</style>